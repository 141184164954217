import React, {useEffect} from "react";
import TopHead from "../components/Blog/topHead";
import NewBlog from "../components/Blog/newBlog";
import SuggestedBlog from "../components/Blog/suggestedBlog";
import { Helmet } from "react-helmet";


function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="Explore our library of Blogs and Articles related to everything Digitization, Engagement, Authentication and Future Trends." />
                <meta name="keywords" content="Library blog, customer engagement, digitization, industry trends " />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="A Library for Everything Digitization and Engagement" />
                <meta property="og:description" content="Explore our library of Blogs and Articles related to everything Digitization, Engagement, Authentication and Future Trends." />
                <meta property="og:url" content="https://ciphercode.co/blog" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="A Library for Everything Digitization and Engagement" />
                <meta name="twitter:description" content="Explore our library of Blogs and Articles related to everything Digitization, Engagement, Authentication and Future Trends." />
            </Helmet>
			<TopHead />
			<NewBlog />
			<SuggestedBlog />
		</div>
	);
}
export default Blog;
