import React, {useEffect} from "react";
import TopHead from "../components/Product/top-head";
import ProductDigital from "../components/Product/prod-digi";
import Discover from "../components/Product/discover";
import SupportApp from "../components/Product/support-app";
import Footerhome from '../components/Home/footerhome';
import { Helmet } from "react-helmet";

function Product() {
  useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
  return (
    <div>
      <Helmet>
        <title>Ciphercode</title>
        <meta name="description" content="Discover Brandwise’s solutions for product digitization, customer engagement, and authentication. Enhance ROI, engagement and security." />
        <meta name="keywords" content="Brandwise, Digitization, Engagement, Authentication, Security" />
        {/* Open Graph tags for social media sharing */}
        <meta property="og:title" content="Enterprise Platform for engagement and digitization needs" />
        <meta property="og:description" content="Discover Brandwise’s solutions for product digitization, customer engagement, and authentication. Enhance ROI, engagement and security." />
        <meta property="og:url" content="https://ciphercode.co/product" />
        {/* Twitter Card for better Twitter sharing */}
        <meta name="twitter:title" content="Enterprise Platform for engagement and digitization needs" />
        <meta name="twitter:description" content="Discover Brandwise’s solutions for product digitization, customer engagement, and authentication. Enhance ROI, engagement and security." />
      </Helmet>
      <TopHead></TopHead>
      <ProductDigital></ProductDigital>
      <SupportApp></SupportApp>
       <Discover></Discover>
       <Footerhome></Footerhome>
    </div>
  );
}
export default Product;
