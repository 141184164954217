import React from "react";
import img26 from "../../images/2 (26).png";
import img25 from "../../images/2 (1).png";
import "./topHead.css";

function TopHead() {
	return (
		<div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center'}}>
			<div className="container top-head">
				<img alt="brandwise" className="absBg1" style={{opacity: 0.21}} src={img26} />
				<img alt="brandwise" className="absBg2" style={{opacity: 0.23}} src={img25} />
				<div className="row pb-5">
					<div className="text-center">
						<h1>Blogs</h1>
						<h4 className="px-6">Here is a guide to our most feature blogs on Anti-Counterfiting Solution, Product Digitization, and Consumer Digital Engagement on Brandwise<sup>®</sup> </h4>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TopHead;
