import React, {useEffect} from "react";
import TopHead from "../components/Blognumberfour/topHead";
import BlogContent from "../components/Blognumberfour/blogcontent";
import { Helmet } from "react-helmet";

function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="How NFT Staking is Transforming the Wine Industry with Ciphercode as its lead innovator " />
                <meta name="keywords" content="NFT token development company, nft staking, winieries, wine" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="NFT Tokenization is transforming the Wine Industry" />
                <meta property="og:description" content="How NFT Staking is Transforming the Wine Industry with Ciphercode as its lead innovator " />
                <meta property="og:url" content="https://ciphercode.co/nft_staking" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="NFT Tokenization is transforming the Wine Industry" />
                <meta name="twitter:description" content="How NFT Staking is Transforming the Wine Industry with Ciphercode as its lead innovator " />
            </Helmet>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;
