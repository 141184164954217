import React from "react";
import img from "../../images/articlecoverphoto.png";

import "./topHead.css";

export default function TopHead() {
	return (
		<div className="articlepost-tophead">
			<h1>The Transformative Impact of<br/> Encryption on the World</h1>
			<img className="cover-img" src={img} alt="cover of the blog" />
		</div>
	);
}
