import React from 'react';
import Marquee from "react-fast-marquee";
import img2 from "../../images/Banner Image.png"
import img3 from "../../images/Guess.svg";
import img5 from "../../images/shycocan.svg";
import img7 from "../../images/Demoza.svg";
import img8 from "../../images/cii.svg";
import img9 from "../../images/sofas & More.svg";
import img10 from "../../images/ABFRL.svg";
import img11 from "../../images/mensa_black.png";
import img13 from "../../images/denis_lingo.webp";
import img14 from "../../images/livp.webp";
import img15 from "../../images/unnamed.webp";
import { Link } from "react-router-dom";

import './top-head.css'

function TopHead() {
  return (
    <>
    <div className='tophead-content'>

    <div className="image-container-banner">
      <img src={img2} alt="prod-banner" className="prod-banner-image" />
      <div className="text-button-container">
        <h1 className="image-text-banner">
          An Enterprise SaaS Platform that Empowers Brands with Innovative Solutions for <span>Product Digitization,
          Customer Engagement</span> & <span>Advanced Authentication</span>
        </h1>
        <Link to="/schedule_demo" rel="noreferrer">
          <button className="btn btn-BlueBg" id="banner-button">Get a demo</button>
        </Link>
      </div>
    </div><br/><br/>
   
      
      <div className="marquee-slider">
        <p>Meet the top Brands using Brandwise</p><br />
        <Marquee speed={100} delay={0} pauseOnHover={true}>
        <div className="image_wrapper">
            <img src={img3} alt="Guess logo" />
          </div>
          <div className="image_wrapper">
            <img src={img5} alt="Shycocan logo" />
          </div>
          <div className="image_wrapper">
            <img src={img7} alt="Demoza logo" />
          </div>
          <div className="image_wrapper">
            <img src={img8} alt="CII logo" />
          </div>
          <div className="image_wrapper">
            <img src={img9} alt="Sofas & More logo" />
          </div>
          <div className="image_wrapper">
            <img src={img10} alt="ABFRL logo" />
          </div>
          <div className="image_wrapper">
            <img src={img11} alt="Mensa logo" />
          </div>
          <div className="image_wrapper">
            <img src={img13} alt="dennis_lingo" />
          </div>
          <div className="image_wrapper">
            <img src={img14} alt="livp_img" />
          </div>
          <div className="image_wrapper">
            <img src={img15} alt="elemage_img" />
          </div>

        </Marquee>
      </div><br/><br/><br/>
      </div>
    </> 
  )
}

export default TopHead;
