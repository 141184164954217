import React from 'react';
import Marquee from "react-fast-marquee";
import './agechallenge.css';

export default function AgeChallenge() {
    return (
        <div className="age-challenge">
            <div className="container">
                <h2>Are you wondering about <span>Digital Age challenges…</span></h2>
            </div>
            <div className="marquee-slider1">
                <Marquee speed={100} delay={0} pauseOnHover={true}>
                    <div className="card" id='marquee-card'>
                        <p>How do we <span> connect</span> our <span> products</span> to<br />the <span> digital world</span>?</p>
                    </div>
                    <div className="full-line-wrapper">
                        <hr className="marquee-separator" />
                    </div>
                    <div className="card" id='marquee-card'>
                        <p>Is our <span> brand safe</span> from<br /><span>counterfeits</span>?</p>
                    </div>
                    <div className="full-line-wrapper">
                        <hr className="marquee-separator" />
                    </div>
                    <div className="card" id='marquee-card'>
                        <p>Are we truly getting the <span>real</span><br /><span>customer data</span>?</p>
                    </div>
                    <div className="full-line-wrapper">
                        <hr className="marquee-separator" />
                    </div>
                    <div className="card" id='marquee-card'>
                        <p>How do we <span> engage customers</span> across<br />all channels effectively?</p>
                    </div>
                </Marquee>
            </div>
        </div>
    );
}
