import React from "react";
import img from "../../images/automobile_topimg.svg";
import './topHead.css'

function TopHead() {
	return (
		<div className="container top-head">
			<div className="row autom-top">
			   <div className="col-md-5 order-1 order-md-2">
					<h1>Protect your consumers and brand from auto parts counterfeiting</h1>
					<h4>Our authentication and deep trace solutions help minimize the risk of counterfeit auto-parts and help trace products across the distribution chain and protect consumers, all from one platform.</h4>
				</div>
				<div className="col-md-1 order-2 order-md-2"></div>
                <div className="col-md-6 order-2 order-md-2 main-img1">
					<img  src={img} alt="main_image" />
				</div>
			</div>
		</div>
	);
}

export default TopHead;
