import React,{useState} from 'react';
import {useNavigate} from 'react-router-dom';


export default function RegisterForm() {
    const [name, setName]=useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState(null);
    const[error,seterror] = useState(false);
    const[emailerror, setEmailerror] = useState(false);
    const [isInputValid,setisInputValid]=useState(true);
    const [isCompanyValid,setisCompanyValid]=useState(true);
    const [isInputNameValid,setisInputNameValid]=useState(true);
    const navigate = useNavigate();

   
    const regextEmailValidation = Email => {
        const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
        return regex.test(Email);
    };
    const regextNameValidation = Name => {
      const regex = new RegExp(/^[A-Za-z\s]*$/);
      return regex.test(Name);
    };
    const regextCompanyValidation = Company  => {
      const regex = new RegExp(/^[A-Za-z\s]*$/);
      return regex.test(Company);
    };
    const updateEmailVal = event => {
      const { value } = event.target;
      const isInputValid = !value || regextEmailValidation(value)
      setisInputValid(isInputValid)
      setEmail(value)
    };
    const updateNameVal = event => {
      const { value } = event.target;
      const isInputNameValid= !value || regextNameValidation(value)
      setisInputNameValid(isInputNameValid)
      setName(value)
    };
    const updateCompanyVal = event => {
      const { value } = event.target;
      const isCompanyValid= !value || regextCompanyValidation(value)
      setisCompanyValid(isCompanyValid)
      setCompany(value)
    };
    const signup = async() => {
      let item = {name, email, company}
      if(name.trim("").length === 0 ){
        seterror(true)
      }
      else if(email.trim("").length === 0 ){
        seterror(true)
      }
      else if(isInputValid === true && isInputNameValid === true){
        seterror(false)
        fetch("https://web.ciphercode.co/api/v1/auth/web_register/", {
            method : 'POST',
            body: JSON.stringify(item),
            headers : {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json',
            }
        })
        .then(response => response.json())
        .then(response => {
        if(response.message === "Registration Successfully"){
          alert('Yay, you will be onboarded shortly!')
          localStorage.setItem("user-info", JSON.stringify(response))
          navigate("/")
        }
        else if(response.message === "Email Address Already exists"){
          setEmailerror(true)
        }
        }).catch((error)=>{
          console.log(error)
        })
    }
  }
  return (
      <div className="card2 card border-0 px-4 py-5">
        {error&&<p style={{color:'red'}}>Please, fill mandatory field</p>}
        <div className="row px-3"> 
          <label className="mb-1 required">Name</label> 
          <input className="mb-4" type="text" name="name"  required onChange = {updateNameVal}  placeholder='Full name'/>
          {!isInputNameValid && (<div style={{ color: "#F61C04", marginTop:"-15px", fontSize:"12px" }}>enter valid name.</div>)}
        </div>
        <div className="row px-3"> 
          <label className="mb-1 required">Email</label> 
          <input className="mb-4" type="email" name="email" required  onChange = {updateEmailVal} placeholder='Email address'/>
          {!isInputValid && (<div style={{ color: "#F61C04", marginTop:"-18px", fontSize:"12px"}}>Email is not valid.</div>)}
          {emailerror&&<p style={{color:'red', marginTop:'-18px', marginLeft:'-10px'}}>Email address already exists</p>}
        </div>
        <div className="row px-3"> 
          <label className="mb-1 required">Company</label> 
          <input className="mb-4" type="text" name="company" required onChange = {updateCompanyVal}  placeholder='Company name'/>
          {!isCompanyValid && (<div style={{ color: "#F61C04", marginTop:"-18px", fontSize:"12px"}}>enter valid company name.</div>)}
        </div>
        <div className="row mb-3 px-3"> 
          <button type="button" className="btn btn-blue text-center" onClick={signup}>Submit</button> 
        </div>
      </div>
  )
}