import React from "react";
import img1 from "../../images/packaging1.webp";
import img2 from "../../images/packaging2.webp";
import img3 from "../../images/packaging3.webp";
import "./package.css";

export default function Package() {
  return (
    <div className="container packaging-content" id="id_packaging_content">
      <h2>How Packaging Partners Leverage Brandwise</h2>
      <div className="row g-4">
        {/* Card 1 */}
        <div className="col-md-4">
          <div className="card custom-packagingcard">
            <img src={img1} className="card-img-top" alt="Sustainable Packaging" />
            <div className="card-body">
              <h3 className="card-title">Embrace Sustainable Packaging Initiatives</h3>
              <ul className="card-text">
                <li>Avoid reprints with dynamic content.</li>
                <li>
                  Attract consumers to participate in sustainability initiatives of the circular economy.
                </li>
                <li>Bring intelligence across the value chain to minimize waste.</li>
                <li>Embed and reuse improvements and more.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-md-4">
          <div className="card custom-packagingcard">
            <img src={img2} className="card-img-top" alt="Bring Ecosystem Together" />
            <div className="card-body">
              <h3 className="card-title">Bring the Ecosystem Together</h3>
              <ul className="card-text">
                <li>
                  Smart packaging is more than just a trend; it’s reshaping the way we interact with
                  products and brands.
                </li>
                <li>
                  Tech-enabled collaboration with ecosystem partners optimizes engagement to intelligence
                  across levels.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-md-4">
          <div className="card custom-packagingcard">
            <img src={img3} className="card-img-top" alt="Personalized Packaging" />
            <div className="card-body">
              <h3 className="card-title">Personalized Packaging for Your Partners</h3>
              <ul className="card-text">
                <li>
                  Smart packaging isn’t just about making products smarter; it’s about making consumers
                  more informed.
                </li>
                <li>
                  Create end-less content, experiences, and deliver those experiences specific to SKU,
                  Batch, Geography, Demography, and other filters.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
