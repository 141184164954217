import React, { useState, useEffect, useRef } from 'react';
import darklogo from "../../images/signup.gif";
import './schedule.css';
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import LoadingOverlay from 'react-loading-overlay';
import img1 from "../../images/captcha_reload.png";
import Swal from 'sweetalert2';
import { Helmet } from "react-helmet";

export default function ScheduleForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [error, setError] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const [isInputNameValid, setIsInputNameValid] = useState(true);
  const [loading, setLoading] = useState(false); 
  const [captchaText, setCaptchaText] = useState(''); 
  const [userInput, setUserInput] = useState(true); 
  const canvasRef = useRef(null); 
  const [captchaValid, setCaptchaValid] = useState(true);
  

  const handleScheduleChange = (args) => {
   
    const selectedDateTime = args.value;
    if (selectedDateTime) {
      setSchedule(selectedDateTime);
    }
  };

  const minDate = () => {
    const today = new Date().toISOString().split('T')[0];
    return today;
};


  const regextEmailValidation = Email => {
    const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    return regex.test(Email);
  };

  const regextNameValidation = Name => {
    const regex = new RegExp('[a-zA-Z]+\\.?');
    return regex.test(Name);
  };

  const updateEmailVal = event => {
    const { value } = event.target;
    const isInputValid = !value || regextEmailValidation(value)
    setIsInputValid(isInputValid)
    setEmail(value)
  };

  const updateNameVal = event => {
    const { value } = event.target;
    const isInputNameValid = !value || regextNameValidation(value)
    setIsInputNameValid(isInputNameValid)
    setName(value)
  };

   //Generating captcha
useEffect(() => { 
  const canvas = canvasRef.current; 
  const ctx = canvas.getContext('2d'); 
  initializeCaptcha(ctx); 
}, []); 

const generateRandomChar = (min, max) => 
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min)); 
    const generateCaptchaText = () => { 
       let captcha = ''; 
       for (let i = 0; i < 4; i++) { 
          captcha += generateRandomChar(65, 90); // for uppercase letters
          captcha += generateRandomChar(97, 122); // for lowercase letters
          captcha += generateRandomChar(48, 57); // for digits
    } 
    return captcha.split('').sort(() => Math.random() - 0.5).join('').substring(0, 4); 
    }; 
    console.log(generateCaptchaText()); 

const drawCaptchaOnCanvas = (ctx, captcha) => { 
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); 
    const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)']; 
    const letterSpace = 150 / captcha.length; 
    for (let i = 0; i < captcha.length; i++) { 
        const xInitialSpace = 25; 
        ctx.font = '20px Roboto Mono'; 
        ctx.fillStyle = textColors[Math.floor( 
            Math.random() * 2)]; 
        ctx.fillText( 
            captcha[i], 
            xInitialSpace + i * letterSpace, 
              
            // Randomize Y position slightly 
            Math.floor(Math.random() * 16 + 25), 
            100 
        ); 
    } 
}; 

const initializeCaptcha = (ctx) => { 
    setUserInput(''); 
    const newCaptcha = generateCaptchaText(); 
    setCaptchaText(newCaptcha); 
    drawCaptchaOnCanvas(ctx, newCaptcha); 
}; 

const handleUserInputChange = (e) => { 
    setUserInput(e.target.value); 
}; 

  const Demo = async () => {
    let item = { name, email, company, schedule, userInput }
    if (name.trim("").length === 0) {
      setError(true)
    }
    else if (email.trim("").length === 0) {
      setError(true)
    }
    else if (schedule.length === 0) {
      setError(true)
    }
    else if (userInput === captchaText && isInputValid === true && isInputNameValid === true) {
      setLoading(true); 
      setCaptchaValid(true);
      setError(false)
      fetch("https://web.ciphercode.co/api/v1/schedule_demo/", {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json',
        }
      })
      .then(response => response.json())
      .then(response => {
      if (response.message === "Your data successfully submitted") {
        Swal.fire({title: "Demo on the way! ",text: "Get ready for an insightful session.", icon: "success",timer: 5000});
        setName("")
        setEmail("")
        setCompany("")
        setSchedule("")
        setUserInput("")
      }
      }).catch((error) => {
        alert(error)
      }).finally(() => {
        setLoading(false);
      });
    }
    else {
      setError(true); // Set error state to true
      setCaptchaValid(false); // Set captcha validity state to false
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      initializeCaptcha(ctx); // Reinitialize the captcha
  }
}

  useEffect(() => {
    window.scroll({ top: 0 })
  }, []);

  return (
    <LoadingOverlay active={loading} spinner text='Submitting...' >
      <Helmet>
        <title>Ciphercode</title>
        <meta name="description" content="Provide your details and a Brandwise specialist will reach out to you to schedule a demo according to your convenience." />
        <meta name="keywords" content="Get a Demo, Sign Up for Brandwise, Brandwise Demo" />
        {/* Open Graph tags for social media sharing */}
        <meta property="og:title" content="Get a Demo of Brandwise by Ciphercode" />
        <meta property="og:description" content="Provide your details and a Brandwise specialist will reach out to you to schedule a demo according to your convenience." />
        <meta property="og:url" content="https://ciphercode.co/Vanity_metrics_to_Revenue_Metrics" />
        {/* Twitter Card for better Twitter sharing */}
        <meta name="twitter:title" content="Get a Demo of Brandwise by Ciphercode" />
        <meta name="twitter:description" content="Provide your details and a Brandwise specialist will reach out to you to schedule a demo according to your convenience." />
      </Helmet>
      <div className="container py-5">
        <div className="card card0 border-0">
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="card1 pb-5">
                <div className="row px-3 justify-content-center mt-4 mb-5 border-line">
                  <img src={darklogo} className="image" alt='sch_img' /> </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card2 card border-0 px-4 py-5">
                {error && <p style={{ color: 'red' }}>Please, fill out all the required fields</p>}
                <div className="row px-3"> <label className="mb-1 required">Name </label>
                  <input className="mb-4" type="text" name="name" required value={name}  onChange={updateNameVal} placeholder='Full name' />
                  {!isInputNameValid && (<div style={{ color: "#F61C04", marginTop: "-15px", fontSize: "12px" }}>enter valid name.</div>)}
                </div>
                <div className="row px-3">
                  <label className="mb-1 required">Company</label>
                  <input className="mb-4" type="text" name="company" required value={company} onChange={(e) => setCompany(e.target.value)} placeholder='Company name' />
                </div>
                <div className="row px-3">
                  <label className="mb-1 required">Email</label>
                  <input className="mb-4" type="email" name="email" required value={email} onChange={updateEmailVal} placeholder='Email address' />
                  {!isInputValid && (<div style={{ color: "#F61C04", marginTop: "-18px", fontSize: "12px" }}>Email is not valid.</div>)}
                </div>
                <div className="row px-3">
                  <label className="mb-1 required">Schedule Time</label>
                  <DateTimePickerComponent
                    value={schedule}
                    min={minDate()}
                    onChange={handleScheduleChange}
                    required={true}
        
                  />
                 {/* <DateTimePickerComponent value={schedule}  min={minDate()} onChange={(e) => setSchedule(e.target.value)} required />*/}
                </div><br />
                <div className="captcha-field">
                  <div className="mb-3 form-item">

                    <input 
                        type="text"
                        id="user-input"
                        placeholder="Enter the Captcha"
                        value={userInput} 
                        onChange={handleUserInputChange}
                        className={captchaValid ? "" : "invalid"}/> 
                    </div>
                    <div className="wrapper">
                                    
                        <canvas className="captcha-box" ref={canvasRef} 
                          width="200"
                          height="70" > 
                        </canvas> 
                        <button 
                          id="reload-button" 
                          type="button" // Add this line to prevent form submission
                          onClick={() => initializeCaptcha(canvasRef.current.getContext('2d'))} 
                          style={{ border: "none", marginLeft: "20px", background: "none", verticalAlign: "middle" }} 
                        >
                        <img src={img1} alt='captcha' className="reload-img" />
                        </button>
                                    
                    </div>
                   </div>
                <div className="row mb-3 px-3">
                  <button type="button" className="btn btn-blue text-center" onClick={Demo} disabled={loading}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  )
}
