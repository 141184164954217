import React from 'react';
import './signup.css';
import backgroundImg from "../../images/background_1.png";
import logoImg from "../../images/logo_1.png";
import blurImg from "../../images/blur_1.png";
import SignupForm from './signupform';

export default function Signup() {
  return (
    <div className="container signup-content">
      <div className="row">
        <div className="col-md-6 d-none d-md-flex" style={{backgroundImage:`url(${backgroundImg})`}}>
          <div className="centered-content">
              <img src={logoImg} className="cipherlogo" alt="Cipher_Logo" />
              <p style={{ whiteSpace: 'nowrap', color: 'white' }}><span style={{ border: '1px solid whitesmoke', borderRadius: '8px', padding: '3px',marginLeft:'-40px'}}>Cryptoblock</span> is Transforming physical products in Phygital era</p>
              <hr className="rounded" />
              <p style={{ whiteSpace: 'nowrap', color: 'white' }}>Build <span style={{ border: '1px solid whitesmoke', borderRadius: '5px', padding: '3px' }}>Intelligence</span>, Consumer First, & Grow 10x</p>
              <hr className="rounded" />
              <p style={{ whiteSpace: 'nowrap', color: 'white' }}><span style={{ border: '1px solid whitesmoke', borderRadius: '5px', padding: '3px' }}>Qseal®</span>, is disrupting industry using Cryptography</p>
              <hr className="rounded" />
            </div>
            <div style={{ position: 'absolute',right: '0' }}>
              <img src={blurImg} className="blur_logo" alt="Blur Logo" />
            </div>
          </div>
        <div className="col-md-6">
          <SignupForm></SignupForm>
        </div>
      </div>
    </div>
  )
}
