import React from "react";
import img26 from "../../images/2 (26).png";
import img25 from "../../images/2 (1).png";
import img1 from "../../images/cryptoIllustration.svg";
import img1m from "../../images/cryptoIllustration-m.png";
import "./topHead.css";

function TopHead() {
	return (
		<div className="magic-margin" style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container top-head">
				<img alt="brandwise" className="absBg1" style={{opacity: 0.21}} src={img26} />
				<img alt="brandwise" className="absBg2" style={{opacity: 0.23}} src={img25} />
				<div className="row pb-5 cryptoblocks">
					<div className="text-center">
						<h1>
							What are <span className="myBlue"> CryptoBlocks</span>?
						</h1>
						<h4 className="px-6">Brandwise<sup>®</sup> uses the most advanced authentication technology called Cryptoblocks, where each block represents the transaction between the factory and the owning business – AKA Brand.</h4>
					</div>
				</div>
				<div className="row justify-content-center extra-margin">
					<div className="hide-sm card-bg6 text-center">
						<img src={img1} alt="crypto_pic" style={{maxWidth: "100%", height:"100%"}} />
					</div>
					<div className="show-sm card-bg6 text-center">
						<img src={img1m} alt="crypto_pic" style={{maxWidth: "100%", height:"100%"}} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default TopHead;
