import React, {useEffect}  from "react";

export default function Qreport() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    const headStyles = {
      marginTop:'320px',
      TextAlignment: 'center',
      marginBottom:'200px',
      fontWeight: '900',
      color:'blue',
      
    }
    return (
        <div style={{textAlign: '-webkit-center'}}>  
              <h1 style={headStyles}>Coming Soon</h1>
              <br />
              <br />
              <br />
              <br />
        </div>
    )
}