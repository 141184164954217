import React, {useEffect} from "react";
import CustomerEngagement from "../components/HomeApplicants/customerEngagement";
import CustomerMapping from "../components/HomeApplicants/customerMapping";
import Integrate from "../components/HomeApplicants/integrate";
import ProductTop from "../components/Product/product-top";
import img from "../images/QReach_img2.svg";
import img26 from "../images/2 (26).png";
import img1 from "../images/2 (1).png";
import img11 from "../images/Qseal_img.svg";
import industrial from "../images/INDUSTRIAL PRODUCTS.svg";
import eduction from "../images/EDUCATION.svg";
import certificate from "../images/SKILLING  TRAINING INSTITUTES.svg";
import corporate from "../images/CORPORATES.svg";
import "./qseal_qreach.css";


export default function QsealPage() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	const customerMappingData = [
		{
			title : "PHYSICAL OBJECTS",
			description: "Digitize physical objects (at unit, in batch, or SKU), to deliver real-time authentication to prevent counterfeits. Leverage digital experiences to administrate distribution and user virtual experiences to enhance consumer engagement (gamification of promotions, loyalty programs etc) and increase user retention. Build subtle ground insights, grow revenue, and enhance product experiences."
		},
		{
			title : "DOCUMENTS",
			description: "Create or upload personal documents to secure using unique digital signature and share on permissioned blockchain for public authentication. Allow stakeholders from various groups to validate your personal KYC documents on the private vault. Seamless integration with local regulatory body brings new experiences towards the modern world."
		},
		{
			title : "DIGITAL ASSETS",
			description: "Upload your digital assets (business contracts, agreements, compliance documents etc.) that carry confidential information and require restricted access. Use digitally signed Cryptoblock to give restricted access inside or outside of your organization. Securing documents on blockchain means information stored is immutable."
		}
	
	];
	return (
		<>
		<div>
			<ProductTop
			btnText={"Know More"}
        bgImage={img26}
        bgImage2={img1}
        productHead="<h1> Qseal<sup>®</sup> <br />Product <span className='myBlue'>digital authentication </span><br /> built for scale</h1>"
        mainImage={img11}
        productSubHead="Digitize your documents, physical objects, and bring them to life in consumer hands.
        Deliver futuristic experiences on permissioned blockchain"
        url={"#qsealUseCase"}
      ></ProductTop>
	       <div style={{marginTop:'8px'}}>
           {/*<CallBackBtn></CallBackBtn>*/}
           </div>
			<CustomerMapping alldata={customerMappingData} mainHead="End-to-End Brand-Consumer Mapping" />
			<CustomerEngagement 
			 heading={"Acquire data, build intelligence"}
			 subHeading={"Solutions that helps you acquire key consumer data, build intelligence, boost analytics, and adapt to evolving marketing processes, establish service networks and drive futureproof growth models"}
			  url={"#qsealUseCase"}
			/>
			<Integrate
			heading={"Benefits"}
			subHeading={"<ul> <li> Build brand trust, revenue streams, operational efficiency, digital experiences, OTO and more </li><br /><li> Bulk processing, productivity enhancement, process optimization, value chain visibility (deep trace), and digital experience </li><br /><li>Digital asset & document authentication, patented product digital anti-counterfeit solution, easy public authentication on permissioned blockchain and seamless experience </li></ul>"}
			image={img}
			/>
            <div id="qsealUseCase" className="product-useCase container">
            <h1><strong>Use cases</strong></h1>
<h4>Industrial Products</h4>
<div className="usecasbox">
<img src={industrial} alt="" />
<p>Counterfeits is growing concern and results in product revenue losses. Brand protection methods includes authentication of genuine products. Cryptoblock QR delivers secure anti-counterfeit solutions with various schemes of its protection, customized to industry needs and consumer segments. &nbsp;</p>
</div>
<h4>Education</h4>
<div className="usecasbox">
<img src={eduction} alt="" />
<p>Certificate authentication is now one click away. Recruiters can validate certificates and eliminate expensive verification services. Universities / Colleges offering professional Diploma / UG / PG / PhD / PGDM programs leverage Cryptoblock powered digital signatures on certificates with smartphone single click authentication.</p>
</div>
<h4>Skilling / Training institutes</h4>
<div className="usecasbox">
<img src={certificate} alt="" />
<p>Professional skilling or training institutes offer unique programs recognized in the industry. These institutes deliver digital certificates on permissioned blockchain for public authentication and digitized social credentials for user profile platforms.</p>
</div>
<h4>Corporates</h4>
<div className="usecasbox">
<img src={corporate} alt="" />
<p>Functional and legal documents between two companies (B2B) can be digitally signed and stored on private vault with restricted access to specific people in the organization. Avoid mail clusters and keep latest documents on the private cloud using permissioned blockchain.&nbsp;</p>
</div>
            </div>
		</div>
		</>
        
	);
}

