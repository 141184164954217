import React, {useEffect, useState} from "react";
import QRCode from 'qrcode.react';
import './tophead.css'
import './switch.css';
import { AiOutlineUpload, AiOutlineDownload} from "react-icons/ai";
import { BsUnion } from "react-icons/bs";
import { CgColorPicker } from "react-icons/cg";
import { IoIosLock} from "react-icons/io";
import Tophead from "./tophead";
import dummyimage from '../../images/QR_Code.png';
import Popup from './popup';
import Watermark_popup from "./watermark_popup";
import Dynamicqr_popup from "./dynamicqr_popup";

const WebformQR = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const[color,setColor]=useState(null);
  const [logo, setLogo]=useState(null);
  const [qrCodeText, setQRCodeText] = useState('');
  const[qr_type,setqr_type]=useState("logo"); //logo,color
  
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopup1 = () => {
    setIsOpen1(!isOpen1);
  }
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  }
    return (
        <>
          <Tophead />
          <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center', paddingBottom:'80px'}}>
<div className="row brandqr">
<div className="col">
<div className="qr_data">
   <label htmlFor="exampleInputfile" style = {{cursor:"pointer"}}>Sign up to create form or Enter link for online form </label><br />
   <input type="url" className="form-control" d="exampleInputurl" placeholder='Enter cloud link here' />
   <p>Use our short URL for better readability</p>
   <label htmlFor="exampleInputfile" style = {{cursor:"pointer"}} onClick={togglePopup}>URL Shortner <IoIosLock /> </label><br />
  <input type="checkbox" id={`react-switch-new`} className="react-switch-checkbox" onClick={togglePopup2}/>
  <label className="react-switch-label" htmlFor={`react-switch-new`}>
  <span className={`react-switch-button`} /></label><h6>Toggle for Dynamic QR <IoIosLock />  </h6>
   <h5>QR Code Customization</h5><hr />
   <div className="custo_item">
     <ul>
     <li><a  onClick={()=>{setqr_type("logo")}} className={qr_type=="logo"?"active":""}><BsUnion /> Pick QR Logo </a></li>
     <li><a onClick={()=>{setqr_type("color")}} className={qr_type!="logo"?"active":""}><CgColorPicker /> Pick QR Color</a></li>
     </ul>
   </div><br />
   {qr_type=="color" && <input type="color" className="form-control-file" id="exampleiInputcolor"    onChange = {(e) => {setColor(e.target.value)}}></input>}
   {qr_type=="logo" && <div className="qr_logo">
  <label htmlFor="exampleFormControlFile1"> <AiOutlineUpload /> Upload Logo</label>
  <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange = {(e) => { setLogo(URL.createObjectURL(e.target.files[0]))}}/>
  </div>}<br /> <br />
   {/* <input type="url" className="form-control" d="exampleInputurl" placeholder='Paste the URL to shorten ' />
   <input type="url" className="form-control" d="exampleInputurl" placeholder='Short URL' /> */}
</div>
</div>
<div className="col">
<div className='qr_code'>
  <div className="qrcodeimg">
  <img src={dummyimage} />
</div>
<p style = {{cursor:"pointer"}} onClick={togglePopup1}>Signup to remove watermark <IoIosLock /> </p>
<button type="button" className="download-btn" value="Download"><AiOutlineDownload /> Download (.PNG)</button>
</div>
</div>
</div>
{isOpen && <Popup handleClose={togglePopup}/>}
{isOpen1 && <Watermark_popup handleClose={togglePopup1}/>}
{isOpen2 && <Dynamicqr_popup handleClose={togglePopup2}/>}
</div>
</>
    );
};
export default WebformQR;