import React from "react";
import img from "../../images/blog-cryptoblock.svg";
import img2 from "../../images/nitin.webp";
import "./blogcontent.css";

function BlogContent() {
	return (
		<>
			<div className="position-relative">
				<div className="container blog-content">
					<h1>A QR Code<sup>®</sup></h1>
					<p>
					<span className="subhead">QR Code<sup>®</sup>  : </span>Quick Response Code- is a two-dimensional bar code created and patented by Japanese corporation Denso-Wave in 1994. Which is an efficient way to store and recover larger information than traditional one-dimensional barcodes. This machine-readable codes have transformed the way devices interacts with the print media.
					</p>
					<p>DENSO Wave Incorporated has allowed public to use patented code for free. Consequently, many websites now feature online QR Code generators for free. Such code generators are not certified by the International Organization for Standardization (ISO), and there is no easy way of telling whether they adhere to relevant ISO Standard 18004.</p>
					<h2 className="fw-bold">Cryptoblocks<sup>®</sup> and security</h2>
					<p>Cryptoblock<sup>®</sup> in the form of QR code encrypted and published on permissioned blockchain. Each individual block is secured using cryptography and robust Web-platform without compromising the QR code convenience. One of the key aspects of security beneath Cryptoblock is that the link leads user to a safe landing page validating attributes of product/document on the landing page. Cryptoblock<sup>®</sup> is immutable and secure as an anti-counterfeit solution. In real-time consumers can cross verify the various product attributes provided by the brand, and brand has no limit on number of attributes can be mapped per product. This is important to businesses and especially looking for solutions at unit level for the volume and scale.</p>
					<div className="p-1 p-md-4 img-wrap position-relative">
						<div className="blue-div"></div>
						<img className="main-img" src={img} alt="blog_pic" loading="lazy" />
					</div>
					<p>
						To prevent counterfeits, original product manufacturer encrypts attributes of unit item into the block and prints it on product. To authenticate, buyers scan this Cryptoblock QR using a smartphone camera (no need for dedicated mobile app) and validates the encrypted attributes with original product. Brandwise smart cloud service virtually validates the certificate and delivers the real-time authentication for consumer convenience, while acquiring the consumer insights to deliver better and personalized services.
					</p>
					<h2 className="fw-bold">Not just a QR</h2>
					<p><span className="subhead">Versatile :</span> Cryptoblocks storing product attributes, provenance information, document credentials and URLs are increasingly common in print media, packaging, and logistic solutions. The application of secure Cryptoblocks greatly simplifies the task of acquiring/accessing information and engaging with the target audience.</p>
					<p><span className="subhead">Secure :</span> A free QR generator may carry malicious activity or intermediary pages to capture data and show ads. Imitated QR labels can be used on counterfeit products, even a document can be tampered if not empowered with encryption and its advancement. [add image or icon]</p>
					<p><span className="subhead">Compliant :</span> Cryptoblocks are GS1, NIST and ISO compliant. Standardization is part of Ciphercode’s core belief. Strengthening brands with technology by ensuring compliances is a vital need for business stability, scalability, and global acceptance.</p>
					<p><span className="subhead">Compact : </span>Though it is a well-known 2D machine readable code, there is an art and science behind it, to meet the customer experience standards. Cryptoblock QRs are outcome of the subtle design process customized to industry and application.</p>
					<p><span className="subhead">Scalable :</span> Whether you are producing in millions or billions, it has never been easy to deploy blocks. Advancements in the smart packaging and printing technology made it simpler and efficient. Cryptoblocks turns legacy counterfeit measures to smart and engaging touchpoints, delivering the convenience to entire value chain. </p>
					<p><span className="subhead">Digitized : </span> Businesses are transforming their consumer touchpoints by adopting to Cryptoblock<sup>®</sup> authentication for products/documents which makes it a compelling choice as it comes with an enterprise grade solution suite. Advanced approach with private blockchain empowers the businesses to deliver privately owned publicly available authentic and immutable information. The Solution encapsulating immutability data integrity, data security, accessibility makes digital assets tamper proof at scale.</p>
					<p><span className="subhead">Intangible : </span> Subtle yet simple, Cryptoblock<sup>®</sup> brings intangible value benefits to businesses in addition to revenue uplifts, it is proven in certain cases where the business saw ROI of 15x, which is exceptional but not surprise.</p>
					<br />
					<p>Looking for authentication of digital asset / document?< br />
					Is the product counterfeit a concern?< br />
					Excited with industry leader’s best practices using Cryptoblock?< br />
					Visit our resources section for industry use cases and articles for more information
                     Talk to an expert at Brandwise for a custom yet scalable business solution.</p>
					<br/>
					<p>Read more about QR Code<br />
                     Download the White Paper by Denso Wave on QR Code<sup>®</sup> essentials.<br />
                    © QR Code is a registered trademark of Denso Wave Incorporated.
                    </p>
					<hr />
					<div className="d-flex flex-row blog-author">
						<img src={img2} alt="profile_pic" className="rounded-circle"/>
						<div className="px-3 d-flex flex-row flex-wrap justify-content-between w-100">
							<div className="align-self-center" style={{minWidth: "224px"}}>
								<p className="fw-bold">Nitin Mangesh</p>
								<p>Business Marketing</p>
							</div>
							<div className="align-self-center" style={{paddingRight: "5vw", minWidth: "calc(110px + 5vw)"}}>
								<p className="fw-bold">Date</p>
								<p>28 Feb 2022</p>
							</div>
						</div>
					</div>
					<div className="mt-4">	
		              <div className="col-md-12" id="fbcomment">
		              <div className="fb-comments" data-href="https://ciphercode.co/blogpost/2" data-width="700"></div>
	 	            </div>	
		        </div>
				</div>
				<div className="blue-line hide-sm">
					<svg width="188" height="11" viewBox="0 0 188 11" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="-1" y="0.0644531" width="188.487" height="10.752" fill="#3157EF" />
					</svg>
				</div>
			</div>
		</>
	);
}

export default BlogContent;
