import React, {useEffect} from "react";
import TopHead from "../components/Blognumberthree/topHead";
import BlogContent from "../components/Blognumberthree/blogcontent";
import { Helmet } from "react-helmet";

function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="Discover how to prevent and combat product counterfeits with the power of cryptoblock QR Codes Authentication Solution" />
                <meta name="keywords" content="Counterfiet prevention, Cryptoblock, Authentic, Authenticity" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="Prevent and Fight Counterfeits with Cryptoblock QR Codes" />
                <meta property="og:description" content="Discover how to prevent and combat product counterfeits with the power of cryptoblock QR Codes Authentication Solution" />
                <meta property="og:url" content="https://ciphercode.co/cryptoblock_anti-counterfeit" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="Prevent and Fight Counterfeits with Cryptoblock QR Codes" />
                <meta name="twitter:description" content="Discover how to prevent and combat product counterfeits with the power of cryptoblock QR Codes Authentication Solution" />
            </Helmet>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;
