import React from "react";
import "./signUp.css";
import {Link} from "react-router-dom";

function signUp() {
	return (
		<div className="container py-4 overflow-hidden">
			<div className="bluedesign-bg p-5 text-center">
				<h2>
				Start protecting your product<br></br>
                and build customer trust
				</h2>
				<Link to ="/schedule_demo"><button className="mt-4 btn-WhiteBg">
					Get a demo
				</button></Link>
			</div>
		</div>
	);
}

export default signUp;
