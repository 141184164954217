import React, {useEffect}  from "react";
import Signup from "../components/Onboarding/signup";


export default function Onboarding() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <>
         <Signup />
         
      </>
    )
}