import React from "react";
import img26 from "../../images/2 (26).png";
import img25 from "../../images/2 (1).png";
import imgqr from "../../images/QR_Code.png";
import img27 from "../../images/cryp_bg.gif";
import './tophead.css'
import URLQR from "./url_qr"




function TopHead(){
  const myStyle={
    backgroundImage: `url(${img27})` ,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    marginTop:"85px",
    };
    return (
      <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center'}}>
			<div className="tophead" style={myStyle}>
				<img alt="brandwise" className="absBg1" style={{opacity: 0.21}} src={img26} />
				<img alt="brandwise" className="absBg2" style={{opacity: 0.23}} src={img25} />
				<div className="row pb-5">
					<div className="container text-center">
						<h1>Create your Cryptoblock QR here</h1>
						<p className="px-6">Experience power of dynamic cryptoblock QR</p>
          </div>
        </div>
      </div>
    </div>
    )
}
export default TopHead;