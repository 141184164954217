import React, {useEffect} from "react";
import TopHead from "../components/AutoMobile/topHead";
import CustomerEngagement from "../components/AutoMobile/customerEngagement";
import CustomerMapping from "../components/AutoMobile/customerMapping";
import SignUp from "../components/AutoMobile/signUp";

export default function AutoMobile() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<TopHead />
			<CustomerMapping />
			<CustomerEngagement />
			<SignUp />
		</div>
	);
}

