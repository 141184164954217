import React from "react";
import "./customerMapping.css";

function MapTile({title, description, icon}){
    return (
		<div className="col-12 col-md-4 ">
			<div className="wrapper p-4">
				<div className="icon">
					{icon}
				</div>
				<h2 className="title">{title}</h2>
				<p>{description}</p>
			</div>
		</div>
	);
}


export default function CustomerMapping() {
	return (
		<div className="container customer-mapper text-center">
			<h1>End-to-End Brand-consumer mapping</h1>
			<div className="row mt-5 text-start align-items-stretch">
				<MapTile
					icon={
						<svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16.2125 2.15283H3.575C2.41175 2.15283 1.46875 3.09583 1.46875 4.25908V16.8966C1.46875 18.0598 2.41175 19.0028 3.575 19.0028H16.2125C17.3757 19.0028 18.3187 18.0598 18.3187 16.8966V4.25908C18.3187 3.09583 17.3757 2.15283 16.2125 2.15283Z" stroke="black" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M16.2125 27.4277H3.575C2.41175 27.4277 1.46875 28.3707 1.46875 29.534V42.1715C1.46875 43.3347 2.41175 44.2777 3.575 44.2777H16.2125C17.3757 44.2777 18.3187 43.3347 18.3187 42.1715V29.534C18.3187 28.3707 17.3757 27.4277 16.2125 27.4277Z" stroke="black" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M41.4879 2.15283H28.8504C27.6871 2.15283 26.7441 3.09583 26.7441 4.25908V16.8966C26.7441 18.0598 27.6871 19.0028 28.8504 19.0028H41.4879C42.6511 19.0028 43.5941 18.0598 43.5941 16.8966V4.25908C43.5941 3.09583 42.6511 2.15283 41.4879 2.15283Z" stroke="black" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M26.7441 27.4277V35.8527" stroke="#1FDC00" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M26.7441 44.2777H35.1691V27.4277" stroke="#1FDC00" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M35.168 31.6401H43.593" stroke="#1FDC00" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
							<path d="M43.5938 40.0654V44.2779" stroke="#1FDC00" strokeWidth="2.7" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					}
					title="GS1 Compliant Cryptoblock QR Codes"
					description="Brandwise is compliant with GS1 standards allowing our systems to efficiently communicate with your existing supply chain solutions leading to easy implementation and seamless billing at the authorized service centers."
				/>
				<MapTile
					icon={
						<svg width="56" height="40" viewBox="0 0 56 40" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M36.3555 33.8945C36.3555 34.8787 35.5584 35.6758 34.5742 35.6758H22.1055C21.1213 35.6758 20.3242 34.8787 20.3242 33.8945H0.730469V35.6758L4.27961 38.0413C5.44989 38.8215 6.82502 39.2383 8.23131 39.2383H48.4484C49.8547 39.2383 51.2298 38.8215 52.401 38.0413L55.9492 35.6758V33.8945H36.3555Z" fill="#1FDC00" />
							<path d="M50.6055 0.941406H6.07422C5.09453 0.941406 4.29297 1.74297 4.29297 2.72266V32.1133L52.3867 31.2227V2.72266C52.3867 1.74297 51.5852 0.941406 50.6055 0.941406ZM48.8242 28.5508H7.85547V4.50391H48.8242V28.5508Z" fill="black" />
						</svg>
					}
					title="Device Agnostic"
					description="Brandwise QR codes device agnostic i.e. a customer can authenticate the genuineness of your product by just using a QR code scanner present in their mobile phone or any other connected device. "
				/>
				<MapTile
					icon={
						<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M22.2188 41.9881C15.6188 41.9881 7.09375 27.3168 7.09375 11.7381C7.09324 11.4804 7.16515 11.2277 7.3013 11.0089C7.43744 10.7901 7.63234 10.614 7.86375 10.5006L21.71 3.62558C21.9001 3.5311 22.1096 3.48193 22.3219 3.48193C22.5342 3.48193 22.7436 3.5311 22.9338 3.62558L36.5875 10.5006C36.8164 10.6159 37.0084 10.7929 37.1421 11.0115C37.2757 11.2302 37.3455 11.4818 37.3438 11.7381C37.3438 27.3168 28.8188 41.9881 22.2188 41.9881ZM9.84375 12.5906C10.1463 27.2481 18.0938 39.2381 22.2188 39.2381C26.3438 39.2381 34.2913 27.2481 34.5938 12.5768L22.315 6.40308L9.84375 12.5906ZM23.1813 25.0893L28.6813 19.5893L26.7288 17.6368L22.0263 22.3393L18.8363 20.2218L17.3238 22.5043L21.4488 25.2543C21.6764 25.4073 21.9445 25.4887 22.2188 25.4881C22.3997 25.4891 22.5791 25.4544 22.7466 25.386C22.9142 25.3176 23.0665 25.2168 23.195 25.0893H23.1813Z" fill="black" />
							<path d="M23.1817 25.0892L28.6817 19.5892L26.7292 17.6367L22.0267 22.3392L18.8367 20.2217L17.3242 22.5042L21.4492 25.2542C21.6768 25.4072 21.945 25.4886 22.2192 25.488C22.4002 25.489 22.5796 25.4543 22.7471 25.3859C22.9146 25.3175 23.067 25.2167 23.1955 25.0892H23.1817Z" fill="#1FDC00" />
						</svg>
					}
					title="Whitelabelled solution"
					description="Our solutions can be integrated into your existing systems which means your customers will see your brand website for any interactions including product authentication certificates, warranty registration, and tracing. "
				/>
			</div>
		</div>
	);
}


