import React from "react";
import parse from "html-react-parser";
import "./product-top.css";

function ProductTop({productHead, productSubHead,mainImage,}) {
  return (
    <div className="container top-head">
      <div className="product-top-content">
        <div className="row">
          <div className="col-12 col-md-6 order-md-2">
            {parse(productHead)}
            <h4>{productSubHead}</h4>
          </div>
          <div className="col-12 col-md-6 order-md-2"> 
            <div className="product-img">
              <img src={mainImage}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductTop;
