import React, {useEffect}from 'react';
import TopHead from "../components/Home/top-head";
import Feedback from "../components/Home/feedback";
import Footertwo from '../components/Home/footerhome';
import Potential from '../components/Home/potential';
import AgeChallenge from '../components/Home/agechallenge';
import BrandDiff from '../components/Home/branddiff';
import Transformation from '../components/Home/transformation';
import Discover from '../components/Home/discover';
import whitelogo from "../images/favicon.png";
import { Helmet } from "react-helmet";

function Home() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <div>
        <Helmet>
        <title>Ciphercode</title>
        <meta name="description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta name="keywords" content="Ciphercode, Brandwise, Customer Engagement, Product Digitzation, Authentication, Brand Loyalty" />
        <meta property="og:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta property="og:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
        <meta property="og:url" content="https://ciphercode.co" />
        <meta property="og:image" content={whitelogo} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Customer Engagement and Digitization Leading Solutions" />
        <meta name="twitter:description" content="Cutting-edge solutions for customer engagement, product digitization and authentication. Boost brand loyalty, trust and sales with custom QR campaigns." />
      </Helmet>
          <TopHead></TopHead>
          <Potential></Potential>
          <AgeChallenge></AgeChallenge>
          <BrandDiff></BrandDiff>
          <Transformation></Transformation>
          <Feedback></Feedback>
          <Discover></Discover>
          <Footertwo></Footertwo>
        </div>
    )
}
export default Home;
