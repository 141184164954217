import React from "react";
import './employeesay.css';
import img1 from "../../images/salary.svg";
import img2 from "../../images/health.svg";
import img3 from "../../images/challeging.svg";
import img4 from "../../images/workplace.svg";


export default function EmployeeSay() {
    return (
        <div style={{ textAlign: '-webkit-left' }}>
            <div className="employee-content">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h1>We care about our employees</h1>
                            <ul>
                                <li>Innovative Product Authentication</li>
                                <li>Dynamic Consumer Engagement</li>
                                <li>Unit Level Control</li>
                                <li>AI Driven Insights</li>
                            </ul>
                        </div>
                        <div className="col">
                            <div className="employeebox">
                                <img src={img2} alt="Entrepreneurial work environment icon" style={{ marginTop: '14px' }} />
                                <p>Entrepreneurial Work Environment</p>
                            </div>
                            <div className="employeebox">
                                <img src={img3} alt="Challenging tasks icon" />
                                <p>Challenging Tasks for Self-Development</p>
                            </div>
                            <div className="employeebox">
                                <img src={img1} alt="Competitive salaries icon" />
                                <p>Competitive Salaries and Flexible Salary Policy</p>
                            </div>
                            <div className="employeebox">
                                <img src={img4} alt="Well-equipped workplace icon" />
                                <p>Well-Equipped Workplace</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}