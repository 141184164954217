import React from "react";
import aboutus from "../../images/about_1.gif";
import img1 from "../../images/Partners_image.png";
import img2 from "../../images/Partners.png";
import author from "../../images/venkat.png";
import author4 from "../../images/daniel.png";
import author3 from "../../images/shailendra.png";
import author2 from "../../images/govind.png";
import author5 from "../../images/radhakrishna.png";
import author6 from "../../images/manjunath.png";
import author7 from "../../images/Rohaan.png";
import author8 from "../../images/ravishankar.png";
import "./aboutme.css"

function Aboutme() {
	return (
		<>
		<div className="position-relative">
		<div className="container about-content">
		<div className="row">
		    <p className="aboutus_para" style={{fontSize:"20px", fontWeight:"800"}}>About Us</p>
            <div className="col-md-6 order-md-2">
				<h2>Transforming Businesses by bringing <span>intelligence</span> into products and platforms.</h2>
				<p>Ciphercode leads the  <span>product digitization </span> tech, offering
                   cryptographic <span>digital twins</span>  for physical objects. We pioneer
                   innovation, crafting solutions that drive business value and
                   inspire exceptional <span>customer experiences </span>. Committed to
                   delivering timely value, we anticipate and fulfill evolving
                   needs with agility and foresight in the era of data privacy
                   and AI.</p>
			</div>
			<div className="col-md-6 order-md-2" style={{textAlign: 'right'}}>
			    <img src={aboutus}  alt='Scanning QR Code by Brandwise to enable Product Digitization, 
				Customer Engagement and Track & Trace'  style={{width:'100%'}}/> 
			</div>
		</div>
		<br></br><br></br>
		  {/* Our Mission Section */}  
		<div className="row">
		<p className="aboutus_para" style={{fontSize:"20px", fontWeight:"800"}}>Mission & Vision</p>
            <div className="col-md-6 order-md-2">
				<h2>To be the most preferred<span> digital platform </span> for brands
                    for their <span>consumer intelligence </span> and <span>trust</span>.</h2>
				<p>We are committed to equip brands with the tools to deeply
                   understand, actively engage, and expand their customer
                   base. By infusing intelligence into objects, applications, and
                   systems, we aim to eliminate inefficiencies and contribute to
                   the creation of a sustainable, safe, and better world.</p>
			</div>
			<div className="col-md-6 order-md-2" style={{marginTop:"100px",paddingLeft:"50px" }}>
				<div className="shadow-none p-3 mb-5 rounded-4" style={{backgroundColor:"#B5D1EF ",position:"relative"}}>
					<div class="position-absolute start-50 translate-middle rounded-circle overflow-hidden" style={{width: "90px", height: "90px"}}>
						<img className="rounded-top" src={author} alt="cteam_pic" style={{width: "100%", height: "100%", objectFit: "cover",}} />
					</div>
					<h6 style={{marginTop:"50px",textAlign:"center",fontSize:"16px"}}><span style={{color:"blue"}}>Terugu Venkat</span><br/>Founder | CEO</h6>
					<p style={{fontSize:"15px"}}>In today's world of fleeting attention and
					instant gratification, it's crucial for brands to
					convert every consumer touchpoint into an
					intelligent channel, delivering adaptive
					digital experiences</p>
				</div>
			</div>
		</div><br/>
         {/* Our Values Section */}
		<div className="row our-value">
		    <h5>Our Values</h5>
            <div className="col-12 col-md-4 order-md-2" >
				<div className="our-value-card">
					<h6>Innovation</h6>
					<p>Pioneering Progress</p>
					<hr/>
					<p style={{fontStyle:"normal", fontSize: 13}}>We foster curiosity and collaboration,
					anticipating market needs, crafting
					groundbreaking solutions that fuel
					customer growth.
					</p>
				</div>
			</div>
			<div className="col-12 col-md-4 order-md-2">
				<div className="our-value-card">
					<h6>Customer Centric</h6>
					<p>Prioritizing Customers</p><hr/>
					<p style={{fontStyle:"normal", fontSize: 13}}>We listen, exceed expectations, build
					trust, and deliver unmatched value
					through proactive solutions and
					unwavering commitment.</p>
				</div>
			</div>
			<div className="col-12 col-md-4 order-md-2">
				<div className="our-value-card">
					<h6>Ownership & Accountability</h6>
					<p>Empowering Excellence</p><hr/>
					<p style={{fontStyle:"normal", fontSize: 13}}>We empower individuals to excel,
					fostering trust, collaboration, continuous
					improvement, ensuring transparency,
					integrity, and reliability.</p>
				</div>
			</div>
		</div><br></br>
		<div className="row our-value justify-content-center">
            <div className="col-12 col-md-4 order-md-2">
				<div className="our-value-card">
					<h6>Humility & Kindness</h6>
					<p>Embracing Empathy</p>
					<hr/>
					<p style={{fontStyle:"normal", fontSize: 13}}>We value everyone, fostering respect,
					understanding, and collaboration in an
					empathetic and supportive
					environment.</p>	
				</div>
			</div>
			<div className="col-12 col-md-4 order-md-2">
				<div className="our-value-card">
					<h6>Integrity & Honesty</h6>
					<p>Upholding Trust</p><hr/>
					<p style={{fontStyle:"normal", fontSize: 13}}>We are guided by integrity, earning
					trust, credibility, and lasting
					relationships, upholding the highest
					ethical standards in all actions.</p>
				</div>
			</div>
		</div><br/>
		{/* Our Team Section */}
		<div className="row justify-content-center">
			<h6 style={{fontSize:"20px", fontWeight:"800",marginTop: "50px"}}>The Leadership Team</h6><br/><br/>
			<div className="container" style={{padding: "12px"}}>
				<div className="row">
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author} alt="cteam1_pic"/>
							<h6><span>Terugu Venkat</span><br/>Founder | CEO</h6>
						</div>
					</div>
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author2} alt="cteam2_pic"/>
							<h6><span>Govind Babu</span><br/>GTM Strategy Advisor</h6>
						</div>
					</div>
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author3} alt="cteam2_pic"/>
							<h6><span>Shailendra Mruthyunjayappa</span><br/>Board Advisor</h6>
						</div>
					</div>
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author4} alt="cteam2_pic"/>
							<h6><span>Daniel Langer</span><br/>Consultant Advisor-Luxury</h6>
						</div>
					</div>
					
					<div className="col-md-4 col-xl-3 order-md-2" >
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author5} alt="cteam2_pic"/>
							<h6><span>Radhakrishna</span><br/>Director DM Strategies</h6>
						</div>
					</div>
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author6} alt="cteam2_pic"/>
							<h6><span>Manjunath Sajjan</span><br/>Principal Engineer</h6>
						</div>
					</div>
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author7} alt="cteam2_pic"/>
							<h6><span>Rohaan Joseph</span><br/>Marketing & Sales Exec</h6>
						</div>
					</div>
					<div className="col-md-4 col-xl-3 order-md-2">
						<div className="shadow-right p-3 mb-5">
							<img className="team-image rounded-circle" src={author8} alt="cteam2_pic"/>
							<h6><span>Ravishankar</span><br/>Director BD - Global</h6>
						</div>
					</div>
				</div>
			</div>
		</div><br></br>
		<div className="row">
			<h6  style={{fontSize:"20px", fontWeight:"800"}}>Our Brief Success Story</h6><br/><br/>
			<div className="col-md-7 order-md-2">
				<p style={{marginTop:'0px'}}>Ciphercode, born from our founder's ordeal with authenticity
				challenges, emerged in 2018 with its first patent filing and MVP
				launch.. By 2019, securing our first customer set the tone for our
				journey. Notably, in 2020, CIO Review India recognized us among
				the top 20 SaaS startups, bolstering our position.<br/><br/>
				2022 marked a pivotal moment as we were chosen by Infosys
				Innovation Network for Blockchain innovations and acquired critical
				patents. By 2023, our enterprise SaaS platform, Brandwise,
				reached millions of consumer touchpoints through QR codes on
				various physical products, cementing our role as leaders in first-
				party data enablement.<br/><br/>
				Presently, our focus lies on global regulatory compliance, forging
				partnerships with industry leaders, and accelerating growth
				metrics.</p>
			</div>
			<div className="col-md-5 order-md-2" style={{paddingLeft:"80px"}}>
				<div class="shadow-none p-3 ps-5 pe-5 mb-5 rounded-4" style={{backgroundColor:"#B5D1EF ",width:"100%"}}>
					<h6 style={{fontSize:"16px",color:"blue", marginTop:"0"}}>2018</h6>
					<p style={{fontSize:"16px",marginTop:'0px',marginBottom:'0px'}}>First Patent & MVP Launch</p>
					<div class="vertical-line"></div>
					<h6 style={{fontSize:"16px",color:"blue", marginTop:"0"}}>2020</h6>
					<p style={{fontSize:"16px",marginTop:'0px',marginBottom:'0px'}}>Recognized by CIO Review India as a Top 20 SaaS Startup</p>
					<div class="vertical-line"></div>
					<h6 style={{fontSize:"16px",color:"blue", marginTop:"0"}}>2022</h6>
					<p style={{fontSize:"16px",marginTop:'0px',marginBottom:'0px'}}>Selected by Infosys Innovation Network for Blockchain innovations</p>
					<div class="vertical-line"></div>
					<h6 style={{fontSize:"16px",color:"blue", marginTop:"0"}}>2023</h6>
					<p style={{fontSize:"16px", marginTop:'0px',marginBottom:'0px'}}>Serving Millions of Customer Touchpoints & Digitized Products</p>
				</div>
			</div>
		</div>
		<div className="text-centre">
			<h6 style={{fontSize:"20px", fontWeight:"800", textAlign:'center'}}>Trusted by most Innovative Brands</h6><br/><br/>
		</div><br/>
		<img className="partner-img" src={img2} alt="partner_pic" style={{width:"100%"}} /><br/><br/>
		<div className="text-centre">
			<h6 style={{fontSize:"20px", fontWeight:"800", textAlign:'center'}}>Partners in Innovation</h6><br/><br/>
		</div><br/>
		<img className="partner2-img" src={img1} alt="partner2_pic" style={{width:"100%"}} />	
		</div>
		</div>
		</>
	);
}
export default Aboutme;