import React from "react";
import './integrate.css'
import parse from "html-react-parser";


function Integrate({
	heading,
	subHeading,
	image,
}) {
	return (
		<div className="dark-bg">
			<div className="container product-engagement">
				<div className="d-flex flex-column flex-md-row h-100 justify-content-around">
					<div className="col-md-6 align-self-center px-md-5">
						<h1>{heading}</h1>
						<h3>{parse( subHeading)}</h3>
						{/* <button className="btn btn-BlueBg w-50 mt-2">Learn More</button> */}
					</div>
					<div className="col-md-6">
						<div className="hide-sm position-relative h-100">
							<img src={image} alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Integrate;