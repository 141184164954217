import React, {useEffect} from "react";
import TopHead from "../components/Blognumberseven/tophead";
import BlogContent from "../components/Blognumberseven/blogcontent";
import { Helmet } from "react-helmet";

function Blog() {
	useEffect(() => {window.scroll({top: 0, behavior: 'smooth'})});
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="Shift in Consumer Expectations from Information to Intelligence. Harness AI and Personalize Experiences for Ultimate Luxury" />
                <meta name="keywords" content="Consumer Expectations, AI, Information Era, Intelligence Era" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="The Shift from the information era to intelligence era" />
                <meta property="og:description" content="Shift in Consumer Expectations from Information to Intelligence. Harness AI and Personalize Experiences for Ultimate Luxury" />
                <meta property="og:url" content="https://ciphercode.co/information_to_intelligence" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="The Shift from the information era to intelligence era" />
                <meta name="twitter:description" content="Shift in Consumer Expectations from Information to Intelligence. Harness AI and Personalize Experiences for Ultimate Luxury" />
            </Helmet>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;