import React from 'react'

export default function FourOFourpage() {
  return (
    <div className='four0fourPage' style={{marginTop:'120px'}}>
        <svg width="404" height="404" viewBox="0 0 404 404" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M55.8483 306.831H48.4492" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M355.551 306.831H67.5508" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M247.194 337.765C260.721 337.765 271.688 334.748 271.688 331.027C271.688 327.305 260.721 324.289 247.194 324.289C233.666 324.289 222.699 327.305 222.699 331.027C222.699 334.748 233.666 337.765 247.194 337.765Z" fill="#263238"/>
<path d="M120.595 346.333C95.1012 346.333 91.3372 342.649 90.9664 339.675C90.8822 339.056 90.9433 338.425 91.1447 337.834C91.3461 337.242 91.6824 336.705 92.1271 336.266C98.9942 329.06 138.569 332.107 193.328 336.322C200.969 336.911 208.868 337.515 216.968 338.12C272.3 342.23 294.578 341.698 299.18 336.137C300.115 335 300.309 333.582 299.793 331.664C297.601 323.644 268.294 323.604 257.196 324.232C256.942 324.246 256.693 324.158 256.503 323.989C256.313 323.82 256.197 323.583 256.18 323.33C256.173 323.204 256.19 323.078 256.231 322.959C256.273 322.84 256.337 322.731 256.421 322.637C256.505 322.544 256.607 322.468 256.721 322.413C256.834 322.359 256.957 322.328 257.083 322.322C261.331 322.072 298.648 320.194 301.639 331.188C302.324 333.719 302.009 335.742 300.655 337.386C295.489 343.657 274.339 344.326 216.831 340.054C208.723 339.45 200.824 338.845 193.183 338.257C140.987 334.227 99.7518 331.059 93.5134 337.612C93.2667 337.846 93.0795 338.136 92.9674 338.457C92.8552 338.778 92.8214 339.121 92.8686 339.458C93.1023 341.36 97.7046 345.615 134.781 344.132C155.519 343.326 176.354 341.045 176.564 341.021C176.806 341.011 177.044 341.094 177.228 341.252C177.412 341.411 177.529 341.633 177.556 341.875C177.582 342.116 177.516 342.359 177.371 342.553C177.226 342.748 177.012 342.88 176.773 342.923C176.564 342.923 155.664 345.212 134.861 346.043C129.509 346.236 124.77 346.333 120.595 346.333Z" fill="white" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M238.086 336.943C254.026 336.943 266.948 324.021 266.948 308.08C266.948 292.14 254.026 279.217 238.086 279.217C222.145 279.217 209.223 292.14 209.223 308.08C209.223 324.021 222.145 336.943 238.086 336.943Z" fill="white"/>
<path d="M238.085 336.943C243.338 336.947 248.492 335.516 252.991 332.806C257.49 330.096 261.165 326.21 263.618 321.565C266.071 316.921 267.21 311.695 266.912 306.451C266.614 301.207 264.89 296.144 261.927 291.807C261.612 304.381 254.673 322.943 218.58 329.326C223.893 334.227 230.857 336.946 238.085 336.943Z" fill="#407BFF"/>
<path opacity="0.3" d="M238.085 336.943C243.338 336.947 248.492 335.516 252.991 332.806C257.49 330.096 261.165 326.21 263.618 321.565C266.071 316.921 267.21 311.695 266.912 306.451C266.614 301.207 264.89 296.144 261.927 291.807C261.612 304.381 254.673 322.943 218.58 329.326C223.893 334.227 230.857 336.946 238.085 336.943Z" fill="white"/>
<path d="M252.286 319.396C247.139 314.389 240.889 310.659 234.039 308.507C240.889 310.659 247.139 314.389 252.286 319.396H253.818C257.215 316.029 260.14 312.217 262.515 308.064C248.998 293.782 235.852 295.241 235.852 295.241C235.852 295.241 249.038 293.782 262.555 308.08C263.296 306.75 263.917 305.501 264.425 304.348C258.799 296.466 249.111 291.09 243.912 290.284L242.703 289.994C240.84 289.655 238.933 289.628 237.061 289.913C238.933 289.628 240.84 289.655 242.703 289.994L246.145 280.322C244.518 279.857 242.854 279.536 241.172 279.362C238.939 282.965 233.192 294.475 233.192 317.309C233.192 294.475 238.939 282.965 241.172 279.362C240.204 279.274 239.237 279.217 238.246 279.217C237.155 279.221 236.065 279.285 234.982 279.411C231.58 284.553 225.076 296.699 224.641 314.931C231.387 317.389 240.414 319.227 252.286 319.396ZM258.807 313.521C247.313 302.317 234.627 301.729 234.627 301.729C234.627 301.729 247.313 302.317 258.807 313.521V313.521Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M224.609 314.979C225.044 296.748 231.548 284.601 234.95 279.459C232.288 279.758 229.682 280.425 227.204 281.442C221.096 290.403 217.645 300.909 217.25 311.747C219.617 313.011 222.077 314.091 224.609 314.979Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M227.204 281.417C222.219 283.472 217.909 286.88 214.758 291.255C211.608 295.63 209.743 300.799 209.375 306.178C211.748 308.36 214.395 310.224 217.25 311.723C217.644 300.885 221.095 290.379 227.204 281.417V281.417Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M264.386 304.348C265.014 302.96 265.552 301.533 265.998 300.077C264.793 295.966 262.692 292.173 259.848 288.97L257.615 296.417L259.807 288.97C258.027 286.973 255.979 285.231 253.722 283.795L251.264 292.992L253.738 283.836C251.378 282.326 248.809 281.172 246.114 280.41L242.672 290.082C247.943 290.84 256.092 293.943 264.386 304.348Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M267.101 309.442C267.101 309.023 267.101 308.636 267.101 308.185C267.101 305.441 266.71 302.711 265.941 300.077C265.495 301.534 264.957 302.96 264.329 304.348C263.983 305.2 263.573 306.025 263.104 306.815C263.152 306.863 262.41 308.032 262.459 308.08C262.507 308.129 261.653 309.531 261.653 309.483C259.735 312.892 253.947 319.421 254.044 319.421C253.947 319.421 250.917 322.169 249.265 323.555C246.242 326.059 243.039 328.335 239.681 330.366H239.891H239.681C236.379 332.362 232.964 334.165 229.453 335.766C232.272 336.653 235.211 337.105 238.166 337.104C238.658 337.104 239.141 337.104 239.617 337.104C245.525 333.888 254.915 326.554 267.101 309.442Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M267.1 309.442C254.914 326.554 245.524 333.888 239.664 337.032C246.85 336.67 253.643 333.643 258.716 328.541C263.789 323.44 266.779 316.63 267.1 309.442V309.442Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M215.613 326.207C219.19 330.703 224.024 334.031 229.501 335.766C233.011 334.165 236.426 332.362 239.729 330.366C232.846 331.639 222.077 328.48 215.613 326.207Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M239.729 330.366C243.087 328.335 246.29 326.059 249.313 323.555C228.526 325.683 215.702 319.243 210.254 315.584C211.282 319.459 213.108 323.077 215.614 326.207C222.078 328.48 232.846 331.64 239.729 330.366Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M249.313 323.555C250.965 322.169 252.456 320.783 253.818 319.413H252.287C252.4 319.517 252.513 319.622 252.617 319.735C252.513 319.622 252.4 319.517 252.287 319.413C240.415 319.243 231.387 317.406 224.609 314.996C214.703 311.562 211.133 307.871 209.376 306.218C209.327 306.879 209.295 307.532 209.295 308.201C209.296 310.698 209.618 313.185 210.254 315.6C215.703 319.243 228.526 325.683 249.313 323.555Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M238.086 336.943C254.026 336.943 266.948 324.021 266.948 308.08C266.948 292.14 254.026 279.217 238.086 279.217C222.145 279.217 209.223 292.14 209.223 308.08C209.223 324.021 222.145 336.943 238.086 336.943Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M291.248 258.753C291.248 258.753 289.274 252.345 283.519 250.04C279.489 248.428 274.919 248.863 270.768 249.581C263.046 250.927 256.308 253.998 249.006 256.617C249.006 256.617 252.133 255.465 264.957 261.719C269.68 264.016 275.08 265.806 280.48 265.685C284.808 265.588 291.466 264.347 291.248 258.753Z" fill="#407BFF"/>
<path d="M291.941 239.917C291.941 239.917 291.941 233.203 287.105 229.342C283.736 226.65 279.207 225.723 275.015 225.215C267.229 224.272 259.895 225.264 252.125 225.658C252.125 225.658 255.454 225.465 265.924 235.153C269.784 238.716 274.443 241.988 279.626 243.431C283.801 244.591 290.523 245.333 291.941 239.917Z" fill="#407BFF"/>
<path d="M305.167 264.83C305.167 264.83 299.936 260.631 299.92 254.433C299.92 250.121 301.991 246.002 304.192 242.415C308.286 235.726 313.622 230.599 318.764 224.756C318.764 224.756 316.54 227.238 317.62 241.464C318.023 246.703 317.684 252.386 315.58 257.359C313.928 261.356 310.325 267.087 305.167 264.83Z" fill="#407BFF"/>
<path d="M323.706 275.131C323.706 275.131 322.964 268.465 327.333 264.064C330.363 261.01 334.756 259.559 338.843 258.576C346.467 256.738 353.866 256.875 361.628 256.367C361.628 256.367 358.307 256.553 349.022 267.385C345.605 271.415 341.357 275.163 336.352 277.202C332.362 278.838 325.769 280.346 323.706 275.131Z" fill="#407BFF"/>
<path d="M322.521 251.362C322.521 251.362 315.872 250.451 312.68 245.14C310.455 241.448 310.125 236.838 310.165 232.631C310.246 224.796 312.196 217.647 313.607 209.998C313.607 209.998 312.97 213.27 321.207 224.917C324.238 229.213 326.849 234.259 327.655 239.61C328.228 243.906 328.074 250.669 322.521 251.362Z" fill="#407BFF"/>
<path d="M341.655 239.425C341.655 239.425 346.434 244.132 352.592 243.455C356.872 242.964 360.725 240.409 364.029 237.813C370.187 232.977 374.66 227.061 379.859 221.282C379.859 221.282 377.659 223.773 363.401 224.361C358.154 224.579 352.552 225.57 347.853 228.246C344.081 230.358 338.818 234.605 341.655 239.425Z" fill="#407BFF"/>
<path d="M336.117 234.774C336.117 234.774 342.791 235.443 347.143 231.035C350.174 227.964 351.576 223.563 352.519 219.46C354.277 211.828 354.067 204.42 354.486 196.659C354.486 196.659 354.341 199.979 343.597 209.377C339.648 212.835 335.9 217.123 333.925 222.144C332.321 226.182 330.878 232.792 336.117 234.774Z" fill="#407BFF"/>
<path d="M312.285 299.746L300.76 271.383L287.469 238.587" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M314.945 294.87L326.463 255.432L338.875 231.059" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M319.369 279.362L330.895 272.713" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M326.462 255.432L320.699 242.577" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M333.676 242.641L347.732 236.37" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M302.977 276.703L307.853 257.206" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M296.512 261.88L285.252 255.875" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M318.193 313.924H308.586C307.015 313.923 305.494 313.375 304.285 312.373C303.075 311.371 302.253 309.978 301.96 308.435L298.543 290.437H328.236L324.827 308.435C324.532 309.979 323.709 311.371 322.498 312.373C321.287 313.375 319.765 313.924 318.193 313.924Z" fill="#263238" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M47.7793 204.203C47.7793 201.97 48.811 200.326 51.1323 200.326C53.4535 200.326 54.5255 201.938 54.5255 204.203V212.037C54.5255 214.27 53.4938 215.914 51.1323 215.914C48.7707 215.914 47.7793 214.302 47.7793 212.037V204.203ZM48.7868 212.102C48.7868 213.77 49.4477 214.995 51.1323 214.995C52.8168 214.995 53.5503 213.77 53.5503 212.102V204.139C53.5503 202.47 52.8732 201.245 51.1323 201.245C49.3913 201.245 48.7868 202.47 48.7868 204.139V212.102Z" fill="#263238"/>
<path d="M56.1133 204.203C56.1133 201.97 57.145 200.326 59.4662 200.326C61.7875 200.326 62.8595 201.938 62.8595 204.203V212.037C62.8595 214.27 61.8278 215.914 59.4662 215.914C57.1047 215.914 56.1133 214.302 56.1133 212.037V204.203ZM57.1208 212.102C57.1208 213.77 57.7817 214.995 59.4662 214.995C61.1508 214.995 61.8842 213.77 61.8842 212.102V204.139C61.8842 202.47 61.2072 201.245 59.4662 201.245C57.7253 201.245 57.1208 202.47 57.1208 204.139V212.102Z" fill="#263238"/>
<path d="M67.703 200.455C70.0726 200.455 70.9915 201.946 70.9915 204.179V205.847C70.9915 208.265 69.8711 209.474 67.4451 209.474H65.5348V215.785H64.5273V200.471L67.703 200.455ZM67.4451 208.555C69.2183 208.555 69.984 207.749 69.984 205.912V204.114C69.984 202.454 69.4117 201.374 67.703 201.374H65.5348V208.555H67.4451Z" fill="#263238"/>
<path d="M78.9642 204.154V204.485H77.997V204.09C77.997 202.478 77.3844 201.245 75.7241 201.245C74.0637 201.245 73.4431 202.43 73.4431 204.042C73.4431 207.757 78.9803 207.822 78.9803 212.053C78.9803 214.262 78.0212 215.882 75.6757 215.882C73.3302 215.882 72.3711 214.27 72.3711 212.053V211.247H73.3383V212.102C73.3383 213.714 73.967 214.923 75.6515 214.923C77.3361 214.923 77.9728 213.738 77.9728 212.102C77.9728 208.418 72.4356 208.354 72.4356 204.09C72.4356 201.785 73.4431 200.302 75.6596 200.278C77.8761 200.253 78.9642 201.946 78.9642 204.154Z" fill="#263238"/>
<path d="M81.4775 213.859V215.785H80.4941V213.859H81.4775Z" fill="#263238"/>
<path d="M84.2179 213.859V215.785H83.2266V213.859H84.2179Z" fill="#263238"/>
<path d="M86.9511 213.859V215.785H85.9355V213.859H86.9511Z" fill="#263238"/>
<path d="M51.0194 222.757C53.389 222.757 54.3079 224.24 54.3079 226.472V228.141C54.3079 230.559 53.1875 231.776 50.7615 231.776H48.8593V238.079H47.8438V222.765L51.0194 222.757ZM50.7615 230.817C52.5347 230.817 53.3004 230.011 53.3004 228.165V226.368C53.3004 224.707 52.7281 223.635 51.0194 223.635H48.8593V230.817H50.7615Z" fill="#263238"/>
<path d="M56.9992 234.67L56.2335 238.063H55.3066L58.6515 222.749H60.1426L63.5601 238.063H62.5606L61.7546 234.67H56.9992ZM57.1524 233.791H61.6176L59.3366 223.894L57.1524 233.791Z" fill="#263238"/>
<path d="M71.6053 226.497V227.633H70.622V226.433C70.622 224.821 69.9933 223.539 68.3007 223.539C66.6081 223.539 65.9875 224.788 65.9875 226.433V234.404C65.9875 236.016 66.6162 237.265 68.3007 237.265C69.9852 237.265 70.622 236.04 70.622 234.404V231.091H68.607V230.172H71.6053V234.331C71.6053 236.548 70.6381 238.208 68.3007 238.208C65.9633 238.208 64.9961 236.548 64.9961 234.331V226.497C64.9961 224.289 65.9552 222.62 68.3007 222.62C70.6462 222.62 71.6053 224.289 71.6053 226.497Z" fill="#263238"/>
<path d="M78.4797 229.834V230.753H74.2966V237.144H79.3341V238.063H73.2891V222.749H79.3341V223.668H74.2966V229.834H78.4797Z" fill="#263238"/>
<path d="M85.2899 238.079H84.3711V222.765H85.6849L90.2791 235.661V222.765H91.1979V238.079H90.0856L85.2496 224.377L85.2899 238.079Z" fill="#263238"/>
<path d="M92.8672 226.497C92.8672 224.264 93.8908 222.62 96.2121 222.62C98.5334 222.62 99.6053 224.232 99.6053 226.497V234.331C99.6053 236.564 98.5817 238.208 96.2121 238.208C93.8424 238.208 92.8672 236.596 92.8672 234.331V226.497ZM93.8747 234.404C93.8747 236.064 94.5275 237.289 96.2121 237.289C97.8966 237.289 98.6301 236.064 98.6301 234.404V226.433C98.6301 224.764 97.953 223.539 96.2121 223.539C94.4711 223.539 93.8747 224.764 93.8747 226.433V234.404Z" fill="#263238"/>
<path d="M103.853 238.079V223.676H100.75V222.757H108.004V223.676H104.869V238.079H103.853Z" fill="#263238"/>
<path d="M117.604 230.221V231.139H113.646V238.079H112.639V222.765H118.458V223.684H113.622V230.229L117.604 230.221Z" fill="#263238"/>
<path d="M119.924 226.497C119.924 224.264 120.956 222.62 123.277 222.62C125.598 222.62 126.67 224.232 126.67 226.497V234.331C126.67 236.564 125.638 238.208 123.277 238.208C120.915 238.208 119.924 236.596 119.924 234.331V226.497ZM120.931 234.404C120.931 236.064 121.592 237.289 123.277 237.289C124.961 237.289 125.695 236.064 125.695 234.404V226.433C125.695 224.764 125.018 223.539 123.277 223.539C121.536 223.539 120.931 224.764 120.931 226.433V234.404Z" fill="#263238"/>
<path d="M129.298 234.379C129.298 235.991 129.91 237.289 131.595 237.289C133.279 237.289 133.892 236.024 133.892 234.379V222.757H134.835V234.331C134.835 236.523 133.916 238.208 131.611 238.208C129.306 238.208 128.322 236.523 128.322 234.331V222.757H129.33L129.298 234.379Z" fill="#263238"/>
<path d="M137.518 238.079H136.6V222.765H137.913L142.508 235.661V222.765H143.426V238.079H142.314L137.518 224.377V238.079Z" fill="#263238"/>
<path d="M148.498 222.757C150.868 222.757 151.891 224.304 151.891 226.561V234.267C151.891 236.523 150.868 238.079 148.498 238.079H145.129V222.765L148.498 222.757ZM148.498 237.16C150.207 237.16 150.916 235.999 150.916 234.315V226.521C150.916 224.836 150.199 223.676 148.498 223.676H146.153V237.16H148.498Z" fill="#263238"/>
<path d="M186.807 276.001C168.865 276.001 158.081 260.083 154.252 246.083L154.123 245.591C153.919 244.912 153.854 244.199 153.932 243.495C154.01 242.79 154.23 242.108 154.577 241.49C154.924 240.873 155.393 240.331 155.954 239.899C156.516 239.466 157.159 239.151 157.845 238.973C158.531 238.794 159.246 238.756 159.947 238.86C160.648 238.964 161.321 239.209 161.925 239.579C162.53 239.95 163.053 240.438 163.465 241.015C163.876 241.593 164.167 242.247 164.319 242.939L164.416 243.302C167.326 253.957 175.249 266.136 187.959 265.435C190.349 265.352 192.695 264.767 194.845 263.719C196.994 262.67 198.899 261.182 200.436 259.349C207.408 251.354 210.527 233.968 209.834 225.199C209.768 224.636 209.817 224.066 209.977 223.523C210.136 222.979 210.404 222.473 210.764 222.035C211.123 221.598 211.567 221.237 212.07 220.975C212.572 220.712 213.122 220.554 213.686 220.509C214.251 220.464 214.819 220.533 215.357 220.713C215.894 220.892 216.39 221.178 216.814 221.554C217.238 221.929 217.583 222.386 217.826 222.897C218.07 223.409 218.208 223.964 218.233 224.53C219.039 234.291 217.975 255.271 208.375 266.273C205.918 269.166 202.889 271.519 199.478 273.183C196.067 274.848 192.348 275.788 188.556 275.945C187.967 275.985 187.379 276.001 186.807 276.001Z" fill="white"/>
<path d="M213.695 220.661C212.582 220.752 211.55 221.28 210.826 222.131C210.102 222.982 209.745 224.086 209.835 225.199C210.173 229.479 209.601 235.806 208.118 242.125C210.236 242.036 212.354 242.308 214.38 242.931C213.901 248.995 211.771 254.812 208.223 259.752C200.396 270.625 188.661 274.099 179.529 272.793C175.443 272.213 170.76 270.069 166.818 267.869C172.166 273.084 179.337 276.007 186.807 276.017C187.379 276.017 187.968 276.017 188.556 275.969C192.348 275.812 196.067 274.872 199.478 273.207C202.889 271.543 205.919 269.19 208.376 266.297C217.975 255.295 219.007 234.315 218.233 224.554C218.193 224 218.044 223.459 217.794 222.962C217.544 222.466 217.198 222.024 216.776 221.662C216.354 221.3 215.865 221.025 215.336 220.853C214.807 220.681 214.249 220.616 213.695 220.661Z" fill="#407BFF"/>
<path opacity="0.5" d="M213.695 220.661C212.582 220.752 211.55 221.28 210.826 222.131C210.102 222.982 209.745 224.086 209.835 225.199C210.173 229.479 209.601 235.806 208.118 242.125C210.236 242.036 212.354 242.308 214.38 242.931C213.901 248.995 211.771 254.812 208.223 259.752C200.396 270.625 188.661 274.099 179.529 272.793C175.443 272.213 170.76 270.069 166.818 267.869C172.166 273.084 179.337 276.007 186.807 276.017C187.379 276.017 187.968 276.017 188.556 275.969C192.348 275.812 196.067 274.872 199.478 273.207C202.889 271.543 205.919 269.19 208.376 266.297C217.975 255.295 219.007 234.315 218.233 224.554C218.193 224 218.044 223.459 217.794 222.962C217.544 222.466 217.198 222.024 216.776 221.662C216.354 221.3 215.865 221.025 215.336 220.853C214.807 220.681 214.249 220.616 213.695 220.661Z" fill="white"/>
<path d="M186.807 276.001C168.865 276.001 158.081 260.083 154.252 246.083L154.123 245.591C153.919 244.912 153.854 244.199 153.932 243.495C154.01 242.79 154.23 242.108 154.577 241.49C154.924 240.873 155.393 240.331 155.954 239.899C156.516 239.466 157.159 239.151 157.845 238.973C158.531 238.794 159.246 238.756 159.947 238.86C160.648 238.964 161.321 239.209 161.925 239.579C162.53 239.95 163.053 240.438 163.465 241.015C163.876 241.593 164.167 242.247 164.319 242.939L164.416 243.302C167.326 253.957 175.249 266.136 187.959 265.435C190.349 265.352 192.695 264.767 194.845 263.719C196.994 262.67 198.899 261.182 200.436 259.349C207.408 251.354 210.527 233.968 209.834 225.199C209.768 224.636 209.817 224.066 209.977 223.523C210.136 222.979 210.404 222.473 210.764 222.035C211.123 221.598 211.567 221.237 212.07 220.975C212.572 220.712 213.122 220.554 213.686 220.509C214.251 220.464 214.819 220.533 215.357 220.713C215.894 220.892 216.39 221.178 216.814 221.554C217.238 221.929 217.583 222.386 217.826 222.897C218.07 223.409 218.208 223.964 218.233 224.53C219.039 234.291 217.975 255.271 208.375 266.273C205.918 269.166 202.889 271.519 199.478 273.183C196.067 274.848 192.348 275.788 188.556 275.945C187.967 275.985 187.379 276.001 186.807 276.001Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M176.457 265.096C176.884 263.121 172.161 266.208 168.203 269.086C169.667 270.323 171.243 271.421 172.91 272.366C174.321 270.069 176.045 266.998 176.457 265.096Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M186.806 276.001C187.379 276.001 187.967 276.001 188.555 275.953C190.268 275.857 191.969 275.617 193.641 275.236C191.417 272.334 188.354 268.498 187.475 268.207C186.468 267.869 184.525 272.81 183.445 275.808C184.561 275.938 185.683 276.003 186.806 276.001Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M164.416 243.302L164.319 242.939C164.167 242.247 163.876 241.593 163.465 241.015C163.053 240.438 162.53 239.95 161.925 239.579C161.321 239.209 160.648 238.964 159.947 238.86C159.246 238.756 158.531 238.794 157.845 238.972C157.159 239.151 156.516 239.466 155.954 239.898C155.393 240.331 154.924 240.873 154.577 241.49C154.23 242.108 154.01 242.79 153.932 243.494C153.854 244.199 153.919 244.912 154.123 245.591L154.252 246.082C155.596 250.999 157.66 255.69 160.378 260.002L169.977 255.432C167.46 251.727 165.58 247.627 164.416 243.302Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M208.375 266.273C209.113 265.419 209.796 264.52 210.422 263.581C207.376 262.541 202.814 261.107 201.121 261.26C199.114 261.445 201.838 267.893 203.241 270.932C205.129 269.585 206.852 268.022 208.375 266.273Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M208.544 249.049C208.222 249.693 212.163 252.563 214.823 254.408C215.666 251.898 216.345 249.335 216.854 246.735C213.63 247.38 208.858 248.42 208.544 249.049Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M218.16 237.128C218.337 234.831 218.426 232.639 218.434 230.624C215.662 232.147 212.373 234.259 212.373 235.645C212.373 237.031 215.452 237.2 218.16 237.128Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M174.764 174.768C174.764 174.768 166.938 197.432 171.153 215.068C172.401 220.228 174.825 225.031 178.235 229.101C181.645 233.17 185.949 236.398 190.811 238.53C190.811 238.53 179.374 242.56 181.945 248.758C184.516 254.957 194.374 250.967 197.59 248.96C200.806 246.953 204.844 240.94 205.408 236.926C205.766 234.876 205.902 232.792 205.811 230.712C205.811 230.712 218.844 229.31 227.67 222.088C227.67 222.088 235.496 228.302 241.114 227.101C246.732 225.9 254.751 215.866 253.945 206.846C253.139 197.827 247.941 197.835 244.765 199.206C243.116 199.829 241.613 200.784 240.348 202.01C240.348 202.01 244.958 196.368 240.348 188.574C238.987 186.288 237.124 184.342 234.9 182.882C232.675 181.423 230.147 180.489 227.508 180.152C227.508 180.152 225.09 167.312 217.885 162.5C210.679 157.689 184.992 152.707 174.764 174.768Z" fill="white"/>
<path d="M234.352 182.562C230.631 188.768 225.482 193.997 219.333 197.813C213.185 201.629 206.214 203.922 199 204.501C183.042 205.903 174.095 202.76 169.984 200.576C170.483 191.806 172.108 183.138 174.82 174.784C185.049 152.723 210.72 157.737 217.941 162.549C225.163 167.36 227.565 180.2 227.565 180.2C229.968 180.502 232.28 181.306 234.352 182.562ZM244.765 199.206C243.117 199.829 241.613 200.783 240.348 202.01C241.324 200.665 241.968 199.107 242.226 197.465C231.385 202.865 232.232 214.391 230.886 214.391C229.225 214.391 228.403 208.595 228.403 208.595C228.403 208.595 226.332 216.873 213.895 222.676C201.459 228.479 187.394 223.917 187.394 223.917C187.394 223.917 190.296 230.543 182.84 229.713C180.748 229.513 178.742 228.78 177.013 227.585C180.588 232.351 185.336 236.111 190.795 238.498C190.795 238.498 179.358 242.528 181.929 248.726C184.5 254.924 194.366 250.935 197.574 248.928C200.782 246.921 204.828 240.908 205.392 236.894C205.751 234.843 205.886 232.76 205.795 230.68C205.795 230.68 218.828 229.277 227.654 222.056C227.654 222.056 235.48 228.27 241.098 227.069C246.716 225.868 254.735 215.833 253.929 206.814C253.123 197.795 247.941 197.835 244.765 199.206Z" fill="#407BFF"/>
<path opacity="0.5" d="M234.352 182.562C230.631 188.768 225.482 193.997 219.333 197.813C213.185 201.629 206.214 203.922 199 204.501C183.042 205.903 174.095 202.76 169.984 200.576C170.483 191.806 172.108 183.138 174.82 174.784C185.049 152.723 210.72 157.737 217.941 162.549C225.163 167.36 227.565 180.2 227.565 180.2C229.968 180.502 232.28 181.306 234.352 182.562ZM244.765 199.206C243.117 199.829 241.613 200.783 240.348 202.01C241.324 200.665 241.968 199.107 242.226 197.465C231.385 202.865 232.232 214.391 230.886 214.391C229.225 214.391 228.403 208.595 228.403 208.595C228.403 208.595 226.332 216.873 213.895 222.676C201.459 228.479 187.394 223.917 187.394 223.917C187.394 223.917 190.296 230.543 182.84 229.713C180.748 229.513 178.742 228.78 177.013 227.585C180.588 232.351 185.336 236.111 190.795 238.498C190.795 238.498 179.358 242.528 181.929 248.726C184.5 254.924 194.366 250.935 197.574 248.928C200.782 246.921 204.828 240.908 205.392 236.894C205.751 234.843 205.886 232.76 205.795 230.68C205.795 230.68 218.828 229.277 227.654 222.056C227.654 222.056 235.48 228.27 241.098 227.069C246.716 225.868 254.735 215.833 253.929 206.814C253.123 197.795 247.941 197.835 244.765 199.206Z" fill="white"/>
<path d="M205.851 230.728C201.525 230.843 197.231 229.965 193.298 228.161C189.364 226.358 185.897 223.676 183.162 220.323" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M174.764 174.768C174.764 174.768 166.938 197.432 171.153 215.068C172.401 220.228 174.825 225.031 178.235 229.101C181.645 233.17 185.949 236.398 190.811 238.53C190.811 238.53 179.374 242.56 181.945 248.758C184.516 254.957 194.374 250.967 197.59 248.96C200.806 246.953 204.844 240.94 205.408 236.926C205.766 234.876 205.902 232.792 205.811 230.712C205.811 230.712 218.844 229.31 227.67 222.088C227.67 222.088 235.496 228.302 241.114 227.101C246.732 225.9 254.751 215.866 253.945 206.846C253.139 197.827 247.941 197.835 244.765 199.206C243.116 199.829 241.613 200.784 240.348 202.01C240.348 202.01 244.958 196.368 240.348 188.574C238.987 186.288 237.124 184.342 234.9 182.882C232.675 181.423 230.147 180.489 227.508 180.152C227.508 180.152 225.09 167.312 217.885 162.5C210.679 157.689 184.992 152.707 174.764 174.768Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M240.349 202.043C240.349 202.043 236.142 204.856 234.135 213.48" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M248.078 205.734C247.586 207.201 246.24 208.08 245.079 207.685C243.919 207.29 243.387 205.783 243.878 204.316C244.37 202.849 245.716 201.97 246.877 202.365C248.037 202.76 248.577 204.267 248.078 205.734Z" fill="white" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M252.229 208.346C251.738 209.813 250.392 210.691 249.231 210.297C248.07 209.902 247.53 208.394 248.03 206.927C248.53 205.461 249.868 204.582 251.028 204.977C252.189 205.372 252.729 206.879 252.229 208.346Z" fill="white" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M253.116 212.255C252.955 212.525 252.721 212.743 252.44 212.885C252.16 213.028 251.846 213.087 251.533 213.057C251.22 213.028 250.922 212.91 250.674 212.718C250.425 212.526 250.236 212.267 250.129 211.972C250.022 211.676 250.001 211.357 250.068 211.05C250.135 210.743 250.288 210.461 250.508 210.238C250.729 210.014 251.009 209.858 251.315 209.787C251.621 209.716 251.941 209.733 252.237 209.837C252.665 210.051 252.995 210.421 253.158 210.871C253.322 211.321 253.306 211.816 253.116 212.255Z" fill="white" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M247.821 217.623C246.37 220.242 243.469 221.403 241.333 220.218C239.197 219.033 238.649 215.962 240.1 213.343C241.55 210.723 244.452 209.563 246.588 210.748C248.724 211.932 249.272 215.003 247.821 217.623Z" fill="white" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M190.812 238.546C193.514 239.804 196.586 240.019 199.437 239.151" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M181.986 248.775C181.986 248.775 182.792 245.365 187.798 242.963" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M187 251.789C187 251.789 189.007 246.953 192.416 244.769" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M107.907 196.304C107.054 195.624 106.375 194.751 105.924 193.758C105.473 192.765 105.263 191.679 105.312 190.59V171.479H56.0167C54.8125 171.524 53.6129 171.309 52.4999 170.847C51.387 170.385 50.3871 169.688 49.5687 168.803C47.9564 167.098 47.0717 164.831 47.1023 162.484C47.0958 160.577 47.657 158.711 48.7143 157.124L95.9298 82.924C99.4762 77.4486 104.189 74.7109 110.067 74.7109C113.227 74.6035 116.313 75.6849 118.715 77.7415C121.021 79.7565 122.173 82.7521 122.173 86.7284V158.857H134.715C135.581 158.805 136.448 158.944 137.254 159.265C138.061 159.585 138.787 160.079 139.381 160.711C140.442 161.954 141.024 163.534 141.024 165.168C141.024 166.802 140.442 168.383 139.381 169.625C138.787 170.258 138.061 170.752 137.254 171.072C136.448 171.392 135.581 171.531 134.715 171.479H122.085V190.59C122.137 191.671 121.938 192.75 121.503 193.741C121.068 194.733 120.409 195.61 119.578 196.304C117.955 197.664 115.9 198.399 113.783 198.375C111.642 198.392 109.564 197.66 107.907 196.304ZM105.279 158.857V92.4429L63.9397 158.857H105.279Z" fill="#263238"/>
<path d="M176.312 196.691C171.48 194.583 167.202 191.385 163.81 187.349C160.404 183.085 157.745 178.275 155.944 173.124C153.903 167.558 152.468 161.789 151.664 155.915C150.825 149.521 150.421 143.077 150.455 136.628C150.455 94.1409 164.963 72.8975 193.979 72.8975C222.995 72.8975 237.468 94.1409 237.398 136.628C237.427 143.046 237.037 149.459 236.23 155.827C235.452 161.701 234.03 167.472 231.99 173.035C230.189 178.187 227.53 182.997 224.123 187.261C220.728 191.302 216.451 194.512 211.622 196.643C206.07 199.097 200.048 200.308 193.979 200.189C187.907 200.311 181.88 199.118 176.312 196.691ZM202.555 184.496C204.983 183.461 207.186 181.963 209.043 180.087C210.923 178.085 212.469 175.794 213.621 173.301C214.893 170.659 215.92 167.908 216.692 165.08C217.505 161.998 218.097 158.863 218.465 155.698C218.901 152.205 219.191 149.005 219.336 146.098C219.481 143.191 219.551 140.035 219.545 136.628C219.545 133.055 219.476 129.755 219.336 126.73C219.196 123.705 218.893 120.46 218.425 116.994C218.024 113.831 217.418 110.697 216.612 107.612C215.849 104.812 214.82 102.092 213.541 99.4874C212.38 97.0337 210.831 94.783 208.955 92.8218C207.109 90.9837 204.917 89.5291 202.507 88.5419C196.996 86.4143 190.89 86.4143 185.379 88.5419C182.986 89.5172 180.811 90.9583 178.979 92.7815C177.117 94.7341 175.572 96.9671 174.401 99.3987C173.11 101.98 172.094 104.689 171.371 107.483C170.584 110.586 169.992 113.736 169.598 116.913C169.13 120.427 168.827 123.7 168.687 126.73C168.547 129.761 168.475 133.06 168.469 136.628C168.469 140.029 168.542 143.199 168.687 146.139C168.832 149.078 169.119 152.302 169.549 155.811C169.921 158.975 170.514 162.11 171.322 165.193C172.083 168.02 173.1 170.771 174.361 173.414C175.501 175.892 177.034 178.17 178.899 180.16C180.742 182.024 182.933 183.508 185.347 184.528C190.863 186.774 197.039 186.774 202.555 184.528V184.496Z" fill="#263238"/>
<path d="M312.083 196.304C311.23 195.624 310.55 194.752 310.099 193.758C309.648 192.765 309.439 191.679 309.487 190.59V171.479H260.192C258.988 171.525 257.789 171.309 256.676 170.847C255.563 170.385 254.563 169.688 253.744 168.804C252.132 167.098 251.247 164.831 251.278 162.485C251.272 160.577 251.833 158.712 252.89 157.125L300.106 82.9242C303.62 77.4488 308.319 74.7111 314.202 74.7111C317.379 74.5915 320.485 75.6736 322.899 77.7417C325.204 79.7567 326.357 82.7523 326.357 86.7286V158.858H338.898C339.765 158.805 340.632 158.944 341.438 159.265C342.245 159.585 342.971 160.079 343.565 160.711C344.626 161.954 345.208 163.535 345.208 165.168C345.208 166.802 344.626 168.383 343.565 169.626C342.971 170.258 342.245 170.752 341.438 171.072C340.632 171.393 339.765 171.532 338.898 171.479H326.26V190.59C326.313 191.671 326.113 192.75 325.678 193.742C325.244 194.733 324.585 195.61 323.754 196.304C322.131 197.665 320.076 198.399 317.958 198.376C315.818 198.393 313.739 197.66 312.083 196.304ZM309.487 158.858V92.4431L268.148 158.858H309.487Z" fill="#263238"/>
<path d="M158.515 163.339C158.515 163.339 150.092 175.171 148.891 187.204C147.69 199.238 149.093 207.258 152.704 210.272C156.314 213.287 159.917 212.078 161.924 209.265C163.931 206.452 160.723 196.627 160.723 196.627C160.723 196.627 164.133 187.204 169.347 180.587C174.562 173.97 175.562 172.962 175.562 172.962C175.562 172.962 166.535 162.734 158.515 163.339Z" fill="white"/>
<path d="M158.515 163.339C156.677 166.054 155.047 168.905 153.639 171.866C157.669 173.478 163.915 176.783 167.881 182.634C168.364 181.933 168.864 181.24 169.38 180.587C174.595 173.97 175.594 172.962 175.594 172.962C175.594 172.962 166.535 162.734 158.515 163.339Z" fill="#407BFF"/>
<path opacity="0.5" d="M158.515 163.339C156.677 166.054 155.047 168.905 153.639 171.866C157.669 173.478 163.915 176.783 167.881 182.634C168.364 181.933 168.864 181.24 169.38 180.587C174.595 173.97 175.594 172.962 175.594 172.962C175.594 172.962 166.535 162.734 158.515 163.339Z" fill="white"/>
<path d="M158.515 163.339C158.515 163.339 150.092 175.171 148.891 187.204C147.69 199.238 149.093 207.258 152.704 210.272C156.314 213.287 159.917 212.078 161.924 209.265C163.931 206.452 160.723 196.627 160.723 196.627C160.723 196.627 164.133 187.204 169.347 180.587C174.562 173.97 175.562 172.962 175.562 172.962C175.562 172.962 166.535 162.734 158.515 163.339Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M152.704 210.272C151.719 207.643 151.24 204.85 151.293 202.043" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M158.112 211.473C158.112 211.473 156.114 208.063 155.912 202.244" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M161.126 184.794C161.126 182.683 157.096 178.556 151.495 176.734C150.742 178.698 150.12 180.709 149.633 182.755C151.809 183.128 153.923 183.803 155.912 184.762C158.918 186.398 161.126 187.204 161.126 184.794Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M158.024 164.056C156.725 166.037 155.53 168.084 154.445 170.19C156.141 170.638 157.769 171.316 159.281 172.205C165.496 175.808 166.697 176.235 164.698 170.996C163.489 167.707 160.603 165.475 158.024 164.056Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M224.487 157.495C224.487 157.495 234.03 168.441 236.408 180.305C238.785 192.169 238.181 200.286 234.876 203.631C231.572 206.975 227.872 206.137 225.599 203.534C223.326 200.93 225.599 190.847 225.599 190.847C225.599 190.847 221.279 181.796 215.444 175.719C209.608 169.642 208.504 168.747 208.504 168.747C208.504 168.747 216.443 157.68 224.487 157.495Z" fill="white"/>
<path d="M224.486 157.495C216.426 157.68 208.463 168.779 208.463 168.779C208.463 168.779 209.559 169.682 215.403 175.751C216.495 176.902 217.523 178.113 218.481 179.378C221.531 174.226 225.888 169.972 231.111 167.046C229.19 163.673 226.972 160.477 224.486 157.495Z" fill="#407BFF"/>
<path opacity="0.5" d="M224.486 157.495C216.426 157.68 208.463 168.779 208.463 168.779C208.463 168.779 209.559 169.682 215.403 175.751C216.495 176.902 217.523 178.113 218.481 179.378C221.531 174.226 225.888 169.972 231.111 167.046C229.19 163.673 226.972 160.477 224.486 157.495Z" fill="white"/>
<path d="M224.487 157.495C224.487 157.495 234.03 168.441 236.408 180.305C238.785 192.169 238.181 200.286 234.876 203.631C231.572 206.975 227.872 206.137 225.599 203.534C223.326 200.93 225.599 190.847 225.599 190.847C225.599 190.847 221.279 181.796 215.444 175.719C209.608 169.642 208.504 168.747 208.504 168.747C208.504 168.747 216.443 157.68 224.487 157.495Z" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M234.877 203.631C235.599 200.921 235.802 198.098 235.473 195.313" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M229.605 205.355C229.605 205.355 231.274 201.769 230.903 195.958" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M223.994 179.104C223.793 177.001 227.396 172.503 232.796 170.174C233.73 172.052 234.538 173.991 235.214 175.977C233.089 176.57 231.055 177.451 229.169 178.597C226.348 180.491 224.236 181.474 223.994 179.104Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M225.043 158.164C226.525 159.993 227.908 161.901 229.186 163.879C227.536 164.489 225.977 165.322 224.551 166.353C218.724 170.56 217.563 171.076 219.046 165.692C219.973 162.331 222.617 159.824 225.043 158.164Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M157.726 153.102C157.726 153.102 155.244 161.477 156.179 164.886C157.114 168.296 161.45 165.201 165.794 168.916C170.139 172.632 174.161 179.338 190.611 180.579C207.062 181.82 216.992 172.946 220.433 167.981C223.875 163.016 223.536 157.124 229.428 156.818C235.32 156.512 230.363 145.026 228.501 143.164C228.501 143.164 239.979 126.101 239.979 121.757C239.979 117.413 229.122 118.34 215.799 122.378C215.799 122.378 205.869 117.098 187.871 120.202C169.873 123.305 164.602 128.584 164.602 128.584C164.602 128.584 142.259 131.373 140.712 134.161C139.164 136.95 143.766 148.758 157.726 153.102Z" fill="white"/>
<path d="M229.403 156.826C235.303 156.512 230.338 145.035 228.476 143.173C228.476 143.173 230.661 139.949 233.119 135.919C226.453 132.437 219.909 130.503 218.168 132.461C214.42 136.668 216.459 144.728 218.168 152.611C219.876 160.494 213.049 163.855 208.278 170.674C203.506 177.492 196.696 169.988 196.696 169.988C194.278 179.878 184.082 173.398 184.082 173.398C176.247 170.98 162.957 163.178 165.681 157.721C168.405 152.264 169.09 147.154 164.319 142.73C159.547 138.305 140.518 134.621 140.518 134.621C139.518 137.982 144.346 148.992 157.734 153.159C157.734 153.159 155.251 161.533 156.186 164.943C157.121 168.352 161.457 165.257 165.802 168.973C170.146 172.689 174.168 179.394 190.618 180.636C207.069 181.877 216.999 173.003 220.44 168.038C223.882 163.073 223.503 157.133 229.403 156.826Z" fill="#407BFF"/>
<path opacity="0.5" d="M229.403 156.826C235.303 156.512 230.338 145.035 228.476 143.173C228.476 143.173 230.661 139.949 233.119 135.919C226.453 132.437 219.909 130.503 218.168 132.461C214.42 136.668 216.459 144.728 218.168 152.611C219.876 160.494 213.049 163.855 208.278 170.674C203.506 177.492 196.696 169.988 196.696 169.988C194.278 179.878 184.082 173.398 184.082 173.398C176.247 170.98 162.957 163.178 165.681 157.721C168.405 152.264 169.09 147.154 164.319 142.73C159.547 138.305 140.518 134.621 140.518 134.621C139.518 137.982 144.346 148.992 157.734 153.159C157.734 153.159 155.251 161.533 156.186 164.943C157.121 168.352 161.457 165.257 165.802 168.973C170.146 172.689 174.168 179.394 190.618 180.636C207.069 181.877 216.999 173.003 220.44 168.038C223.882 163.073 223.503 157.133 229.403 156.826Z" fill="white"/>
<path d="M205.135 158.696C205.368 160.663 201 162.783 195.398 163.436C189.796 164.088 185.057 163.024 184.823 161.018C184.59 159.011 188.958 156.939 194.568 156.286C200.178 155.633 204.909 156.738 205.135 158.696Z" fill="#407BFF"/>
<path opacity="0.5" d="M205.135 158.696C205.368 160.663 201 162.783 195.398 163.436C189.796 164.088 185.057 163.024 184.823 161.018C184.59 159.011 188.958 156.939 194.568 156.286C200.178 155.633 204.909 156.738 205.135 158.696Z" fill="white"/>
<path d="M157.726 153.102C157.726 153.102 155.244 161.477 156.179 164.886C157.114 168.296 161.45 165.201 165.794 168.916C170.139 172.632 174.161 179.338 190.611 180.579C207.062 181.82 216.992 172.946 220.433 167.981C223.875 163.016 223.536 157.124 229.428 156.818C235.32 156.512 230.363 145.026 228.501 143.164C228.501 143.164 239.979 126.101 239.979 121.757C239.979 117.413 229.122 118.34 215.799 122.378C215.799 122.378 205.869 117.098 187.871 120.202C169.873 123.305 164.602 128.584 164.602 128.584C164.602 128.584 142.259 131.373 140.712 134.161C139.164 136.95 143.766 148.758 157.726 153.102Z" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M221.899 128.278C213.839 133.694 216.684 139.111 220.287 142.117C222.487 143.955 226.033 143.729 228.459 143.213C228.459 143.213 239.937 126.15 239.937 121.806C239.945 121.765 229.918 122.821 221.899 128.278Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M140.51 134.565C139.51 137.926 144.338 148.936 157.726 153.103C157.726 153.103 167.946 152.103 164.537 143.882C161.353 136.209 145.257 134.299 140.51 134.565Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M187.822 120.218C186.766 120.403 185.751 120.596 184.775 120.79C186.387 128.931 189.039 142.487 191.409 142.487C193.778 142.487 195.592 127.625 196.366 119.299C193.501 119.419 190.647 119.726 187.822 120.218Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M178.577 140.069C181.043 138.619 180.672 127.713 180.277 121.797C177.732 122.43 175.226 123.213 172.773 124.143C173.668 129.68 175.941 141.617 178.577 140.069Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M202.444 135.056C204.966 135.943 206.385 126.876 207.183 119.896C204.635 119.48 202.059 119.262 199.478 119.243C199.397 124.973 199.711 134.097 202.444 135.056Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10"/>
<path d="M199.065 166.442C196.711 165.823 194.21 166.132 192.077 167.304C191.983 167.358 191.904 167.433 191.844 167.524C191.785 167.614 191.748 167.717 191.735 167.824C191.723 167.931 191.735 168.04 191.772 168.141C191.809 168.243 191.869 168.334 191.948 168.409L196.228 172.439C196.308 172.514 196.405 172.569 196.511 172.599C196.616 172.629 196.728 172.634 196.836 172.613C196.943 172.591 197.045 172.545 197.131 172.477C197.217 172.409 197.286 172.321 197.332 172.221L199.524 167.385C199.563 167.293 199.582 167.195 199.58 167.095C199.578 166.996 199.554 166.898 199.51 166.808C199.467 166.719 199.404 166.64 199.327 166.577C199.251 166.513 199.161 166.467 199.065 166.442Z" fill="#407BFF" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M188.895 159.712C189.483 161.267 189.249 162.799 188.371 163.129C187.492 163.459 186.299 162.476 185.703 160.929C185.107 159.381 185.348 157.842 186.227 157.511C187.105 157.181 188.298 158.164 188.895 159.712Z" fill="#263238"/>
<path d="M200.565 158.607C200.258 160.219 200.758 161.711 201.685 161.88C202.612 162.049 203.611 160.872 203.918 159.236C204.224 157.6 203.724 156.141 202.797 155.964C201.87 155.786 200.871 156.979 200.565 158.607Z" fill="#263238"/>
<path d="M197.332 172.213C197.332 172.213 191.609 179.983 186.395 172.165" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M196.229 172.431C196.229 172.431 200.033 176.372 204.047 174.164C208.061 171.955 205.659 168.521 205.659 168.521" stroke="#263238" stroke-width="1.1133" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M178.779 153.506C178.779 153.506 180.182 148.492 186.799 149.693" stroke="#263238" stroke-width="3.33991" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M198.832 148.895C198.832 148.895 203.845 145.889 208.456 150.701" stroke="#263238" stroke-width="3.33991" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M174.967 166.95L179.779 168.151" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M179.174 169.956L174.967 170.754" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M179.979 172.165L176.973 173.768" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M210.463 164.741L214.678 163.734" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M211.268 166.748H214.072" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M211.865 168.755L215.678 169.754" stroke="#263238" stroke-width="1.1133" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>
<br></br>
<p style={{marginBottom:'25px', fontSize:"18px"}}>Your page was not found! Retry after some time.</p>
<a href="https://ciphercode.co/"> <button className="btn btn-BlueBg">Go back Home</button></a>
</div>
  )
}
