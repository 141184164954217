import React, { useEffect, useState } from 'react';
import img1 from "../../images/challenge1.png";
import img3 from "../../images/product_digit.png";
import img4 from "../../images/enduser.png";
import {InlineShareButtons} from 'sharethis-reactjs';
import './beyondqr.css';

const BeyondQR = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const threshold = 4000;
    setIsSticky(scrollPosition <= threshold && scrollPosition > 800);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);
	return (
		<div className="container beyond-conent">
        <div className="row">
            <div className="col-md-3 order-md-2">
                <div className={`personal-contentbody ${isSticky ? 'sticky' : ''}`} style={{marginTop:"20px"}}>
                    <p>In This Case Study</p>
                    <hr></hr>
                    <ul>
                        <li><a href="#challenge">Elemage Wellness Challenge</a></li>
                        <li><a href="#objective">Creative Objective</a></li>
                        <li><a href="#solution">Solution</a></li>
                        <li><a href="#position-value">Additional Value</a></li>
                    </ul>
                    <div className="social-icon">
                    <hr></hr>
                    
                    <p>Share This</p> 
                    <InlineShareButtons config={{ alignment: 'left', color: 'social', enabled: true, font_size: 16,language: 'en',  
                    networks: ['linkedin','whatsapp','twitter'],padding: 10,radius: 4,size: 40,}}/>
                    </div>
                </div>
			</div>
			<div className="col-md-9 order-md-2">
                <div className="personal-content2">
                <h2 id="challenge">The Challenge</h2>
                <p>The premium skincare product stands out for its exceptional performance. Being a high-standard offering, the brand opted out of conventional authentication solutions prevalent in the market - those dependent on consumer mobile apps, multiple QR codes, or unique package marking patterns, etc.</p> 
                <p>This decision stems from the belief that these solutions are not relevant with current digital trends and intelligence era. While the search for the ideal solution posed challenges, it reflects a commitment to finding one that can genuinely elevate the product experience to the new heights.</p>
                <div className="image-position" id="objective">
                    <img src={img1} alt="Diagram showcasing the creative capabilities of Brandwise on Personal care products 
                    such as Consumer Engagement, Dynamic landing pages etc"  style={{marginTop: '15px'}}/>
                </div>
                </div>
                <div className="personal-content2">
                <h2>Creative Objective</h2>
                <ul>
                    <li>Ability to enable or disable <b>unit-level QR codes</b> within the factory-controlled environment at unit or batch level. </li>
                    <li>Flexibility to print <b>Bulk yet Unique QR Codes</b> at packaging vendors' premises, ensuring security and efficiency.</li>
                    <li>Facilitate <b>Consumer Engagement</b> without requiring a mobile app, while adhering to data privacy compliance.</li>
                    <li>Ability to change the landing page domain dynamically without altering QR codes.</li>
                    <li>Higher <b>Production Efficiency</b> and zero impact on shop floor productivity</li>
                    <li>Deliver <b>360° consumer behaviour insights</b> in real-time through POS integrations, available at the Exclusive Brand Outlets (EBOs).</li>
                </ul>
                <div className="image-position" id="solution">
                <img src={img3} alt="Diagram showcasing the Product Digitization Journey from 
                generating bulk qr codes to collecting real time data"  style={{marginTop: '14px'}}/>
                </div>
                </div>
                <div className="personal-content2">
                    <h2>Solution</h2>
                    <ul>
                        <li> <b>Advanced Product Authentication:</b> Integrated Cryptoblock technology in QR codes ensures a unique identity, guarding against counterfeit products.</li>
                        <li> <b>Digital Fingerprint of the Physical Product:</b> Establish a secure digital fingerprint using proven Cryptography & Public Key encryption.</li>
                        <li> <b>GS1-Compliant Serialization:</b> Ensuring product serialization with QR codes compliant with GS1 standards, establishing a unique identity for each unit. </li>
                        <li> <b>Efficient Bulk QR Code Printing:</b>  Secure and efficient bulk QR code generation adaptable across diverse packaging materials is streamlined for deployment.</li>
                        <li> <b>Unit Level Activation and Auto Deactivation:</b> Supporting real-time accuracy and relevance through unit-level activation and auto deactivation of QR codes.</li>
                       <li> <b>Customized Landing Pages:</b> Empowering Elemage to create personalized landing pages for SKUs, enhancing consumer experience.</li>
                    </ul>
                    <div className="image-position">
                        <img src={img4} alt="Diagram showcasing end user engagement journey from scanning the qr code to 
                        authenticating and collecting first party data" />
                    </div>
                </div>
                <div className="personal-content2" >
                    <ul>
                        <li><b>Seamless App-less Consumer Interaction:</b> Effortless consumer interaction with QR codes, eliminating the need for a mobile app.</li>
                        <li><b>Flexible Landing Page Domain:</b> Choice between brand and Ciphercode<sup>®</sup> domains to host landing pages, ensuring adaptability.</li>
                        <li><b>Insightful Customer Analytics:</b>  Equipping Elemage with analytics for valuable insights into customer behavior and preferences.</li>
                        <li><b>Real-Time data and AI-Powered Insights:</b> Gain real-time insights into customer interactions with each QR code scan, paving the way to better decision making.</li>
                        <li id="position-value"><b>Dynamic URL with Static QR Codes:</b> Assigning a single static QR code to each SKU for dynamic linking and freedom to adapt marketing content.</li>
                        <li><b>Innovative Activation Module:</b> Streamlining factory-level activation with an innovative module for operational efficiency and product information mapping.</li>
                    </ul>
                </div>
			</div>
		</div>
    </div>
	);
}
export default BeyondQR; 