import React from "react";
import img1 from "../../images/aboutus_top.png";

import "./tophead.css"

function TopHead() {
	return (
      <div className="pt-5">
        <img className="cover-img" src={img1} alt="cover_pic" style={{marginTop:"45px"}}/>
      </div>
  );
}
export default TopHead;