import React from "react";
import img from "../../images/homeApp_top_img.png";
import './topHead.css'

function TopHead() {
	return (
		<div className="container homeapp-top">
			<div className="row">
				<div className="col-12 col-md-6 order-md-2">
					<div className="homeapp-content">
					<h1>Plug supply chain leakages and map distribution on Brandwise<sup>®</sup></h1>
					<p>Brandwise<sup>®</sup> deep trace technology and customer engagement platform allows you to trace the source of every product delivered across the globe, bringing down cross-selling of products across regions and improving sales.</p>
					{/* <button className="btn btn-BlueBg">Get Started</button> */}
					</div>
				</div>
				<div className="col-12 col-md-1 order-md-2"></div>
				<div className="col-12 col-md-5 order-md-2">
					<div className="row">
						<img  src={img} alt="main_image" />
					</div>
				</div>
			</div>
		</div>
	);
}
export default TopHead;
