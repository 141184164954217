import React, {useState, useEffect} from "react";
import './tophead.css'
import './switch.css';
import { AiOutlineDownload} from "react-icons/ai";
import { IoIosLock} from "react-icons/io";
import Tophead from "./tophead";
import QRCode from 'qrcode.react';
import blogo from '../../images/Group 528.png';
import dummyimage from '../../images/QR_Code.png';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import logo from '../../images/WhatsApp_icon.png';
import Watermark_popup from "./watermark_popup";
import Dynamicqr_popup from "./dynamicqr_popup";
import {MdOutlineCampaign} from "react-icons/md";
import { NavLink} from "react-router-dom";
import { CgColorPicker } from "react-icons/cg";
import { FaLink, FaMapMarkerAlt, FaFilePdf, FaWhatsapp , FaUsers} from "react-icons/fa";

const WhatsAppQR = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [url, setNumber] = useState('');
  const[color,setColor]=useState(null);
  const[text,setText]=useState(null);
  const[error,seterror]=useState(false);
  const [qrCodeText, setQRCodeText] = useState('');
  const[qr_type,setqr_type]=useState("color"); //logo,color
  const whaturl = `https://wa.me/${url}?text=${text}`
  const togglePopup1 = () => {
    setIsOpen1(!isOpen1);
  }
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  }
  const qr_code=async()=>
  {
    if (url.trim("").length === 0 && color!==null){
     seterror(true)
   }
  
   else if(url.trim("").length>0){
     setQRCodeText(whaturl);
   } 
   else{
    seterror(false)
   }
 }
const handleKeyDown = (event) => {
 if (event.key === 'Enter') {
   qr_code() 
 }
}
  // download QR code
  const downloadQRCode = async () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
    const canvas = await html2canvas(qrCodeURL);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'qr_code.png', 'image/png');
  };

// handle qr_color
useEffect(()=>{
  if(color){
    qr_code()
  }
},[color])

    return (
        <>
        <Tophead />
        <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center', paddingBottom:'20px'}}>
          <div className="container brandwiseqrbox">
          <div className="nav">
           <input type="checkbox" id="nav-check" />
           <div className="nav-btn">
             <label htmlFor="nav-check">QR Menu</label>
           </div>
            <div className="nav-links">
               <NavLink exact="true" activeclassname ="active" to="/qrcode/url"><FaLink/> URL</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/shorturl"><FaPaperclip/>  Short URL</NavLink> */}
               <NavLink exact="true" activeclassname ="active"to="/qrcode/whatsapp"><FaWhatsapp /> WhatsApp</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/social_meida"><FaUsers /> Social Media</NavLink>
               <NavLink exact="true" activeclassname ="active" to="/qrcode/File_qr"><FaFilePdf /> PDF/Media</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/location_qr"><FaMapMarkerAlt /> Location</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/webform_qr"><FaWpforms /> Web Forms</NavLink> */}
               <NavLink exact="true" activeclassname ="active" to="/qrcode/campaign"><MdOutlineCampaign /> Campaign</NavLink>
            </div>
            </div>
        <div className="row" onClick={qr_code}>
        <div className="col">
        <div className="qr_data">
         <label htmlFor="exampleInputfile">Enter WhatsApp number </label><br />
         <input type="text" className="form-control" d="exampleInputurl" placeholder='Enter WhatsApp number here' autoComplete="off" onKeyDown={handleKeyDown} onChange = {(e) => {setNumber(e.target.value)}} /><br />
         {error&&<p style={{ color: "#F61C04", marginTop:'-20px', fontSize:'20px', marginLeft:'5px' }}>field is required</p>}
         <label htmlFor="exampleInputfile" onClick={togglePopup1} style = {{cursor:"pointer"}}>Enter Chat Pre-text <IoIosLock /> </label><br />
         <input type="text" className="form-control" d="exampleInputurl" placeholder='Enter pre-text here' autoComplete="off" onChange = {(e) => {setText(e.target.value)}} disabled/>
         <h5>QR Code Customization</h5><hr />
         <div className="custo_item">
           <ul>
            <li><a onClick={()=>{setqr_type("color")}} className={qr_type==="color"?"active":""}><CgColorPicker /> Pick QR Color</a></li>
            </ul>
         </div><br />
            {qr_type==="color" && <input type="color"  className="form-control-file" id="exampleiInputcolor"    onChange = {(e) => {setColor(e.target.value)}}></input>}
         
           </div>
      </div>
       <div className="col">
      <div className='qr_code'>
      <div className="qrcodeimg" id="qrCodeEl" >
        {qrCodeText.trim("").length>0&&<QRCode value={qrCodeText} renderAs="canvas" Level={"H"} fgColor={color?color:"#000"} size ={290} includeMargin
        imageSettings={{src:logo, excavate:true, height: 35, width: 35}}
        />}
        {qrCodeText.trim("").length===0&&<img src={dummyimage} alt="qr_code_dummy"/>}
        <img className="watermark" src={blogo} alt="watermark_img" />
      </div>
      <p  style = {{cursor:"pointer"}} onClick={togglePopup1}>Signup to remove watermark <IoIosLock /> </p>
     <button type="button" className="download-btn" onClick={downloadQRCode} value="Download"><AiOutlineDownload /> Download (.PNG)</button>
     </div>
    </div>
  {isOpen1 && <Watermark_popup handleClose={togglePopup1}/>}
  {isOpen2 && <Dynamicqr_popup handleClose={togglePopup2}/>}
  </div>
  </div>
  </div>
</>
    );
};
export default WhatsAppQR;