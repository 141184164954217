import React, { useEffect, useState } from "react";
import QRCode from 'qrcode.react';
import './tophead.css'
import './switch.css';
import { AiOutlineUpload, AiOutlineDownload } from "react-icons/ai";
import { BsUnion } from "react-icons/bs";
import { CgColorPicker } from "react-icons/cg";
import { IoIosLock } from "react-icons/io";
import Tophead from "./tophead";
import blogo from '../../images/Group 528.png';
import dummyimage from '../../images/QR_Code.png';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import Popup from './popup';
import Watermark_popup from "./watermark_popup";
import Dynamicqr_popup from "./dynamicqr_popup";
import { MdOutlineCampaign } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { FaLink, FaMapMarkerAlt, FaFilePdf, FaWhatsapp, FaUsers } from "react-icons/fa";

const Url_qr = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [url, seturl] = useState("")
  const [color, setColor] = useState(null);
  const [logo, setLogo] = useState(null);
  const [qrCodeText, setQRCodeText] = useState('');
  const [error, seterror] = useState(false);
  const [isInputValid, setisInputValid] = useState(true)
  const [qr_type, setqr_type] = useState("logo"); //logo,color

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopup1 = () => {
    setIsOpen1(!isOpen1);
  }
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  }

  const regextValidation = URL => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(URL);
  };
  const updateUrlVal = event => {
    const { value } = event.target;
    const isInputValid = !value || regextValidation(value)
    setisInputValid(isInputValid)
    seturl(value)
  };
  const qr_code = async () => {
    if (url.trim("").length === 0 && color !== null) {
      seterror(true)
    }
    else if (url.trim("").length === 0 && logo !== null) {
      seterror(true)
    }
    else if (url.trim("").length > 0 && isInputValid === true) {
      setQRCodeText(url);
    }
    else {
      seterror(false)
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      qr_code()
    }
  }
  // download QR code
  const downloadQRCode = async () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
    const canvas = await html2canvas(qrCodeURL);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'qr_code.png', 'image/png');
  };

  // handle logo and qr_color
  useEffect(() => {
    if (color) {
      qr_code()
    }
  }, [color])
  useEffect(() => {
    if (logo) {
      qr_code()
    }
  }, [logo])
  useEffect(() => {
    window.scroll({ top: 400 })
  }, []);
  return (
    <>
      <Tophead />
      <div style={{ background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center', paddingBottom: '20px' }}>
        <div className=" container brandwiseqrbox">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-btn">
              <label htmlFor="nav-check">QR Menu</label>
            </div>
            <div className="nav-links">
              <NavLink exact="true" activeclassname="active" to="/qrcode/url"><FaLink /> URL</NavLink>
              {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/shorturl"><FaPaperclip/>  Short URL</NavLink> */}
              <NavLink exact="true" activeclassname="active" to="/qrcode/whatsapp"><FaWhatsapp /> WhatsApp</NavLink>
              <NavLink exact="true" activeclassname="active" to="/qrcode/social_meida"><FaUsers /> Social Media</NavLink>
              <NavLink exact="true" activeclassname="active" to="/qrcode/File_qr"><FaFilePdf /> PDF/Media</NavLink>
              <NavLink exact="true" activeclassname="active" to="/qrcode/location_qr"><FaMapMarkerAlt /> Location</NavLink>
              {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/webform_qr"><FaWpforms /> Web Forms</NavLink> */}
              <NavLink exact="true" activeclassname="active" to="/qrcode/campaign"><MdOutlineCampaign /> Campaign</NavLink>
            </div>
          </div>
          <div className="row" onClick={qr_code}>
            <div className="col">
              <div className="qr_data">
                <label htmlFor="exampleInputfile">Website URL/Link</label><br />
                <input type="url" className="form-control" d="exampleInputurl" placeholder='Enter the url here' autoComplete="off" onKeyDown={handleKeyDown} onChange={updateUrlVal} />
                {error && <p style={{ color: "#F61C04", marginTop: '-5px', fontSize: '20px', marginLeft: '5px' }}>field is required</p>}
                {!isInputValid && (
                  <div style={{ color: "#F61C04" }}>URL is not valid.</div>
                )}
                <input type="checkbox" id={`react-switch-new`} className="react-switch-checkbox" onClick={togglePopup2} />
                <label className="react-switch-label" htmlFor={`react-switch-new`}>
                  <span className={`react-switch-button`} /></label><h6>Toggle for Dynamic QR <IoIosLock />  </h6>
                <h5>QR Code Customization</h5><hr />
                <div className="custo_item">
                  <ul>
                    <li><a href="#" onClick={() => { setqr_type("logo") }} className={qr_type === "logo" ? "active" : ""}><BsUnion /> Pick QR Logo </a></li>
                    <li><a href="#" onClick={() => { setqr_type("color") }} className={qr_type === "color" ? "active" : ""}><CgColorPicker /> Pick QR Color</a></li>
                  </ul>
                </div><br />
                {qr_type === "color" && <input type="color" className="form-control-file" id="exampleiInputcolor" onChange={(e) => { setColor(e.target.value) }}></input>}
                {qr_type === "logo" && <div className="qr_logo">
                  <label htmlFor="exampleFormControlFile1"> <AiOutlineUpload /> Upload Logo</label>
                  <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={(e) => { setLogo(URL.createObjectURL(e.target.files[0])) }} />
                </div>}<br /><br />
              </div>
            </div>
            <div className="col">
              <div className='qr_code'>
                <div className="qrcodeimg" id="qrCodeEl">
                  {qrCodeText.trim("").length > 0 && <QRCode value={qrCodeText} renderAs="canvas" Level={"H"} fgColor={color ? color : "#000"} includeMargin size={290}
                    imageSettings={{ src: logo, excavate: true, height: 35, width: 35 }} />}
                  {qrCodeText.trim("").length === 0 && <img src={dummyimage} alt="dummy_qr" />}
                  <img className="watermark" src={blogo} alt="watermark_img" />
                </div>
                <p style={{ cursor: "pointer" }} onClick={togglePopup1}>Signup to remove watermark <IoIosLock /> </p>
                <button type="button" className="download-btn" onClick={downloadQRCode} value="Download"><AiOutlineDownload /> Download (.PNG)</button>
              </div>
            </div>
            {isOpen && <Popup handleClose={togglePopup} />}
            {isOpen1 && <Watermark_popup handleClose={togglePopup1} />}
            {isOpen2 && <Dynamicqr_popup handleClose={togglePopup2} />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Url_qr;