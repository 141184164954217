import React from "react";
import {Link} from "react-router-dom";
import whitelogo from "../../images/whitelogo.png";
import "./footerNewInfra.css";


function FooterNewInfra() {
  return (
    <div className="container py-4 footer-new-infra">
      <div className="row">
        <div className="col-md-3" id="footer-add-logo">
          <img className="logo"  src={ whitelogo} alt="footer_logo" />
          <h5 style={{marginTop:'5px', marginBottom:'-10px'}}>Ciphercode Tech Solutions Pvt. Ltd.</h5>
          <h4 style={{width:'90%', marginBottom:'-10px'}}><span style={{fontWeight:'600'}}>HQ</span> : No. 6, IndiQube Delta, Opp Agara Lake 14<sup>th</sup> Main Road Sector 5, HSR Layout, Bangalore 560034 India.</h4>
          <h4 style={{width:'90%'}}><span style={{fontWeight:'600'}}>EU</span> : Boulevard De La Sauveniere 121, 4000 Liege, Belgium. Ph: +32 495 29 76 45.</h4>
        </div>
        <div className="col-md-5">
          <table className="table table-no-border footer-table">
            <tbody>
              <tr>
                <td><Link  to='/aboutus'>  About us </Link></td>
                <td><Link to="/qrcode/url">Cryptoblock QR</Link></td>
                <td><Link to="/career"> Careers</Link></td>
              </tr>
              <tr>
                <td><Link to="product/qSeal"> Qseal<sup>®</sup></Link></td>
                <td><Link to="/resources"> Resources</Link></td>
                <td><Link to="/sitemap"> Sitemap</Link></td>
                
              </tr>
              <tr>
                <td><Link to="product/qReach"> QReach<sup>®</sup></Link></td>
                <td><Link to="/partnershipprogram">Partnership</Link></td>
                <td><Link to="/contactus"> Contact us</Link></td>
               
              </tr>
              <tr>
              <td><Link to="product/qReport">QReport<sup>®</sup></Link></td>
                <td><Link to="/schedule_demo">Get demo</Link></td>
                <td><Link to="/privacy">Privacy Policy</Link></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-4">
          <table className="table table-no-border footer-table">
            <tbody>
              <tr>
                <td><h5>Contact Us</h5></td>
                <td><h5>Find Us Online</h5></td>
              </tr>
              <tr>
                <td>Phone <div style={{color:'blue'}}><a href="tel://+919741111594" rel="noreferrer">+91-9741111594</a></div></td>
                <td>
                  <div className="social-icons" style={{width: "150px"}}>
                    <a href="https://www.facebook.com/share/17xJiDoi8e/?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
                      <img src="https://ciphercode-files.s3.amazonaws.com/demoa/MLQ/Facebook.png" alt="facebook" width="25px" height="25px" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCKxrxgOlTckNPzmngiAHq_Q/featured" target="_blank" rel="noreferrer">
                      <img src="https://ciphercode-files.s3.amazonaws.com/demoa/MLQ/Youtube_Logo.png" alt="youtube" width="25px" height="25px" style={{marginLeft: "10px",marginRight:"10px"}} />
                    </a>
                    <a href="https://x.com/Ciphercode_co" target="_blank" rel="noreferrer">
                      <img src="https://ciphercode-files.s3.amazonaws.com/demoa/MLQ/logo-black.png" alt="twitter" width="25px" height="25px" style={{marginRight:"10px"}} />
                    </a>
                    <a href="https://www.linkedin.com/company/ciphercode/" target="_blank" rel="noreferrer">
                      <img src="https://ciphercode-files.s3.amazonaws.com/demoa/MLQ/LinkedInLogo.png" alt="linkedin" width="25px" height="25px" />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Email <div style={{color:'blue', textDecoration:'underline'}}><a href="mailto:sales@ciphercode.co" target="_blank" rel="noreferrer">sales@ciphercode.co</a></div></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="copyright">
        <small>Copyright &copy; 2024. All rights reserved.</small>
      </div>
    </div>
  );
}

export default FooterNewInfra;
