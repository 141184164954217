import React from 'react'
import './transformation.css';


export default function transformation() {
    return (
        <div className="potential">
            <div className="container brand-transformation">
                <div className="row">
                <h2>Why <span> Brandwise</span> is the Ultimate Tool for <span> Brand Transformation</span> ?</h2>
                <div className="col-md-5">
                <h3 style={{fontWeight:"100"}}>Other Software</h3>
                <div className='card list-card' style={{border:"1px solid black", height: '80%',boxShadow:"5px 10px"}}>
                <ul className="list-unstyled negative">
                    <li>Traditional Authentication Methods</li>
                    <li>Fragmented Solutions</li>
                    <li>Standard Campaign Models</li>
                    <li>Reliance on 3<sup>rd</sup> Party Data</li>
                    <li>Non-Compliant Solution</li>
                    <li>Isolated ROI Management</li>
                </ul>
            </div>
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-center">
                <div class="vs">VS</div>
            </div>
            <div className="col-md-5 " >
            <h3>Brandwise</h3>
            <div className='card list-card' style={{border:"1px solid black", height: '80%', boxShadow:"5px 10px"}}>
                <ul className="list-unstyled positive">
                    <li>AI & Cryptography Authentication</li>
                    <li>Unified Enterprise Platform</li>
                    <li>16+ Customizable Campaign Models</li>
                    <li>1<sup>st</sup> Party Data Collection & Utilization</li>
                    <li>GS1 Compliant Solution</li>
                    <li>Integrated ROI Management</li>
                </ul>
            </div>
            </div>
            </div><br/><br/><br/>
            </div>
        </div>
    )
}
