import React from "react";
import img from "../../images/coverphoto.png";
import "./tophead.css";


function TopHead() {
	return (
		<div className="pt-5" style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container blogpost">
				<div className="px-magic text-center text-md-start">
					<h1>The Shift from Information to Intelligence:<hr></hr></h1> 
					<h2>How Luxury Brands Can Thrive in the New Era of Consumer Expectations</h2>
					<p>18 October 2024 | By Venkat Terugu</p>
				</div>
			</div>
			<img className="cover-img" src={img} alt="cover_pic" />
		</div>
	);
}
export default TopHead;