import React from 'react'
import { Link } from 'react-router-dom';


export default function footerhome() {
  return (
    <div>
      <div className="footerhome">
        <div className='container'>
          <h1>What’s Stopping You?</h1>
          <h2><span className='myBlue'>Transform </span >your Brand with <span className='myBlue'> Brandwise</span></h2>
          <Link to="/schedule_demo"><button className="btn btn-BlueBg btn-explore" >Book a Call</button></Link>
        </div>
      </div>
    </div>
  )
}
