import React, {useEffect}  from "react";
import TopHead from "../components/Retail/tophead";
import Challenges from "../components/Retail/challenges";
import ROI from "../components/Retail/roi";
import Brand from "../components/Retail/brand";
import Feedback from "../components/Home/feedback";
import { Helmet } from "react-helmet";


export default function Retail() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <div>
        <Helmet>
          <title>Ciphercode</title>
          <meta name="description" content="Boot conversions for your Retail Brand by delivering personalized customer experiences and driving omni-channel sales with Brandwise." />
          <meta name="keywords" content="Retail, Customer Conversion, Omni-channel sales, personalization" />
          {/* Open Graph tags for social media sharing */}
          <meta property="og:title" content="Boost Conversions in the Retail Industry with Brandwise" />
          <meta property="og:description" content="Boot conversions for your Retail Brand by delivering personalized customer experiences and driving omni-channel sales with Brandwise." />
          <meta property="og:url" content="https://ciphercode.co/retail" />
          {/* Twitter Card for better Twitter sharing */}
          <meta name="twitter:title" content="Boost Conversions in the Retail Industry with Brandwise" />
          <meta name="twitter:description" content="Boot conversions for your Retail Brand by delivering personalized customer experiences and driving omni-channel sales with Brandwise." />
        </Helmet>
        <TopHead />
        <Challenges/>
        <ROI/>
        <Brand/>
        <Feedback/>
      </div>
    )
}
   