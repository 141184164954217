import React, {useEffect} from "react";
import "./terms&condition.css";



function BrandSom() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
      return (
		<>
		<div className="terms-content">
            <div className="container">
            <h1>Brandsom Privacy Notice</h1>
			<p><h3>Disclaimer</h3></p>
			<p>In the event of any discrepancy or conflict, the English version will prevail over the translation.</p>
            <p>Last updated: May 24th, 2023. To see prior version, click here</p> 
			<p>We know that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. BrandSOM is sole proprietary product of Ciphercode Tech solutions Private Limited. This Privacy Notice describes how BrandSOM and its affiliates including BrandSOM (collectively &quot;BrandSOM&quot;) collect and process your personal information through BrandSOM websites, devices, products, services, online marketplace, and applications that reference this Privacy Notice (together &quot;BrandSOM Services&quot;).</p>
			<p>By using BrandSOM Services you agree to our use of your personal information (including sensitive personal information) in accordance with this Privacy Notice, as may be amended from time to time by us at our discretion. You also agree and consent to us collecting, storing, processing, transferring, and sharing your persona ld information (including sensitive personal information) with third parties or service providers for the purposes set out in this Privacy Notice.</p>
			<p>Personal information subject to this Privacy Notice will be collected and retained by BrandSOM, with a registered office at #199/1, 22nd&nbsp;Main, HSR Layout, Bangalore, INDIA, 560102. &nbsp;</p>
			<br />
            <p><strong>What Personal Information About Customers Does BrandSOM Collect?</strong></p>
            <p><strong>For What Purposes Does BrandSOM Use Your Personal Information?</strong></p>
            <p><strong>What About Cookies and Other Identifiers?</strong></p>
            <p><strong>Does BrandSOM Share Your Personal Information?</strong></p>
            <p><strong>How Secure is Information About Me?</strong></p>
            <p><strong>What About Advertising?</strong></p>
            <p><strong>What Information can I Access?</strong></p>
            <p><strong>What Choices Do I Have?</strong></p>
            <p><strong>Are Children Allowed to Use BrandSOM Services?</strong></p>
            <p><strong>Conditions of Use, Notices, and Revisions</strong></p>
            <p><strong>Related Practices and Information</strong></p>
            <p><strong>Examples of Information Collected</strong></p>
            <br />
            <p><h3>What Personal Information About Customers Does BrandSOM Collect?</h3></p>
			<p>We collect your personal information in order to provide and continually improve our products and services.</p>
            <p><strong>Here are the types of personal information we collect:</strong></p>
			<p><strong>Information You Give Us:</strong> We receive and store any information you provide in relation to BrandSOM Services. Check &ldquo;examples of information&rdquo; to see what we collect. You can choose not to provide certain information, but then you might not be able to take advantage of many of our BrandSOM Services.</p>
			<p><strong>Automatic Information: </strong>We automatically collect and store certain types of information about your use of BrandSOM Services, including information about your interaction with content and services available through BrandSOM Services. Like many websites, we use cookies and other unique identifiers, and we obtain certain types of information when your web browser or device accesses BrandSOM Services and other content served by or on behalf of BrandSOM on other websites.</p>
			<p><strong>Information from Other Sources: </strong>We might receive information about you from other sources, such as updated delivery and address information from our carriers, which we use to correct our records and deliver your next purchase more easily.&nbsp;</p>
			<p><h3>For What Purposes Does BrandSOM Use Your Personal Information?</h3></p>
            <p>We use your personal information to operate, provide, develop, and improve the products and services that we offer our customers. These purposes include:</p>
			<ul>
            <li><p>Purchase and delivery of products and services. We use your personal information to take and fulfill orders, deliver products and services, process payments, and communicate with you about orders, products and services, and promotional offers.</p></li>
            <li><p>Provide, troubleshoot, and improve BrandSOM Services. We use your personal information to provide functionality, analyze performance, fix errors, and improve the usability and effectiveness of the BrandSOM Services.</p></li>
            <li><p>Recommendations and personalization. We use your personal information to recommend features, products, and services that might be of interest to you, identify your preferences, and personalize your experience with BrandSOM Services.</p></li>
            <li><p>Provide voice, image and camera services. When you use our voice, image and camera services, we use your voice input, images, videos, and other personal information to respond to your requests, provide the requested service to you, and improve our services. For more information about Alexa voice services, click here.</p></li>
            <li><p>Comply with legal obligations. In certain cases, we collect and use your personal information to comply with laws. For instance, we collect from sellers information regarding place of establishment and bank account information for identity verification and other purposes.</p></li>
            <li><p>Communicate with you. We use your personal information to communicate with you in relation to BrandSOM Services via different channels (e.g., by phone, e-mail, chat).</p></li>
            <li><p>Advertising. We use your personal information to display interest-based ads for features, products, and services that might be of interest to you. We do not use information that personally identifies you to display interest-based ads. To learn more, please read our Interest-Based Ads notice.</p></li>
            <li><p>Fraud Prevention and Credit Risks. We use personal information to prevent and detect fraud and abuse in order to protect the security of our customers, BrandSOM, and others. We may also use scoring methods to assess and manage credit risks.</p></li>
			</ul>
			<br />
		    <p><h3>What About Cookies and Other Identifiers?</h3></p>
            <ul>
                <li><p>To enable our systems to recognize your browser or device and to provide and improve BrandSOM Services, we use cookies and other identifiers. For more information about cookies and how we use them, please read our Cookies Notice.</p></li>
            </ul>
			<br />
			<p><h3>Does BrandSOM Share Your Personal Information?</h3></p>
            <p>Information about our customers is an important part of our business and we are not in the business of selling our customers&rsquo; personal information to others. We share customers&rsquo; personal information only as described below and with BrandSOM. and subsidiaries under Ciphercode Tech Solutions Private Limited. controls that either are subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.</p>
            <p><strong>Transactions involving Third Parties:</strong> We make available to you services, products, applications, or skills provided by third parties for use on or through BrandSOM Services. For example, the products you order through our marketplace are from third parties, you can download applications from third-party application providers from our App Store, and enable third-party skills through our Alexa services. We also offer services or sell product lines jointly with third-party businesses, such as sellers on the marketplace, restaurants registered on BrandSOM.in, merchants providing mobile recharges and bill-payment assistance. You can tell when a third party is involved in your transactions, and we share customers&rsquo; personal information related to those transactions with that third party.</p>
			<p><strong>Third-Party Service Providers:</strong>We employ other companies and individuals to perform functions on our behalf. Examples include fulfilling orders for products or services, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing payments, transmitting content, scoring, assessing and managing credit risk, and providing customer service. These third-party service providers have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information in accordance with applicable law.</p>
            <p><strong>Business Transfers: </strong>As we continue to develop our business, we might sell or buy other businesses or services. In such transactions, customer information generally is one of the transferred business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, the customer consents otherwise). Also, in the unlikely event that BrandSOM. or BrandSOM Seller Services Private Limited or any of its affiliates, or substantially all of their assets are acquired, customer information will of course be one of the transferred assets.</p>
            <p><strong>Protection of BrandSOM and Others:</strong> We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other agreements; or protect the rights, property, or safety of BrandSOM, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</p>
            <p>Other than as set out above, you will receive notice when personal information about you might be shared with third parties, and you will have an opportunity to choose not to share the information.</p>
			<br />
			<p><h3>How Secure Is Information About Me?</h3></p>
            <p>We design our systems with your security and privacy as the priority.</p>
            <ul>
              <li><p>We work to protect the security of your personal information during transmission by using encryption protocols and software.</p></li>
              <li><p>We follow the Payment Card Industry Data Security Standard (PCI DSS) when handling payment card data.</p></li>
              <li><p>We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, processing, and disclosure of personal customer information. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you.</p></li>
              <li><p>Our devices offer security features to protect them against unauthorized access and loss of data. You can control these features and configure them based on your needs.&nbsp;</p></li>
              <li><p>It is important for you to protect against unauthorized access to your password and to your computers, devices and applications. Be sure to sign off when finished using a shared computer.&nbsp;</p></li>
            </ul>
			<br></br>
			<p><h3>What About Advertising?</h3></p>
            <p>Third-Party Advertisers and Links to Other Websites: BrandSOM Services may include third-party advertising and links to other websites and apps. Third-party advertising partners may collect information about you when you interact with their content, advertising, and services.&nbsp;</p>
            <p>Use of Third-Party Advertising Services: We provide ad companies with information that allows them to serve you with more useful and relevant BrandSOM ads and to measure their effectiveness. We never share your name or other information that directly identifies you when we do this. Instead, we use an advertising identifier like a cookie, a device identifier, or a code derived from applying irreversible cryptography to other information like an email address. For example, if you have already downloaded one of our apps, we will share your advertising identifier and data about that event so that you will not be served an ad to download the app again. Some ad companies also use this information to serve you relevant ads from other advertisers. You can learn more about how to opt-out of interest-based advertising by going to the Advertising Preferences page.</p>
            <p><br/></p>
            <p><h3>What Information Can I Access?</h3></p>
            <p>You can access your information, including your name, address, payment options, profile information, Prime membership, household settings, and purchase history in the &quot;Your Account&quot; section of the website or mobile application. To request access to personal information that is not available through Your Account you can submit a request.</p>
            <p><br /></p>
			<p><h3>What Choices Do I Have?</h3></p>
            <p>If you have any questions as to how we collect and use your personal information, please contact our Grievance Officer. Many of our BrandSOM Services also include settings that provide you with options as to how your information is being used</p>
            <ul>
			<li><p>As described above, you can always choose not to provide certain information, but then you might not be able to take advantage of many of the BrandSOM Services.</p> </li>
            <li><p>You can add or update certain information on pages such as those referenced in What Information Can I Access?. When you update information, we usually keep a copy of the prior version for our records</p></li>
            <li><p>If you do not want to receive e-mail or other communications from us, please adjust your Customer Communication Preferences. If you don&rsquo;t want to receive in-app notifications from us, please adjust your notification settings in the app or device.</p></li>
            <li><p>The Help feature on most browsers and devices will tell you how to prevent your browser or device from accepting new cookies or other identifiers, how to have the browser notify you when you receive a new cookie or how to block cookies altogether. Because cookies and identifiers allow you to take advantage of some essential features of BrandSOM Services, we recommend that you leave them turned on. For instance, if you block or otherwise reject our cookies, you will not be able to add items to your Shopping Cart, proceed to Checkout, or use any Services that require you to Sign in.&nbsp;</p></li>
            <li><p>If you want to browse our websites without linking the browsing history to your account, you may do so by logging out of your account and blocking cookies on your browser.</p></li>
            <li><p>You will also be able to opt out of certain other types of data usage by updating your settings on the applicable BrandSOM website (e.g., in &quot;Manage Your Content and Devices&quot;), device, or application. If you have questions about how to change your device permissions on devices manufactured by third parties, we recommend you contact your mobile service carrier or your device manufacturer.</p></li>
            <li><p>If you are a seller, you can add or update certain information in supplier profile , update your account information by accessing your Seller Account Information, and adjust your e-mail or other communications you receive from us by updating your Notification Preferences.</p> </li>
            </ul>
            <p><br /></p>
			
            <p><h3>Are Children Allowed to Use BrandSOM Services?</h3></p>
            <p>BrandSOM does not sell products for purchase by children. We sell children&apos;s products for purchase by adults. If you are under the age of 18 years, you may use BrandSOM Services only with the involvement of a parent or guardian.</p>
            <p><br /></p>
			<p><h3>Conditions of Use, Notices , and Revisions</h3></p>
			<p>If you choose to use BrandSOM Services, your use and any dispute over privacy is subject to this Notice and our Conditions of Use, including limitations on damages, resolution of disputes, and application of the prevailing law in India. If you have any concern about privacy at BrandSOM, please contact us with a thorough description, and we will try to resolve it. Our business changes constantly, and our Privacy Notice will change also. You should check our websites frequently to see recent changes.</p>
            <p>Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.</p>
		    <br />
			<p><h3>Related Practices and Information</h3></p>
            <ul>
             <li><p>Conditions of Use</p></li>
             <li><p>Seller Program Policies</p></li>
             <li><p>Help Department</p></li>
             <li><p>Most Recent Purchases</p></li>
             <li><p>Your Profile and Community Guidelines</p></li>
            </ul> 
			<br />
			<p><h3>Examples of Information Collected</h3></p>
            <p>Information You Give Us When You Use BrandSOM Services</p>
            <p>You provide information to us when you:</p>
            <ul>
              <li><p>search or shop for products or services in our marketplace.</p></li>
              <li><p>add or remove an item or place an order through or use BrandSOM Services.</p></li>
              <li><p>download, stream, view, or use content on a device or through a service or application on a device;</p></li>
              <li><p>provide information in Your Account (and you might have more than one if you have used more than one e-mail address or mobile number when shopping with us) or Your Profile;</p></li>
              <li><p>upload your contacts, including access to mobile device contacts for certain services;</p></li>
              <li><p>configure your settings on, provide data access permissions for, or interact with an BrandSOM device or service.</p></li>
              <li><p>provide information in your supplier Account, Service Provider Account, or any other account we make available that allows you to develop or offer software, goods, or services to BrandSOM customers.</p></li>
              <li><p>offer your products or services on or through BrandSOM Services.</p></li>
              <li><p>communicate with us by phone, e-mail, or otherwise.</p></li>
              <li><p>complete a questionnaire, a support ticket, or a contest entry form.</p></li>
              <li><p>upload or stream images, videos or audio or other files while using BrandSOM Services.</p></li>
              <li><p>use our services such as Prime Video.</p></li>
              <li><p>compile Playlists, Watchlists, Wish Lists or other gift registries.</p></li>
			  <li><p>participate in Discussion Boards or other community features.</p></li>
              <li><p>provide and rate Reviews.</p></li>
			  <li><p>specify a Special Occasion Reminder; or</p></li>
              <li><p>employee Product Availability Alerts, such as Available to Order Notifications.</p></li>
            </ul>
			<br />
			<p><strong>As a result of those actions, you might supply us with such information as:</strong></p>
             <ul>
				<li><p>identifying information such as your name, address and phone numbers;</p></li>
                <li><p>payment information;</p></li>
                <li><p>your age;</p></li>
                <li><p>your location information;</p></li>
                <li><p>your IP address;</p></li>
                <li><p>people, addresses and phone numbers listed in your Addresses;</p></li>
                <li><p>e-mail addresses of your friends and other people;</p></li>
                <li><p>content of reviews and e-mails to us;</p></li>
				<li><p>the personal description and photograph in Your Profile;</p></li>
                <li><p>voice recordings when you speak to Alexa;</p></li>
                <li><p>images, videos and other content collected or stored in connection with BrandSOM Services;</p></li>
                <li><p>information and officially valid documents regarding identity and address information, including PAN numbers;</p></li>
                <li><p>credit history information;</p></li>
                <li><p>corporate and financial information; and</p></li>
                <li><p>device log files and configurations, including Wi-Fi credentials, if you choose to automatically synchronize them with your other BrandSOM devices.</p> </li>
            </ul>
			<p><strong>Automatic Information</strong></p>
            <p>Examples of the information we collect and analyze include:</p>
            <ul>
              <li><p>the internet protocol (IP) address used to connect your computer to the internet;</p></li>
              <li><p>login, e-mail address, and password (encrypted);</p></li>
              <li><p>the location of your device or computer;</p></li>
			  <li><p>content interaction information, such as content downloads, streams, and playback details, including duration and number of simultaneous streams and downloads, and network details for streaming and download quality, including information about your internet service provider;</p></li>
              <li><p>device metrics such as when a device is in use, application usage, connectivity data, and any errors or event failures;</p></li>
              <li><p>BrandSOM Services metrics (e.g., the occurrences of technical errors, your interactions with service features and content, your settings preferences and backup information, location of your device running an application, information about uploaded images and files such as the file name, dates, times and location of your images);</p></li>
              <li><p>version and time zone settings;</p></li>
              <li><p>purchase and content use history, which we sometimes aggregate with similar information from other customers to create features like BrandSOM Bestsellers;</p></li>
              <li><p>the full Uniform Resource Locator (URL) clickstream to, through, and from our websites, including date and time; products and content you viewed or searched for; page response times, download errors, length of visits to certain pages, and page interaction information (such as scrolling, clicks, and mouse-overs);</p></li>
              <li><p>phone numbers used to call our customer service number; and</p></li>
              <li><p>images or videos when you shop in our marketplace using BrandSOM Services.</p></li>
            </ul>
            <p>We may also use device identifiers, cookies, and other technologies on devices, applications, and our web pages to collect browsing, usage, or other technical information.</p>
		    <p><strong>Information You Can Access</strong></p>
            <p>Examples of information you can access through BrandSOM Services include:</p>
            <ul>
              <li><p>status of recent orders (including subscriptions);</p></li>
              <li><p>your complete order history;</p></li>
              <li><p>personally identifiable information (including name, e-mail, password, and address book);</p></li>
              <li><p>payment settings (including payment method information, promotional certificate, and gift card balances and 1-Click settings);</p></li>
              <li><p>e-mail notification settings (including Product Availability Alerts, Delivers, Special Occasion Reminders and newsletters);</p></li>
              <li><p>recommendations and the products you recently viewed that are the basis for recommendations (including Recommended for You and Improve Your Recommendations);</p></li>
              <li><p>shopping lists and gift registries (including Wish Lists);</p></li>
              <li><p>your content, devices, services, and related settings, and communications and personalized advertising preferences;</p></li>
              <li><p>content that you recently viewed;</p></li>
              <li><p>voice recordings associated with your account;</p></li>
              <li><p>Your Profile (including your product Reviews, Recommendations, Reminders and personal profile);</p></li>
              <li><p>If you are a seller, you can access your account and other information, and adjust your communications preferences, by updating your account in Seller Central .</p></li>
              <li><p>If you are a restaurant, you can access your account and other information, and adjust your communication preferences, by updating your account in Restaurant Central;</p></li>
              <li><p>If you are a service provider listing on our Service Provider Network, you can access your account and other information, and adjust your communication preferences, by updating your account in Service Provider Central;</p></li>
            </ul>
            <p><strong>Grievance Officer</strong></p>
            <p>Please find below the details of the grievance officer:</p>
            <p>Name: Venkat Terugu</p>
            <p>Designation: Grievance officer</p>
            <p>e-mail PDF: info@ciphercode.co</p>
            <p>Phone: +91 9741111594</p>
            <p>Address: BrandSOM, Ciphercode tech Solutions Pvt. Ltd., 199/1, 22nd&nbsp;Main, HSR Sector 1, Bangalore 560102, INDIA.</p>
            <p>For details regarding grievance redressal mechanism, please refer to our Conditions of Use.</p>
            <p>The Grievance Officer is identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.</p>
		  </div>
		</div>
		</>
	);
}
export default BrandSom;