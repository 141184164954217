import React from "react";
import img2 from "../../images/Article img.png";
import "./postcontent.css";

export default function PostContent() {
	return (
		<div className="position-relative">
			<div className="container blog-content">
				<p>
					<span className="big-letter">E</span> ncryption makes the modern world go round. Every time you make a mobile phone call, buy something with a credit card in a shop or on the web, or even get cash from an ATM, encryption bestows upon that transaction the confidentiality and security to make it possible and safe. – BBC Tech News
				</p>
				<h2 className="fw-bold">1. Looking into the history</h2>
				<p>During World War II, the Allies scored some notable victories against the German by recreating the machine used for encrypting the messages. Alternately, secret keys were used by sharing them among those who needed to communicate securely. These are known as symmetric encryption systems and have a weakness, in that everyone involved must possess the same set of secret (symmetric) keys like password.</p>
				<p>In the modern era, a need has arisen to communicate securely with people and organisations we do not know and with whom we cannot easily share secret keys, said Dr Manulis. This need has given rise to public-key cryptography i.e asymmetric encryption. Public-key cryptography involves a pair of keys known as public key and private key. The owner of keys can be individual person, Brand, entity, website, or gadget. The message encrypted with one key can be opened – decrypted- by other corresponding key of the pair.</p>
				<div className="p-1 p-md-4 img-wrap position-relative">
					<div className="blue-div"></div>
					<img className="main-img" src={img2} alt="blog_mage" />
				</div>
				<p>Modern systems use the hybrid combination of symmetric and asymmetric in various creative ways based on the magnitude of security level required. Well established encryption scheme cannot be broken by even supercomputers for years of work - unless the key compromised-.</p>
				<p>Most popular two asymmetric encryption algorithms are</p>
				<ol>
					<li>RSA - Rivest, Shamir and Adlema</li>
					<li>ECC – Elliptic Curve Cryptography </li>
				</ol>
				<p>Once encrypted can not be decrypted without the right other key of the pair, hence the information is as secure as the key protected (private key). There are various key protection schemes such that a group of people can represent single key hence no single person can force the system to compromise.</p>
				<h2 className="fw-bold">Cryptography has transformed the world information system</h2>
				<p>Cryptography has transformed the world information system and give trust to users and institutions. Here are few to name, which would have not existed without Cryptography evolution.</p>
				<ul>
					<li>
						<span>Safe internet</span>
					</li>
					<li>
						<span>Digital banking</span>
					</li>
					<li>
						<span>e-Commerce</span>
					</li>
					<li>
						<span>Digital signatures</span>
					</li>
					<li>
						<span>Blockchain</span>
					</li>
					<li>
						<span>Smartphones applications and what not.</span>
					</li>
				</ul>
				<p>There is enormous potential cryptography can deliver, what is being used is small portion of the capacity. Scientists at NIST expect the next leap with quantum computing.</p>
				<h3>Author</h3>
				<div className="blue-text">Venkat Terugu | Co-Founder at Ciphercode</div>
			</div>
			<div className="blue-line hide-sm">
				<svg width="188" height="11" viewBox="0 0 188 11" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="-1" y="0.0644531" width="188.487" height="10.752" fill="#3157EF" />
				</svg>
			</div>
		</div>
	);
}