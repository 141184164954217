import React from 'react';
import img2 from "../../images/exclamation1.png";
import './challenges.css';

export default function Challenges() {
	return (
		<div className="container challenges-content">
            <h2>Is your business constrained by these <span>challenges</span>?</h2>
         <div className="row">
            <div className="col-md-3 sm-6 quard">
                <div className='card'>
                <div className="challenges-content2 ">
                <img src={img2} alt="exclamation_img" loading="lazy" />
                    <p>Limited or No Control<br/>
                    Over Store Footfall</p>
                </div>
                </div>
            </div>
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
            <div className="challenges-content2 ">
            <img src={img2} alt="exclamation_img" loading="lazy"  />
                <p>Too Many Tech Tools
                   to subscribe,
                   fragmented data and
                   inefficiencies</p>
                </div>
                </div>
            </div>
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
            <div className="challenges-content2">
            <img src={img2} alt="exclamation_img"  loading="lazy" />
                  <p>Counterfeits Eating
                     into Revenues and
                     denting the repetition
                  </p>
                </div>
                </div>
            </div>
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
            <div className="challenges-content2">
            <img src={img2} alt="exclamation_img" loading="lazy"  />
                    <p>Missing Omnichannel
                       Experiences and
                       influence
                    </p>
                  </div>
                </div>
            </div>
            </div><br></br>
            <div className="row">
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
                <div className="challenges-content2">
                <img src={img2} alt="exclamation_img"  loading="lazy" />
                    <p>Constrained to
                        generation & accept
                        common coupons
                        across the platforms</p>
                  </div>
                </div>
            </div>
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
            <div className="challenges-content2">
            <img src={img2} alt="exclamation_img" loading="lazy" />
                <p>Growing campaign
                   costs and missing ROI
                   insights</p>
                </div>
                </div>
            </div>
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
            <div className="challenges-content2">
            <img src={img2} alt="exclamation_img" loading="lazy" />
                  <p>Lack of freedom for
                      campaign innovation,
                      constrained
                      segmentation,
                      channels and reach
                  </p>
                </div>
                </div>
            </div>
            <div className="col-md-3 sm-6 quard">
            <div className='card'>
            <div className="challenges-content2">
            <img src={img2} alt="exclamation_img" loading="lazy" />
                <p>Inefficient or<br/>
                    constrained CRM, CDP<br/>
                    and Gen AI Services
                </p>
                  </div>
                </div>
            </div>
            </div><br/>
            <div className="challenges-content3" id="boostContent">
              <h3>No worries, you just need <span>Brandwise</span>, lets check how..</h3>
            </div>
        </div>
    )
}