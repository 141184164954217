// SignupForm.js

import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";
import { Link,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import './signup.css';

const TEN_MINUTES = 10 * 60 * 1000;



export default function SignupForm() {
    const [step, setStep] = useState("email");
    const [email, setEmail] = useState(localStorage.getItem("email") || "");
    const [otp, setOtp] = useState("");
    const [full_name, setFullname] = useState("");
    const [company_name, setCompanyname] = useState("");
    const [phone, setPhone] = useState("");
    const [industry, setIndustry] = useState("");
    const [tenantName, setTenantName] = useState("");
    const [message, setMessage] = useState(null);
    const [errors, setErrors] = useState({});

    const [isLoading, setIsLoading] = useState(false);
    //const [acceptTerms, setAcceptTerms] = useState(false);
    const recaptchaRef = useRef();
    const [captchaToken, setCaptchaToken] = useState(null);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(20);
    const [isSendOtpEnabled, setIsSendOtpEnabled] = useState(false);  // New state for Send OTP
    const [isVerifyOtpEnabled, setIsVerifyOtpEnabled] = useState(false); // New state for Verify OTP


    const siteKey = "6LeUFVQqAAAAAD2yeueZfDDmQnO69gLfQnx-_RO-";

    // Helper functions to validate email and phone
    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isValidPhone = (phone) => phone === "" || /^\d{10}$/.test(phone);


    // List of public domains to block
    const publicDomains = [
        "gmail", "yahoo", "outlook", "hotmail", "aol", "icloud", "protonmail"
    ];

    // Valid TLDs to allow
    const validTLDs = [
        "com", "org", "net", "edu", "gov", "io", "co", "biz", "info"
    ];

    // Helper: Extract the domain from an email
    const extractDomain = (email) => {
        const parts = email.split('@');
        return parts.length === 2 ? parts[1].toLowerCase() : '';
    };

    // Helper: Compute Levenshtein Distance between two strings
    const levenshteinDistance = (a, b) => {
        const matrix = Array(a.length + 1).fill(null).map(() => Array(b.length + 1).fill(null));

        for (let i = 0; i <= a.length; i++) matrix[i][0] = i;
        for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                const cost = a[i - 1] === b[j - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,      // Deletion
                    matrix[i][j - 1] + 1,      // Insertion
                    matrix[i - 1][j - 1] + cost // Substitution
                );
            }
        }
        return matrix[a.length][b.length];
    };

    // Check if domain is public or a slight variation
    const isPublicDomain = (domain) => {
        const baseDomain = domain.split('.')[0];
        return publicDomains.some((publicDomain) => levenshteinDistance(baseDomain, publicDomain) <= 1);
    };

    // Validate TLD of the email
    const isValidTLD = (domain) => {
        const tld = domain.split('.').pop();
        return validTLDs.includes(tld);
    };

    // Check if email is a business email
    const isBusinessEmail = (email) => {
        const domain = extractDomain(email);
        return !isPublicDomain(domain) && isValidTLD(domain);
    };


    function handleEmailChange(e) {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        // Check if email is valid and business-oriented
        if (inputEmail && !isValidEmail(inputEmail)) {
            setErrors((prev) => ({ ...prev, email: "Invalid email address." }));
        } else if (inputEmail && !isBusinessEmail(inputEmail)) {
            setErrors((prev) => ({ ...prev, email: "Please use a business email address." }));
        } else {
            setErrors((prev) => ({ ...prev, email: "" }));
        }
        // Enable/Disable Send OTP button
        setIsSendOtpEnabled(isValidEmail(inputEmail) && isBusinessEmail(inputEmail));
    }

    function handleOtpChange(e) {
        const inputOtp = e.target.value;

        // Allow only numeric input
        if (/^\d*$/.test(inputOtp)) {
            setOtp(inputOtp); // Update state only if input is numeric
            setErrors((prev) => ({ ...prev, otp: "" })); // Clear errors if valid input
        } else {
            setErrors((prev) => ({ ...prev, otp: "OTP must contain only numbers." }));
        }
        // Enable/Disable Verify OTP button
        setIsVerifyOtpEnabled(inputOtp.length === 6);
    }


   // const navigate = useNavigate();
    // Timer Effect to handle countdown
    useEffect(() => {
        if (isResendDisabled && timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval); // Cleanup interval on unmount
        } else if (timer === 0) {
            setIsResendDisabled(false); // Enable resend when timer ends
        }
    }, [timer, isResendDisabled]);


    useEffect(() => {
        console.log("Is Resend Disabled:", isResendDisabled);
    }, [isResendDisabled]);


    // Set step from localStorage or reset to 'email' if idle for 10 minutes
    useEffect(() => {
        const savedStep = localStorage.getItem("signupStep") || "email";
        const lastActivity = localStorage.getItem("lastActivityTime");

        if (lastActivity && Date.now() - lastActivity > TEN_MINUTES) {
            setStep("email"); // Reset to the first step
            localStorage.removeItem("signupStep"); // Clear saved step
        } else {
            setStep(savedStep); // Restore the saved step
        }
    }, []);

    // Save the current step and timestamp to localStorage on step change
    useEffect(() => {
        localStorage.setItem("signupStep", step);
        localStorage.setItem("lastActivityTime", Date.now());
    }, [step]);

    // Save email to localStorage to persist it across refreshes
    useEffect(() => {
        localStorage.setItem("email", email);
    }, [email]);

    // Go back to the previous step
    function goBack() {
        if (step === "otp") setStep("email");
        else if (step === "signup") setStep("otp");
    }

    // Send OTP request
    async function sendOTP() {
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email.");
          //  Swal.fire({ text: "Please enter a valid email.", icon: "error", timer: 3000, });
            return;
        }

        if (!isBusinessEmail(email)) {

          //  Swal.fire({ text: "Please use a business email addre", icon: "error", timer: 3000, });
            toast.error("Please use a business email address.");
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch("https://prod.ciphercode.co/api/v1/sent-otp/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const result = await response.json();
                toast.success(result.message);
              //  toast.success("OTP sent successfully!");
               // Swal.fire({ text: result.message, icon: "success", timer: 3000 });
                setStep("otp");
                setTimer(20); // Reset timer to 20 seconds
                setIsResendDisabled(true); // Disable resend button
            } else {
                const result = await response.json();
                toast.error(result.message);
              //  Swal.fire({ text: result.message || "Error sending OTP.", icon: "error", timer: 3000 });
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
          //  Swal.fire({ title: "Error!", text: "An error occurred. Please try again later.", icon: "error", timer: 3000 });
        } finally {
            setIsLoading(false);
        }
    }

    // Verify OTP
    async function verifyOTP() {
        if (!otp) {
            toast.error("OTP is required.");
           // Swal.fire({ text: "OTP is required.", icon: "error", timer: 3000 });
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch("https://prod.ciphercode.co/api/v1/verify-otp/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, otp }),
            });

            if (response.ok) {
                toast.success("Proceed to complete the registration");
             //   Swal.fire({ text: "Proceed to complete the registration.", icon: "success", timer: 2000 });
                setOtp(""); // Clear OTP field
                setTimer(0); // Stop timer
                setStep("signup");
            } else {
                const result = await response.json();
                toast.error("Invalid OTP.");
               // Swal.fire({ text: result.message || "Invalid OTP.", icon: "error", timer: 2000 });
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
           // Swal.fire({ title: "Error!", text: "An error occurred. Please try again later.", icon: "error", timer: 2000 });
        } finally {
            setIsLoading(false);
        }
    }

    // Handle reCAPTCHA
    function onCaptchaChange(token) {
        setCaptchaToken(token);
        setIsSubmitEnabled(true);
    }

  
   // const isValidCompanyName = (name) => /^[a-zA-Z0-9\s]{1,15}$/.test(name);

    const isValidTenantName = (name) => /^[a-zA-Z\s]{1,15}$/.test(name);
    // Validate form fields
    function validateForm() {
        const newErrors = {};

        if (!full_name) newErrors.full_name = "Name is required.";
        if (!company_name) newErrors.company_name = "Company name is required.";
        if (!industry) newErrors.industry = "Plese Select an Industry";
        if (!message) newErrors.message = "Message is required.";
        if (!isValidTenantName(tenantName)) newErrors.tenantName = "Tenant name must be less than 15 characters and allow only alphabetic characters";

        if (!isValidPhone(phone)) newErrors.phone = "Enter a valid 10-digit phone number.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    }

    // Submit the form
    async function auto_onboard() {
        if (!captchaToken) {
            toast.error("Please complete the reCAPTCHA.");
           // Swal.fire({ text: "Please complete the reCAPTCHA.", icon: "error", timer: 3000 });
            return;
        }

        if (!validateForm()) return; // Stop submission if validation fails

        const formData = new FormData();
        formData.append("full_name", full_name);
        formData.append("company_name", company_name);
        formData.append("industry", industry);
        formData.append("tenant_name", tenantName);
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("message", message);
        formData.append("captcha_token", captchaToken);
        setIsLoading(true);
        try {
            const response = await fetch("https://prod.ciphercode.co/api/v1/create-tenant/", {
                method: "POST",
                body: formData,
            });
            const result = await response.json();

            if (response.ok) {             
               // toast.success("Please check your email for further details.", { autoClose: 5000 }); // Show toast for 5 seconds
                Swal.fire({
                    title: "Tenant creation request submitted!",
                    text: "Please check your email for further details.",
                    icon: "success",
                    showConfirmButton: false, // Hide confirmation button
                    timer: 4000,
                });
                setEmail(""); // Clear email input
                setStep("email"); // Update step state without redirecting
               
                
                // Navigate to home after 5 seconds
              //  setTimeout(() => {navigate("/");}, 5000);
            } else {
                toast.error(result.error);

              //  Swal.fire({ text: result.error || "An error occurred. Please try again.", icon: "error", timer: 5000 });
                recaptchaRef.current.reset();
                setIsSubmitEnabled(false);
            }
        } catch (error) {
            toast.error("An error occurred. Please try again later.");
           // Swal.fire({ title: "Error", text: "An error occurred. Please try again later.", icon: "error", timer: 5000 });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <LoadingOverlay active={isLoading} spinner text="Processing...">
            <div className="container signup_form">
            <ToastContainer />
                <h2>Tenant Registration</h2>
                {step !== "email" && (
                    <div onClick={goBack} className="back-icon" style={{ cursor: "pointer", position: 'relative', top: '-50px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M19 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H19v-2z" />
                        </svg>
                    </div>
                )}
                {step === "email" && (

                    <div>
                        <h4>Get started with your email</h4>

                        <input type="email"
                            placeholder="Enter your business email"
                            className={`form-control ${errors.email ? "is-invalid" : ""}`}
                            value={email}
                            onChange={handleEmailChange}
                            required />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}

                        <button onClick={sendOTP} disabled={!isSendOtpEnabled || isLoading}>
                        {isLoading ? "Sending..." : "Send OTP"}</button>
                    </div>
                )}
                {step === "otp" && (
                    <div >
                        <h5>Almost there. Enter the OTP sent to your Email</h5>
                        <p><strong>{email}</strong> </p>
                        <input
                            type="text" placeholder="Enter OTP"
                            className={`form-control ${errors.otp ? "is-invalid" : ""}`}
                            value={otp}
                            onChange={handleOtpChange}
                            maxLength={6} // Example: Limit OTP length to 6 digits
                            required
                        />
                        {errors.otp && <div className="invalid-feedback">{errors.otp}</div>}
                        <button onClick={verifyOTP} disabled={!isVerifyOtpEnabled || isLoading}>
                        {isLoading ? "Verifying..." : "Verify"}</button>
                        <a
                            onClick={!isResendDisabled && !isLoading ? sendOTP : undefined}
                            style={{
                                marginLeft: '250px',
                                textDecoration: isResendDisabled ? 'none' : 'underline',
                                color: isResendDisabled ? 'gray' : '#5374f9',
                                pointerEvents: isResendDisabled ? 'none' : 'auto',
                                cursor: isResendDisabled ? 'not-allowed' : 'pointer',
                                fontWeight: 'bold',
                            }}>
                            {isResendDisabled ? `Resend OTP in ${timer}s` : 'Resend OTP'}
                        </a>
                    </div>
                )}
                {step === "signup" && (

                    <form>

                        <div className="row px-3">
                            <input type="text" className="mb-4" name="fname" value={full_name} placeholder="Full Name" required onChange={(e) => setFullname(e.target.value)} />
                            {errors.full_name && <p className="error" style={{ marginTop: '-20px', marginLeft: '-8px', }}>{errors.full_name}</p>}
                        </div>

                        <div className="row px-3">
                            <input className="mb-4" name="cname" value={company_name} placeholder="Company Name" required onChange={(e) => setCompanyname(e.target.value)} />
                            {errors.company_name && <p className="error" style={{ marginTop: '-20px', marginLeft: '-8px', }}>{errors.company_name}</p>}

                        </div>
                        <div className="row px-3">
                            <input className="mb-4" type="number" name="phone" placeholder="Phone number" value={phone} required onChange={(e) => setPhone(e.target.value)} />
                            {errors.phone && (<p className="error" style={{ marginTop: '-20px', marginLeft: '-8px', }}>{errors.phone}
                            </p>
                            )}
                        </div>
                        <div className="row px-3 select-container">
                            <select className="mb-4" name="industry" value={industry} onChange={(e) => setIndustry(e.target.value)} >
                                <option value="">Select industry</option>
                                <option value="fashion">Fashion and Apparels</option>
                                <option value="personal">Personnel Care</option>
                                <option value="luxury">Luxury and Beauty</option>
                                <option value="spirit">Spirit and Wines</option>
                                <option value="electrical">Electrical and Electronics Appliances</option>
                                <option value="personnel">Personnel Accessories</option>
                                <option value="toys">Toys</option>
                                <option value="pharma">Pharma</option>
                                <option value="leather">Leather Accessories</option>
                                <option value="others">Others</option>
                            </select>
                            {errors.industry && <p className="error" style={{ marginTop: '-20px', marginLeft: '-8px', }}>{errors.industry}</p>}
                        </div>
                        
                        <div className="row px-3">
                            <input className="mb-4" name="tenant_name" value={tenantName} placeholder="Tenant Name" required onChange={(e) => setTenantName(e.target.value)} />
                            {errors.tenantName && <p className="error">{errors.tenantName}</p>}
                        </div>
                        
                       
                        <div className="row px-3">
                            <textarea type="text" className="mb-8" name="msg" placeholder="What problem are you trying to solve?" value={message} onChange={(e) => setMessage(e.target.value)} />
                            {errors.message && <p className="error" style={{ marginTop: '-2px', marginLeft: '-8px', }}>{errors.message}</p>}
                        </div>
                        {/* Google reCAPTCHA */}
                        <div className="row px-3 recaptcha-container">
                            <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} onChange={onCaptchaChange} />
                        </div>

                        <div className="row px-3">
                            <button type="button" onClick={auto_onboard} disabled={!isSubmitEnabled}
                                style={{
                                    backgroundColor: isSubmitEnabled ? "#3157EF" : "#ccc",
                                    cursor: isSubmitEnabled ? "pointer" : "not-allowed",
                                }}>Submit</button>
                        </div>
                    </form>
                )}
            </div>
        </LoadingOverlay>
    );
}
