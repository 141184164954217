import React,{useState} from "react";
import "./popup.css"
 
const Pdf_popup = props => {
  const [name, setName]=useState("");
  const [email, setEmail] = useState("");
  const purpuse = "pdf_cbqrg";
  const[error,seterror]=useState(false);
  const [isInputValid,setisInputValid]=useState(true);
  const [isInputNameValid,setisInputNameValid]=useState(true);

  const regextEmailValidation = Email => {
    const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    return regex.test(Email);
  };
  const regextNameValidation = Name => {
     const regex = new RegExp('[a-zA-Z]+\\.?');
     return regex.test(Name);
  };
  const updateEmailVal = event => {
    const { value } = event.target;
    const isInputValid = !value || regextEmailValidation(value)
    setisInputValid(isInputValid)
    setEmail(value)
  };
  const updateNameVal = event => {
    const { value } = event.target;
    const isInputNameValid= !value || regextNameValidation(value)
    setisInputNameValid(isInputNameValid)
    setName(value)
  };

  const signup = async() => {
    let item = {name, email, purpuse}
    if(name.trim("").length === 0 ){
      seterror(true)
    }
    else if(email.trim("").length === 0 ){
      seterror(true)
    }
    else if(isInputValid === true && isInputNameValid === true){
      seterror(false)
      fetch("https://web.ciphercode.co/api/v1/auth/web_register/", {
          method : 'POST',
          body: JSON.stringify(item),
          headers : {
                  "Content-Type" : 'application/json',
                  "Accept" : 'application/json',
          }
      })
      .then(response => response.json())
      .then(response => {
        if(response.message === "Registration Successfully"){
            alert('Yay, you will be onboarded shortly!')
            localStorage.setItem("pdf-qr", JSON.stringify(response))
            props.handleClose()
        }
      }).catch((error)=>{
        console.log(error)
      })
  }
}
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>x</span>
        <h2 className="py-2">Signup</h2>
        <p>Signup and generate PDF/Media File QR Code</p>
      <form className="text-start" style={{padding:"20px"}}>
      {error&&<p style={{color:'red'}}>Please, fill mandatory field</p>}
        <div className="mb-3 form-item">
          <label htmlFor="name" className="form-label required">
            Name
          </label>
          <input type="text" className="form-control" id="name" name="name" placeholder='Enter full name' required onChange = {updateNameVal} />
          {!isInputNameValid && (<div style={{ color: "#F61C04", marginTop:"-5px", fontSize:"12px" }}>enter valid name.</div>)}
        </div>
        <div className="mb-3 form-item">
          <label htmlFor="email" className="form-label required">
            Email
          </label>
          <input type="email" className="form-control" id="email" name="email" placeholder='Enter email address' required onChange = {updateEmailVal} />
          {!isInputValid && (<div style={{ color: "#F61C04", marginTop:"-5px", fontSize:"12px"}}>Email is not valid.</div>)}
        </div>
        <div><br />
        <div className="mb-3 form-item">
          <button type="button" onClick={signup} className="btn btn-primary w-100 py-2">
            Register
          </button>
          </div>
        </div>
      </form>
      </div>
    </div>
  );
};
 
export default  Pdf_popup;