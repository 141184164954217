import React from "react";
import './tophead.css'
import img1 from "../../images/footwear_banner.webp";
import img2 from "../../images/retail1.webp";

export default function TopHead() {
	return (
		<>
        <div className="pt-5">
           <img className="cover-image" src={img1} alt="cover_pic"/>
        </div>
        <div className="container retail-tophead">
        <div className="retail-content">
        
            <div className="row">
                <div className="col-md-6 order-2 order-md-2 ">
                <h1>Transform Fleeting Attentions into Enduring Value</h1>
                <p>Deliver Unique experiences across customer
                   Touchpoints and drive Omni-Channel Sales.
                   Understand your customers and ride on *3x
                    higher conversions. Harness the power of
                    Brandwise and become a brand of the future, Today!
                </p>
                <br></br>
                <div className="row">
                    <div className="col">
                        <input type="text" placeholder="Full Name"/>
                    </div>
                    <div className="col">
                        <input type="text" placeholder="Business Email"/>
                    </div>
                </div>
                <button type="button">Connect with an expert</button>
                </div>
                <div className="col-md-6 order-1 order-md-2 " style={{textAlign:"right"}}>
                    <img src={img2} alt="Man receiving omnichannel alerts and suggestions from whatsapp, email, messages etc to purchase clothes he is interested in" style={{width:"100%",height:"100%"}}/>
                </div>
            </div>
        </div>
          <br></br>
        <div className="highlight-content" id="challengesButton">
                <div className="row">
                    <div className=" col-6 col-md-2">
                        <a href="#challengesContent"><button>Challenges</button></a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-2">
                        <a href="#ROIcontent"><button>Features</button></a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-2">
                      <a href="#brandContent"><button>Use Cases</button></a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-2">
                        <a href="#feedbackContent"><button>Testimonies</button></a>
                    </div>
                    
                </div>
          </div><br/><br/>
		</div>       
        </>
	);
}