import React, {useEffect}  from "react";
import CustomerEngagement from "../components/HomeApplicants/customerEngagement";
import CustomerMapping from "../components/HomeApplicants/customerMapping";
import Integrate from "../components/HomeApplicants/integrate";
import ProductTop from "../components/Product/product-top";
import img from "../images/QReach_img2.svg";
import img26 from "../images/2 (26).png";
import img1 from "../images/2 (1).png";
import img11 from "../images/QReach img.svg";
import onproduct from "../images/ON-PRODUCT CAMPAIGN.svg";
import frontskill from "../images/STORE FRONT QR CAMPAIGN.svg";
import campaign from "../images/FLYER OR NEWSPAPER QR CAMPAIGNS.svg"
import "./qseal_qreach.css";

export default function QreachPage() {
  useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
  const customerMappingData = [
    {
      title: "Inherent to Qseal",
      description:
        "Physical products are brand touchpoints and engagement is not limited to printed information on product label or packaging with Brandwise. On social media audience is big, and relevance is limited. Digitize your products, deliver dynamic content, improve reach, and engage high intent consumers remotely.",
    },
    {
      title: "Campaigns",
      description:
        "Creating brand awareness and brand value are important throughout product lifecycle. Market is competitive, there is no room for laggards. Delivering dynamic content is key to enhance campaign effectiveness. Every product is a channel by itself, control content delivery at unit level seamlessly instantly without changing physical labels / on packaging remotely and instantly.",
    },
    {
      title: "Deep Trace			",
      description:
        "Every consumer engagement can be deep traced to build futureproof intelligence.",
    },
  ];
  return (
    <div>
      <ProductTop
        bgImage={img26}
        bgImage2={img1}
        productHead="<h1> QReach<sup>®</sup> <br />Consumer <span className='myBlue'> digital engagement </span> built <br />to scale</h1>"
        mainImage={img11}
        productSubHead="Engage consumers through social, retail and print media. Offline to Online Campaigns and more to : Deliver novel digital experiences to consumers. Engage & entice consumers through campaigns that build brand awareness, generate leads, and scale growth."
        url={"#qreachUseCase"}
		btnText={"Know More"}
      ></ProductTop>
      <div style={{marginTop:'8px'}}>
      </div>
      <CustomerMapping alldata={customerMappingData} mainHead="End-to-End Brand-Consumer Mapping" />
      <CustomerEngagement
        heading={<p>QReach<sup>®</sup></p>}
        url={"#qreachUseCase"}
        subHeading={
          <p>QReach<sup>®</sup> is a unique consumer campaign feature to deliver brand engagement initiatives, it can be inherent to QSeal or explicit application based on brand configuration on Brandwise<sup>®</sup> platform.            </p>
        }
      />
      <Integrate
        heading={"Benefits"}
        subHeading={
          "<ul> <li> Personalized consumer experience, loyalty programs, CRM and digital experience delivery</li><br /><li> Large audience outreach with dynamic content and higher conversion rates </li><br /><li> New growth avenues, feature proof consumer intelligence engine</li></ul>"
        }
        image={img}
      />
      <div id="qreachUseCase" className="product-useCase container">
        <h1>
          <strong>Use cases</strong>
        </h1>
        <h4>
          Products
        </h4>
        <div className="usecasbox">
        <img src={onproduct} alt="" />
        <p>
        Digitize products, turn every unit into a direct to consumer channel. Build campaign to acquire form data like registration (product / warranty), survey, feedback and more..
        </p>
        </div>
        <h4>
         Storefront & Instore campaigns
        </h4>
        <div className="usecasbox">
        <img src={frontskill} alt="" />
        <p>
        Dedicated campaigns for your stores like Scan2Win, Feedback2Win, Spin2Win that generates high intent leads, reduce customer acquisition cost, increase customer retention and loyalty.
        </p>
       
        </div>
        <h4>Print Media Campaigns</h4>
        <div className="usecasbox">
        <img src={campaign} alt="" />
        <p>
          Often print media campaigns are offline, they just drive the consumer
          awareness, turn that awareness to actions, experiences and measure
          campaign performance in real-time, build insights on lead indicators
          than lag results.
        </p>
        </div>
        <h4>
          Online Campaigns
        </h4>
        <div className="usecasbox">
        <p>
        Generate custom url for online campaigns with prebuilt landing pages and data protection schemes.
        </p>
        </div>
      </div>
    </div>
  );
}
