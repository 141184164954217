import React, {useEffect} from "react";
import TopHead from "../components/Resources/topHead";
import CryptoHelps from "../components/Resources/cryptoHelps";
import ThingsCanDo from "../components/Resources/thingsCanDo";


function Resources() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<TopHead></TopHead>
			<CryptoHelps></CryptoHelps>
			<ThingsCanDo></ThingsCanDo>
		</div>
	);
}
export default Resources;
