import React, {useEffect}  from "react";
import TopHead from "../components/Packaging/tophead";
import Seamless from "../components/Packaging/seamless";
import Package from "../components/Packaging/package";
import GetDemo from "../components/Packaging/getdemo";
import Feedback from "../components/Home/feedback";
import { Helmet } from "react-helmet";


export default function Packaging() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <div>
        <Helmet>
        <title>Ciphercode</title>
        <meta name="description" content="Explore Ciphercode’s smart packaging solutions which enable product digitization, traceability, and customer engagement. Boost security and sustainability." />
        <meta name="keywords" content="Smart Packaging, Traceablity, Sustainability" />
        {/* Open Graph tags for social media sharing */}
        <meta property="og:title" content="Transforming Packaging to Smart Packaging with Brandwise" />
        <meta property="og:description" content="Explore Ciphercode’s smart packaging solutions which enable product digitization, traceability, and customer engagement. Boost security and sustainability." />
        <meta property="og:url" content="https://ciphercode.co/packaging" />
        {/* Twitter Card for better Twitter sharing */}
        <meta name="twitter:title" content="Transforming Packaging to Smart Packaging with Brandwise" />
        <meta name="twitter:description" content="Explore Ciphercode’s smart packaging solutions which enable product digitization, traceability, and customer engagement. Boost security and sustainability." />
        </Helmet>
        <TopHead />
        <Seamless />
        <Package />
        <Feedback />
        <GetDemo/>
      </div>
    )
}
   