import React, {useEffect} from "react";
import TopHead from "../components/BlogPost/topHead";
import BlogContent from "../components/BlogPost/blogcontent";
import { Helmet } from "react-helmet";

function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="What is Product Digitization? Key benefits and approaches to digitize your brand and products in the modern era." />
                <meta name="keywords" content="What is Product Digitization, Benefits of Digitization, Digital Products, Digitization Process" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="The Benefits & Opportunities of Product Digitization" />
                <meta property="og:description" content="What is Product Digitization? Key benefits and approaches to digitize your brand and products in the modern era." />
                <meta property="og:url" content="https://ciphercode.co/product_digitization" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="The Benefits & Opportunities of Product Digitization" />
                <meta name="twitter:description" content="What is Product Digitization? Key benefits and approaches to digitize your brand and products in the modern era." />
            </Helmet>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;
