import React, {useState} from "react";
import "./faqs.css";


function Faq({question, ans, initialopen}) {
	const [open, setopen] = useState(initialopen || false);

	return (
		<div className="faq-section">
			<div className="py-3">
			<div className="btn" onClick={() => setopen((prev) => !prev)}>
					<div>
						{!open && (
							<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.2" d="M33.2551 16.9037C33.2551 25.8221 26.0685 33.0468 17.2096 33.0468C8.35065 33.0468 1.16406 25.8221 1.16406 16.9037C1.16406 7.98544 8.35065 0.760742 17.2096 0.760742C26.0685 0.760742 33.2551 7.98544 33.2551 16.9037Z" stroke="black" />
								<line x1="11.4453" y1="16.646" x2="22.9733" y2="16.646" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
								<line x1="17.2051" y1="22.0542" x2="17.2051" y2="10.4495" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
							</svg>
						)}
						{open && (
							<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.2" d="M33.2551 16.686C33.2551 25.6043 26.0685 32.829 17.2096 32.829C8.35065 32.829 1.16406 25.6043 1.16406 16.686C1.16406 7.76766 8.35065 0.542969 17.2096 0.542969C26.0685 0.542969 33.2551 7.76766 33.2551 16.686Z" stroke="black" />
								<line x1="11.4453" y1="16.4282" x2="22.9733" y2="16.4282" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
							</svg>
						)}
					</div>
				</div>
				<h3 className="question">{question}</h3>
			</div>
			{open && <div className="ans">{ans}</div>}
            <hr className="m-0"/>
		</div>
	);
}

export default function Faqs() {
	return (
		<div className="container contactus text-center">
			<h2>Frequently Asked Questions</h2>
            <div className="mt-3 text-start">
                <Faq 
				 question="What is Brandwise®?"
				 ans = "Brandwise® is a tech stack or in common terms a technology platform for businesses to digitize articles at scale, real-time authentication, consumer experience and many more D2C solutions in the demanding consumer era."
				/>
                <Faq 
				  question= "What is a Cryptoblock QR and how it is different from any other QR codes?"
				  ans = "The algorithm behind creating this Block is powered by cryptography and hence the name. A Block uses crypto level security to encrypt and decrypt information which ensures additional security.Postproduction, qualified dispatchable items get a specific block identifier ID, which represents the specific product digital certificate inheriting various product (item) attributes, certified by the owning brand.These signed blocks are placed on the permissioned Blockchain representing the private ownership and public access, delivering immutability, product provenance and ownership authenticity."
				/>
                <Faq 
				  question="How to generate a Cryptoblock QR?"
				  ans="There are two ways to create blocks, one through factory automation by integrating the Brandwise APIs or through the Brandwise admin panel. Once you register on Brandwise and create a product on the platform, you can easily generate blocks by navigating to “Blocks” tab on the menu. You can create in batches and download them to print through your printing applications such as BarTender or any other applications supplied by your printing partners/vendors."
				/>
                <Faq 
				  question="What is the lifetime of a Block? Is it free for life like any other static QR code generator on the internet?"
				   ans = "A Block is having indefinite life! However, the delivery of service on cloud for authentication product, landing page url and any hosted services will be available only if the subscription or license is active."
				/>
				<Faq
				  question="What is a Campaign? Can I create a custom campaign?"
				  ans="Campaign is when you create a channel using the Block (for print media) or link (digital/social media) to capture engagement data for analytics and decision making. Use “Campaigns” on the platform tabs when you want to create a Block. An active campaign engagement at right consumer touchpoint, will facilitate a participant to share consented information and claim the campaign incentive."
				/>
				<Faq 
				  question="How do I redeem an incentive on Brandwise?"
				  ans="“Redeem” tab on the Brandwise menu hosts the redemption service, with details of any participant who provided information to acquire a coupon."
				/>
				<Faq 
				  question="I am unable to access Campaign Tab and Block tab, what do I do?"
				  ans="Every user is associated with user rights to access and control features or information on the Brandwise platform. Please contact your admin user for information on user rights associated with your user id. Please contact support in case of any query for admin user."
				/>
				<Faq  
				 question="Do I need to use an external URL shortener for creating the Block? Can I use the URL shortener on Brandwise?"
				 ans="Brandwise comes with an inbuilt URL shortener. Please talk to our team for URL shortener and tracker service."
				/>
				<Faq 
				  question="Can I redirect users to a customized page where I can update contents about my brand and connect on SM platforms?"
				  ans="Yes. This is exactly what Brandwise® offers you, to create a custom landing page of your own and direct your traffic to deliver target specific and dynamic content."
				/>
				<Faq 
				  question="Do I need an app to scan a Block? Does it require internet to scan and access information?"
				  ans="You just need a smartphone to scan the Block. However, upon scanning the Block you will need an active data or internet connection to access the online content."
				/>
				<Faq 
				  question="Where is my data stored? How long is my data available for access?"
				  ans="Your data is secure on a private cloud protected by multiple layers of security measures. Read more about the private cloud and security of these data here for more information. Your data is available if you have an active subscription or license. Please read our service level agreement and data retention to learn more."
				/>
				<Faq 
				 question="What if I discontinue the subscription or service, will the Block still function normally?"
				 ans="Yes. The Block is functional if it can be read/accessed for limited period. However, authentication or engagement service can be only delivered if there is an active subscription or license."
				/>
            </div>
		</div>
	);
}
