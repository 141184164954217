import React from 'react'
import { Link } from 'react-router-dom';


export default function footerhome() {
  return (
    <>
        <div className="footerhome">
            <div className='container'>
                <p>What’s Stopping You?</p>
                <h2><span className='myBlue'>Transform </span >your Brand with <span className='myBlue'> Brandwise</span></h2>
                <Link to="/schedule_demo"><button className="btn btn-BlueBg btn-explore" >Book a Call</button></Link>
            </div>
        </div>
    </>
  )
}
