import React, {useEffect}  from "react";
import TopHead from "../components/Partnershipprogram/tophead";
import Partner from "../components/Partnershipprogram/partner";
import Feedback from "../components/Partnershipprogram/feedback";
import Discover from '../components/Partnershipprogram/discover';
import Footerhome from '../components/Partnershipprogram/footerhome';
import { Helmet } from "react-helmet";

export default function Partnershipprogram() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <div>
        <Helmet>
          <title>Ciphercode</title>
          <meta name="description" content="Join Ciphercode's Partnership Program to drive mutual growth and innovation. Collaborate with us to unlock new opportunities and enhance customer experiences." />
          <meta name="keywords" content="Business partner, ciphercode partnership programs, collaborative" />
          {/* Open Graph tags for social media sharing */}
          <meta property="og:title" content="Join the Ciphercode Partnership Program for Growth & Innovation" />
          <meta property="og:description" content="Join Ciphercode's Partnership Program to drive mutual growth and innovation. Collaborate with us to unlock new opportunities and enhance customer experiences." />
          <meta property="og:url" content="https://ciphercode.co/partnershipprogram" />
          {/* Twitter Card for better Twitter sharing */}
          <meta name="twitter:title" content="Join the Ciphercode Partnership Program for Growth & Innovation" />
          <meta name="twitter:description" content="Join Ciphercode's Partnership Program to drive mutual growth and innovation. Collaborate with us to unlock new opportunities and enhance customer experiences." />
        </Helmet>
        <TopHead />
        <Partner/>
        <Feedback/>
        <Discover/>
        <Footerhome/> 
      </div>
    )
}