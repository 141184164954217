import React from 'react';
import './loginForm.css';
import darklogo from "../../images/signup.gif"
import RegisterForm from './registerForm';

export default function LoginSignup() {
  return (
    <div className="container py-5">
      <div className="card card0">
        <div className="row">
           <div className="col-lg-6">
              <div className="card1 pb-5">
                <div className="row px-3 justify-content-center mt-4 mb-5 border-line"> 
                  <img src={darklogo} className="image" alt='reg_img'/>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <RegisterForm></RegisterForm>
            </div>
        </div>
      </div>
    </div>
  )
}
