import React from "react";
import img from "../../images/Article img.png";
import { NavLink } from "react-router-dom";
import "./suggestedBlog.css";

function SuggestedBlog() {
	return (
		<div style={{background: "linear-gradient(180deg, rgba(128, 200, 243, 0.1)  0%, rgba(128, 200, 243, 0) 100%)", paddingBottom:30}}>
			<div className="container top-head">
				<div className="row">
					<div className="col-12 col-md-6 order-md-2">
						<div className="fw-bold">Suggested Article</div>
						<h1 style={{marginTop:'1px'}}>How encryption changed the world</h1>
						<h4>Encryption makes the modern world go round. Every time you make a mobile phone call, buy something with a credit card in a shop or on the web, or even get cash from an ATM, encryption bestows upon that transaction...</h4>
						<NavLink className='btn-index' to='/encryption'><button className="btn btn-BlueBg">Read Article</button></NavLink>
					</div>
					<div className="col-12 col-md-1 order-md-2"></div>
					<div className="col-12 col-md-5 order-md-2">
						<img className ="suggested_blog_image" alt="suggested_article" src={img} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default SuggestedBlog;
