import React, {useEffect} from 'react'
import LoginForm from '../components/Login/loginSignup';
function Register() {
  useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
        <div>
          <LoginForm login={false}></LoginForm>
        </div>
    )
}
export default Register;
