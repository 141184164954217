
import React from "react";

export const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            /*style={{ ...style, display: "block", background: "rgba(0, 0, 0, 0.5)", borderRadius: "50%", padding: "10px", cursor: "pointer", position: "absolute", right: "-50px", top: "50%", transform: "translateY(-50%)" }}*/
            onClick={onClick}
        >
           
        </div>
    );
};

export const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            /*style={{ ...style, display: "block", background: "rgba(0, 0, 0, 0.5)", borderRadius: "50%", padding: "10px", cursor: "pointer", position: "absolute", left: "-50px", top: "50%", transform: "translateY(-50%)" }}*/
            onClick={onClick}
        >
            
        </div>
    );
};
