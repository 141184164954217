import React, { useState, useEffect, useRef } from "react";
import mapimg from "../../images/Contactus_img.png";
import flag from "../../images/india_flag.png";
import flag2 from "../../images/belgium_flag.png";
import "./top-head.css";
import img1 from "../../images/captcha_reload.png";
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';

function TopHead() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState(null);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [isInputValid, setIsInputValid] = useState(true);
    const [isInputNameValid, setIsInputNameValid] = useState(true);
    const [isInputPhoneValid, setIsInputPhoneValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [captchaText, setCaptchaText] = useState(''); 
    const [userInput, setUserInput] = useState(true); 
    const canvasRef = useRef(null); 
    const [captchaValid, setCaptchaValid] = useState(true);

    const regexPhoneValidation = (phoneNumber) => {
        const regex = /^[0-9]{10}$/;
        return regex.test(phoneNumber);
    };

    const regextEmailValidation = Email => {
        const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
        return regex.test(Email);
    };

    const regextNameValidation = Name => {
        const regex = new RegExp('[a-zA-Z]+\\.?');
        return regex.test(Name);
    };

    const updatePhoneVal = (event) => {
        const { value } = event.target;
        const isInputPhoneValid = !value || regexPhoneValidation(value);
        setIsInputPhoneValid(isInputPhoneValid);
        setPhone(value);
    };
    const updateEmailVal = event => {
        const { value } = event.target;
        const isInputValid = !value || regextEmailValidation(value)
        setIsInputValid(isInputValid)
        setEmail(value)
    };
    const updateNameVal = event => {
        const { value } = event.target;
        const isInputNameValid = !value || regextNameValidation(value)
        setIsInputNameValid(isInputNameValid)
        setName(value)
    };
    //Generating captcha
    useEffect(() => { 
        const canvas = canvasRef.current; 
        const ctx = canvas.getContext('2d'); 
        initializeCaptcha(ctx); 
    }, []); 
  
    const generateRandomChar = (min, max) => 
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min)); 

    const generateCaptchaText = () => { 
       let captcha = ''; 
       for (let i = 0; i < 4; i++) { 
          captcha += generateRandomChar(65, 90); // for uppercase letters
          captcha += generateRandomChar(97, 122); // for lowercase letters
          captcha += generateRandomChar(48, 57); // for digits
    } 
    return captcha.split('').sort(() => Math.random() - 0.5).join('').substring(0, 4); 
    }; 

console.log(generateCaptchaText()); 

    const drawCaptchaOnCanvas = (ctx, captcha) => { 
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); 
        const textColors = ['rgb(0,0,0)', 'rgb(130,130,130)']; 
        const letterSpace = 150 / captcha.length; 
        for (let i = 0; i < captcha.length; i++) { 
            const xInitialSpace = 25; 
            ctx.font = '20px Roboto Mono'; 
            ctx.fillStyle = textColors[Math.floor( 
                Math.random() * 2)]; 
            ctx.fillText( 
                captcha[i], 
                xInitialSpace + i * letterSpace, 
                  
                // Randomize Y position slightly 
                Math.floor(Math.random() * 16 + 25), 
                100 
            ); 
        } 
    }; 
  
    const initializeCaptcha = (ctx) => { 
        setUserInput(''); 
        const newCaptcha = generateCaptchaText(); 
        setCaptchaText(newCaptcha); 
        drawCaptchaOnCanvas(ctx, newCaptcha); 
    }; 
  
    const handleUserInputChange = (e) => { 
        setUserInput(e.target.value); 
    }; 

    const contact = async () => {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('message', message)
        formData.append('company', company)
        formData.append('userInput', userInput)
        
        if (name.trim("").length === 0) {
            setError(true)
        }
        else if (email.trim("").length === 0) {
            setError(true)
        }
        else if (message.trim("").length === 0) {
            setError(true)
        }
       
        
        else if (userInput === captchaText && isInputValid === true && isInputNameValid === true) {
                setError(false);
                setIsLoading(true);
                fetch("https://web.ciphercode.co/api/v1/contactus/", {
                    method: "POST",
                    body: formData
                })
                .then(response => response.json())
                .then(response => {
                    if (response.message === "Your data successfully submitted") {
                        Swal.fire({
                            title: "Thanks for contacting us!",
                            text: "We'll respond shortly.",
                            icon: "success",
                            timer: 5000
                        });
                        setName("");
                        setEmail("");
                        setPhone("");
                        setCompany("");
                        setMessage("");
                        setUserInput("");
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            } else {
                setError(true);
            setCaptchaValid(false); // Set captcha validity state to false
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            initializeCaptcha(ctx);
            }
       
    }
        
       
    function trackingListener() {
        var capterra_vkey = 'a5b7f38f96bd3b892bb64d2750fabfac',
        capterra_vid = '2210067',
        ct = document.createElement('img');
        ct.src = 'https://ct.capterra.com/capterra_tracker.gif?vid=' + capterra_vid + '&vkey=' + capterra_vkey;
        document.body.appendChild(ct);
    };

    return (
        <LoadingOverlay active={isLoading} spinner text='Submitting...'>
            <div className="container contactus">
                <div className="row">
                    <div className="col-md-6">
                        <div className="map-contact">
                            <h1>Finding your way around Ciphercode?</h1>
                            <h3>Let us assist!</h3>
                            <img src={mapimg} alt='map' />
                            <h4>We’ll assign a professional to learn your setup and guide you on how Ciphercode products can meet your business needs through one-on-one sessions. Think of us as your own personal business consultants.</h4>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-contact">
                            <h1>Get In Touch!</h1>
                            <form className="text-start">
                                {error && <p style={{ color: 'red' }}>Please, fill out all the required fields</p>}
                                 
                                <div className="mb-3 form-item">
                                    <input type="text" className="form-control" id="name" name="name" value={name} placeholder="Full name" onChange={updateNameVal} required />
                                    
                                    {!isInputNameValid && (<div style={{ color: "#F61C04", marginTop: "-5px", fontSize: "12px" }}>Enter valid name.</div>)}
                                </div>
                                <div className="mb-3 form-item">
                                   
                                    <input type="email" className="form-control" id="email" name="email" value={email} placeholder="Email Address" onChange={updateEmailVal} required />
                                    
                                    {!isInputValid && (<div style={{ color: "#F61C04", marginTop: "-5px", fontSize: "12px" }} required>Email is not valid.</div>)}
                                </div>
                                <div className="mb-3 form-item">
                                    
                                    <input type="text" className="form-control" id="phone" name="phone" value={phone} placeholder="Mobile Number" onChange={updatePhoneVal} required />
                                    
                                    {!isInputPhoneValid && (<div style={{ color: "#F61C04", marginTop: "-5px", fontSize: "12px" }}>Phone is not valid.</div>)}
                                </div>
                                <div className="mb-3 form-item">
                                    <input type="text" className="form-control" id="company" name="company" value={company} required placeholder="Company name" onChange={(e) => setCompany(e.target.value)} />  
                                </div>
                                <div className="mb-4 form-item">
                                    <textarea type="text" className="form-control" id="message" name="message" value={message} placeholder="Tell us about your requirements" onChange={(e) => setMessage(e.target.value)} />
                                    
                                </div>
                                <div className="captcha-field">
                                 
                                <div className="mb-3 form-item">

                                <input 
                                   type="text"
                                   id="user-input"
                                   placeholder="Enter the Captcha"
                                   value={userInput} 
                                   onChange={handleUserInputChange}
                                   className={captchaValid ? "" : "invalid"}/> 
                                </div>
                                <div className="wrapper">
                                    
                                      <canvas className="captcha-box" ref={canvasRef} 
                                        width="200"
                                        height="70" > 
                                      </canvas> 
                                      <button 
                                        id="reload-button" 
                                        type="button" // Add this line to prevent form submission
                                        onClick={() => initializeCaptcha(canvasRef.current.getContext('2d'))} 
                                        style={{ border: "none", marginLeft: "20px", background: "none", verticalAlign: "middle" }} 
                                      >
                                      <img src={img1} alt='captcha' className="reload-img" />
                                      </button>
                                    
                                </div>
                                </div>
                                <div><button type="button" onClick={() => { contact(); trackingListener();  }} className="btn btn-primary  py-2">Submit</button></div>
                            </form>
                            
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '55px' }}>
                    <div className="col-md-6">
                        <div className="map-contact">
                            <h1>Interested in our product?<br></br>Talk to our sales team</h1>
                            <h4>From questions about pricing to one-on-one <br></br>personalized demos, we'd love to connect and <br></br>help get you started.</h4>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6 order-md-2">
                                <div className="map-contact">
                                    <h2 style={{ color: 'black', marginLeft: '20px' }}><img src={flag} alt="" style={{ height: '40px', width: '55px' }} /> <span>India</span></h2>
                                    <h4 style={{ color: 'black', marginLeft: '20px' }}>Phone : <span style={{ color: 'blue' }}>+91-9741111594</span></h4>
                                </div>
                            </div> 
                            <div className="col-md-6 order-md-2"> 
                                <div className="map-contact">
                                    <h2 style={{ color: 'black', marginLeft: '20px' }}><img src={flag2} alt="" style={{ height: '40px', width: '55px' }} /> <span>Belgium</span></h2>
                                    <h4 style={{ color: 'black', marginLeft: '20px' }}>Phone : <span style={{ color: 'blue' }}>+32 495 29 76 45</span></h4>
                                </div>
                            </div>
                        </div>
                        <h4 style={{ color: 'black', marginLeft: '20px', textAlign:'center', fontSize:'17px' }}>Email : <a href="mailto:sales@ciphercode.co" target="_blank" rel="noreferrer"><span style={{ color: 'blue' }}>sales@ciphercode.co</span></a></h4>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
export default TopHead;
