import React, {useEffect} from "react";
import TopHead from "../components/Blognumbersix/tophead";
import BlogContent from "../components/Blognumbersix/blogcontent";
import { Helmet } from "react-helmet";

function Blog() {
	useEffect(() => {window.scroll({top: 0, behavior: 'smooth'})});
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="Anticipated Challenges and Opportunities for Brands in the Near Future. Explore strategies to navigate future business trends" />
                <meta name="keywords" content="Business Trend, Opportunites, Threats, Business Challenges" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="Challenges & Opportunities for Brands in the Near Future" />
                <meta property="og:description" content="Anticipated Challenges and Opportunities for Brands in the Near Future. Explore strategies to navigate future business trends" />
                <meta property="og:url" content="https://ciphercode.co/navigating_the_future" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="Challenges & Opportunities for Brands in the Near Future" />
                <meta name="twitter:description" content="Anticipated Challenges and Opportunities for Brands in the Near Future. Explore strategies to navigate future business trends" />
            </Helmet>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;