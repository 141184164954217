import React, {useEffect, useState} from "react";
import QRCode from 'qrcode.react';
import './tophead.css'
import './switch.css';
import { AiOutlineDownload} from "react-icons/ai";
import { IoIosLock} from "react-icons/io";
import logo from '../../images/location.png';
import Tophead from "./tophead";
import { CgColorPicker } from "react-icons/cg";
import blogo from '../../images/Group 528.png';
import dummyimage from '../../images/QR_Code.png';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import Watermark_popup from "./watermark_popup";
import {MdOutlineCampaign} from "react-icons/md";
import { NavLink} from "react-router-dom";
import { FaLink, FaPaperclip, FaMapMarkerAlt, FaFilePdf, FaWhatsapp , FaUsers} from "react-icons/fa";

const LocationQR = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [url, setUrl] = useState('')
  const[color,setColor]=useState(null);
  const [error, seterror] = useState(false);
  const[qr_type,setqr_type]=useState("color");
  const [qrCodeText, setQRCodeText] = useState('');

  const togglePopup1 = () => {
    setIsOpen1(!isOpen1);
  }
  const qr_code=async()=>
  {
    if (url.trim("").length === 0 && color!==null){
     seterror(true)
   }
   else if(url.trim("").length>0){
     setQRCodeText(url);
   } 
   else{
    seterror(false)
   }
 }
const handleKeyDown = (event) => {
 if (event.key === 'Enter') {
   qr_code() 
 }
}
  // download QR code
  const downloadQRCode = async () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
    const canvas = await html2canvas(qrCodeURL);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'qr_code.png', 'image/png');
  };

// handle qr_color
useEffect(()=>{
  if(color){
    qr_code()
  }
},[color])

 
    return (
        <>
          <Tophead />
          <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center', paddingBottom:'20px'}}>
          <div className="container brandwiseqrbox">
          <div className="nav">
           <input type="checkbox" id="nav-check" />
           <div className="nav-btn">
             <label htmlFor="nav-check">QR Menu</label>
           </div>
            <div className="nav-links">
               <NavLink exact="true" activeclassname ="active" to="/qrcode/url"><FaLink/> URL</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/shorturl"><FaPaperclip/>  Short URL</NavLink> */}
               <NavLink exact="true" activeclassname ="active"to="/qrcode/whatsapp"><FaWhatsapp /> WhatsApp</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/social_meida"><FaUsers /> Social Media</NavLink>
               <NavLink exact="true" activeclassname ="active" to="/qrcode/File_qr"><FaFilePdf /> PDF/Media</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/location_qr"><FaMapMarkerAlt /> Location</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/webform_qr"><FaWpforms /> Web Forms</NavLink> */}
               <NavLink exact="true" activeclassname ="active" to="/qrcode/campaign"><MdOutlineCampaign /> Campaign</NavLink>
            </div>
            </div>
<div className="row" onClick={qr_code}>
<div className="col">
<div className="qr_data">
   <label htmlFor="exampleInputfile">Enter location url/link </label><br />
   <input type="url" className="form-control" d="exampleInputurl" placeholder='Enter the url here'  autoComplete="off" onKeyDown={handleKeyDown} onChange = {(e) => {setUrl(e.target.value)}} />
   {error&&<p style={{ color: "#F61C04", marginTop:'-5px', fontSize:'20px', marginLeft:'5px' }}>field is required</p>}
   <h5>QR Code Customization</h5><hr />
   <div className="custo_item">
           <ul>
            <li><a onClick={()=>{setqr_type("color")}} className={qr_type=="color"?"active":""}><CgColorPicker /> Pick QR Color</a></li>
            </ul>
         </div><br />
            {qr_type=="color" && <input type="color"  className="form-control-file" id="exampleiInputcolor"    onChange = {(e) => {setColor(e.target.value)}}></input>}
           </div>
    </div>
<div className="col">
<div className='qr_code'>
  <div className="qrcodeimg" id="qrCodeEl">
  {qrCodeText.trim("").length>0&&<QRCode  value={qrCodeText} renderAs="svg" Level={"H"} fgColor={color?color:"#000"} includeMargin size ={290} 
    imageSettings={{src:logo, excavate:true, height: 42, width: 42}}><img src={logo} /> </QRCode>}
   {qrCodeText.trim("").length==0&&<img src={dummyimage} />}
   <img className="watermark" src={blogo} />
</div>
<p style={{cursor:"pointer"}} onClick={togglePopup1}>Signup to remove watermark <IoIosLock /> </p>
<button type="button" className="download-btn" onClick={downloadQRCode} value="Download"><AiOutlineDownload /> Download (.PNG)</button>
</div>
</div>
{isOpen1 && <Watermark_popup handleClose={togglePopup1}/>}
</div>
</div>
</div>
</>
    );
};
export default LocationQR;