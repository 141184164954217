import React, {useEffect} from "react";
import Faqs from "../components/ContactUs/faqs";
import Footer from "../components/ContactUs/footer";
import TopHead from "../components/ContactUs/top-head";
import { Helmet } from "react-helmet";


export default function Contactus() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<Helmet>
                <title>Ciphercode</title>
                <meta name="description" content="Get in touch with Ciphercode. We're here to answer your questions and provide support. Reach out for inquiries about our services or partnership opportunities." />
                <meta name="keywords" content="Get in Touch, Ciphercode Contact Us, Connect" />
                {/* Open Graph tags for social media sharing */}
                <meta property="og:title" content="Get in touch, we’re here to assist you settle in seamlessly" />
                <meta property="og:description" content="Get in touch with Ciphercode. We're here to answer your questions and provide support. Reach out for inquiries about our services or partnership opportunities." />
                <meta property="og:url" content="https://ciphercode.co/contactus" />
                {/* Twitter Card for better Twitter sharing */}
                <meta name="twitter:title" content="Get in touch, we’re here to assist you settle in seamlessly" />
                <meta name="twitter:description" content="Get in touch with Ciphercode. We're here to answer your questions and provide support. Reach out for inquiries about our services or partnership opportunities." />
            </Helmet>
			<TopHead />
            <Faqs />
            <Footer />
		</div>
	);
}

