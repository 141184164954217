import React, {useEffect}  from "react";
import TopHead from "../components/Footwear/tophead";
import Challenges from "../components/Footwear/challenges";
import Boost from "../components/Footwear/boost";
import Utilise from "../components/Footwear/utilise";
import Feedback from "../components/Home/feedback";
import { Helmet } from "react-helmet";

export default function Footwear() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <div>
        <Helmet>
          <title>Ciphercode</title>
          <meta name="description" content="Looking to grow in the Footwear Industry? Harness Brandwise to Differentiate yourselves to Attract Customers, Deliver Premium Experiences and Retain Loyalty." />
          <meta name="keywords" content="Footwear, Customer Experiences, Loyalty" />
          {/* Open Graph tags for social media sharing */}
          <meta property="og:title" content="Footwear Industry Customer Engagement and Authentication" />
          <meta property="og:description" content="Looking to grow in the Footwear Industry? Harness Brandwise to Differentiate yourselves to Attract Customers, Deliver Premium Experiences and Retain Loyalty." />
          <meta property="og:url" content="https://ciphercode.co/footwear" />
          {/* Twitter Card for better Twitter sharing */}
          <meta name="twitter:title" content="Footwear Industry Customer Engagement and Authentication" />
          <meta name="twitter:description" content="Looking to grow in the Footwear Industry? Harness Brandwise to Differentiate yourselves to Attract Customers, Deliver Premium Experiences and Retain Loyalty." />
        </Helmet>
         <TopHead />
         <Challenges/>
         <Boost/>
         <Utilise/>
         <Feedback/>
         
      </div>
    )
}
   