import React from "react";
import './tophead.css'
import img26 from "../../images/2 (26).png";
import img25 from "../../images/2 (1).png";


export default function TopHead() {
    return (
        <div style={{textAlign: '-webkit-center'}}>
        <div className="container tophead">
            <img alt="brandwise" className="absBg1" style={{opacity: 0.21}} src={img26} />
            <img alt="brandwise" className="absBg2" style={{opacity: 0.23}} src={img25} />
            <h1>Careers</h1>
            <p className="px-6">Welcome! Ciphercode is a team of entreprenuerial, energetic and purposeful people with one thing in common – desire for self-improvement and contribution to the Company’s values and mission</p>
        </div>
        </div>
    )
}