import React, {useState} from "react";
import './tophead.css'
import './switch.css';
import { AiOutlineUpload, AiOutlineDownload} from "react-icons/ai";
import { BsUnion } from "react-icons/bs";
import { CgColorPicker } from "react-icons/cg";
import { IoIosLock} from "react-icons/io";
import Tophead from "./tophead";
import dummyimage from '../../images/QR_Code.png';
import Watermark_popup from "./watermark_popup";
import Dynamicqr_popup from "./dynamicqr_popup";
import Pdf_popup from "./pdf_popup";
import {MdOutlineCampaign} from "react-icons/md";
import { NavLink} from "react-router-dom";
import { FaLink, FaPaperclip, FaMapMarkerAlt, FaFilePdf, FaWhatsapp , FaUsers} from "react-icons/fa";

const FileQR = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const[color,setColor]=useState(null);
  const[bgcolor,setbgColor]=useState(null);
  const [logo, setLogo]=useState(null);
  const [qrCodeText, setQRCodeText] = useState('');
  const[qr_type,setqr_type]=useState("logo"); //logo,color
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const togglePopup1 = () => {
    setIsOpen1(!isOpen1);
  }
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  }
    return (
        <>
          <Tophead />
          <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center', paddingBottom:'20px'}}>
          <div className=" container brandwiseqrbox">
          <div className="nav">
           <input type="checkbox" id="nav-check" />
           <div className="nav-btn">
             <label htmlFor="nav-check">QR Menu</label>
           </div>
            <div className="nav-links">
               <NavLink exact="true" activeclassname ="active" to="/qrcode/url"><FaLink/> URL</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/shorturl"><FaPaperclip/>  Short URL</NavLink> */}
               <NavLink exact="true" activeclassname ="active"to="/qrcode/whatsapp"><FaWhatsapp /> WhatsApp</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/social_meida"><FaUsers /> Social Media</NavLink>
               <NavLink exact="true" activeclassname ="active" to="/qrcode/File_qr"><FaFilePdf /> PDF/Media</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/location_qr"><FaMapMarkerAlt /> Location</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/webform_qr"><FaWpforms /> Web Forms</NavLink> */}
               <NavLink exact="true" activeclassname ="active" to="/qrcode/campaign"><MdOutlineCampaign /> Campaign</NavLink>
            </div>
            </div>
<div className="row">
<div className="col-md-7">
<div className="qr_data">
   <label htmlFor="exampleInputfile" style={{cursor:"pointer"}} onClick={togglePopup}>Enter Name For Digital Signature  <IoIosLock /> </label><br />
   <input type="url" className="form-control" d="exampleInputurl" placeholder='Enter cloud link here' /> 
      <h4>OR</h4>
      <div class="center-container">
     <div className="qr_file">
  <label htmlFor="exampleFormControlFile1"> <AiOutlineUpload /> Upload File</label>
  <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange = {(e) => { setLogo(URL.createObjectURL(e.target.files[0]))}}/>
  </div> 
  </div><br />
  <input type="checkbox" id={`react-switch-new`} className="react-switch-checkbox"  onClick={togglePopup2} />
  <label className="react-switch-label" htmlFor={`react-switch-new`}>
  <span className={`react-switch-button`} /></label><h6>Toggle for Dynamic QR <IoIosLock />  </h6>
   <h5>QR Code Customization</h5><hr />
   <div className="custo_item">
     <ul>
     <li><a onClick={()=>{setqr_type("logo")}} className={qr_type=="logo"?"active":""}><BsUnion /> Pick QR Logo </a></li>
     <li><a onClick={()=>{setqr_type("color")}} className={qr_type=="color"?"active":""}><CgColorPicker /> Pick QR Fgcolor</a></li>
     <li><a onClick={()=>{setqr_type("bgcolor")}} className={qr_type=="bgcolor"?"active":""}><CgColorPicker /> Pick QR  Bgcolor</a></li>
     </ul>
   </div><br />
   {qr_type=="color" && <input type="color"  className="form-control-file" id="exampleiInputcolor"    onChange = {(e) => {setColor(e.target.value)}}></input>}
   {qr_type=="bgcolor" && <input type="color"  className="form-control-file" id="exampleiInputcolorbg"    onChange = {(e) => {setbgColor(e.target.value)}}></input>}
   {qr_type=="logo" && <div className="qr_logo">
  <label htmlFor="exampleFormControlFile1"> <AiOutlineUpload /> Upload Logo</label>
  <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange = {(e) => { setLogo(URL.createObjectURL(e.target.files[0]))}}/>
  </div>}<br /><br />
</div>
</div>
<div className="col-md-5">
<div className='qr_code'>
  <div className="qrcodeimg">
  <img src={dummyimage} />
</div>
<p style={{cursor:"pointer"}} onClick={togglePopup1}>Signup to remove watermark <IoIosLock /> </p>
<button type="button" className="download-btn" value="Download"><AiOutlineDownload /> Download (.PNG)</button>
</div>
</div>
{isOpen && <Pdf_popup handleClose={togglePopup}/>}
{isOpen1 && <Watermark_popup handleClose={togglePopup1}/>}
{isOpen2 && <Dynamicqr_popup handleClose={togglePopup2}/>}
</div>
</div>
</div>
</>
    );
};
export default FileQR;